import info_img_01_dk from '../images/info_img_01_dk.png';
import info_img_02_dk from '../images/info_img_02_dk.png';
import info_img_03_dk from '../images/info_img_03_dk.png';
import info_img_04_dk from '../images/info_img_04_dk.png';
import info_img_05_dk from '../images/info_img_05_dk.png';

import ic_abbank_dk from '../images/ic_abbank_dk.png';

import ic_BIDV from '../images/ic_BIDV.svg';
import ic_mbbank from '../images/ic_mbbank.svg';

import ic_techcombank_dk from '../images/ic_techcombank_dk.png';
import ic_techcombank_tb from '../images/ic_techcombank_tb.svg';

import ic_vietcombank_dk from '../images/ic_vietcombank_dk.svg';

import ic_vietinbank_dk from '../images/ic_vietinbank_dk.svg';
import ic_vietinbank_tb from '../images/ic_vietinbank_tb.png';

export const menu = [
    {
        key: 'home',
    },
    {
        key: 'introduction',
        label: {
            vn:'Giới thiỆu',
            en:'introduction'
        }
    },
    {
        key: 'features',
        label: {
            vn:'TÍNH NĂNG',
            en:'features'
        }
    },
    {
        key: 'technology',
        label: {
            vn:'CÔNG NGHỆ',
            en:'technology'
        }
    },
    {
        key: 'partners',
        label: {
            vn:'ĐỐI TÁC',
            en:'partners'
        }
    },
    {
        key: 'q&a',
        label: {
            vn:'hỏi đáp',
            en:'Q&A'
        }
    },
    {
        key: 'downloads',
        label: {
            vn:'TẢI VỀ',
            en:'downloads'
        }
    },
    // {
    //     key: 'login',
    //     label: {
    //         vn:'ĐĂNG NHẬP',
    //         en:'login'
    //     }
    // },
    
]

export const pageKey = [
    {key:'home'},
    {key:'introduction'},
    {key:'features'},
    {key:'features-convenient'},
    {key:'features-more'},
    {key:'technology'},
    {key:'partners'},
    {key:'q&a'},
    {key:'downloads'},
]

export const language = [
    {
        key: '/vn',
        label: 'VN'
    },
    {
        key: '/en',
        label: 'EN'
    }
]

export const footer = [
    {
        label: 'Liên hệ',
        key : 'contact'
    },
    // {
    //     label: 'Đối tác',
    //     key: 'partner'
    // },
    {
        label: 'Thoả thuận sử dụng',
        key: 'term-of-use'
    },
    {
        label: 'Chính sách bảo mật',
        key: 'privacy-policy'
    }
]

export const imageBank = [
    {
        icon_dk: ic_BIDV,
        top_dk: '10%',
        left_dk: '23%', 
    },
    {
        icon_dk: ic_vietinbank_dk,
        icon_tb: ic_vietinbank_tb,
        icon_mb: ic_vietinbank_tb,
        top_dk:'34%',
        left_dk:'5%',
        top_tb: '22%',
        right_tb: '20%',
        top_mb: '7%',
        right_mb: -10,    
    },
    {
        icon_dk: ic_vietcombank_dk,
        icon_tb: ic_vietcombank_dk,
        bottom_dk: '10%',
        left_dk: '10%',
        top_tb: '17%',
        right_tb: -5
    },
    {
        icon_dk: ic_mbbank,     
        top_dk: '20%',
        right_dk: '17%',   
    },
    {
        icon_dk: ic_techcombank_dk,
        icon_tb: ic_techcombank_tb,
        icon_mb: ic_techcombank_tb,
        top_dk: '40%',
        right_dk: '6%',
        top_tb: '11%',
        left_tb: '5%',
        top_mb: '6%',
        left_mb: -10,  
    },
    {
        icon_dk: ic_abbank_dk,
        icon_tb: ic_abbank_dk,
        icon_mb: ic_abbank_dk,
        bottom_dk: '5%',
        right_dk: '3%',
        top_tb: '20%',
        left_tb: '20%',
        top_mb: '14%',
        left_mb: '30%',   
    }
]

export const imageQA = [
    {
        img_dk: info_img_01_dk,
        top:'0%',
        left:'-1%'
    },
    {
        img_dk: info_img_02_dk,
        img_mb: info_img_02_dk,
        bottom: '0%',
        left: '0%',
        left_mb: '-2%',
        top_mb: '20%',
    },
    {
        img_dk: info_img_03_dk,
        img_mb: info_img_03_dk,
        right: '4%',
        right_mb: '-2%',
        top: '10%',
        top_mb: '0',
    },
    {
        img_dk: info_img_04_dk,
        right: '10%',
        top: '45%'
    },
    {
        img_dk: info_img_05_dk,
        img_mb: info_img_05_dk,
        right: '-1%',
        right_mb: '-1%',
        bottom: '10%',
        bottom_mb: '30%'
    },
]

export const qaData = [
    {
        id: 0,
        question: 'Làm thế nào để tạo tài khoản Wee?',
        answer: [
            'Bước 1: Mở ứng dụng Wee, chọn "ĐĂNG KÝ”',
            'Bước 2: Nhập số điện thoại của bạn để đăng ký. Hệ thống sẽ gửi mã xác nhận về số điện thoại này để xác nhận',
            'Bước 3: Nhập mã xác nhận để xác thực',
            'Bước 4: Tạo nhận diện của bạn',
            'Bước 5: Tạo hình đại diện',
            'Bước 6: Nhập họ và tên của bạn',
            'Bước 7: Đặt tên đăng nhập.',
            'Bước 8: Chọn ngày tháng năm sinh của bạn',
            'Bước 9: Chọn giới tính',
            'Bước 10: Thiết lập mã PIN gồm 4 số, mã PIN này dùng để đăng nhập ứng dụng Wee vào những lần sau',
            'Màn hình sẽ thông báo thành công sau khi việc đăng ký hoàn tất, giờ thì bạn có thể tận hưởng và trải nghiệm cùng Wee rồi'
        ],
    },
    {
        id: 1,
        question: 'Làm thế nào để liên kết tài khoản của tôi với một ngân hàng?',
        answer: [
            'Hệ thống sẽ tự động hiện thông báo yêu cầu liên kết tài khoản ngân hàng đối với những thao tác liên quan đến tài chính. Để thực hiện liên kết:',
            'Bước 1: Bấm vào thông báo hoặc vào mục setting chọn liên kết ngân hàng',
            'Bước 2: Chọn ngân hàng của bạn',
            'Bước 3: Nhập thông tin tài khoản (lưu ý số điện thoại phải trùng với số điện thoại đăng ký SMS của ngân hàng nhé)',
            'Bước 4: Nhập mã OTP gửi về số điện thoại đã đăng ký SMS với ngân hàng',
            'Hệ thống sẽ hiển thị thông báo thành công sau khi liên kết'
        ]
    },
    {
        id: 2,
        question: 'Wee cho phép liên kết với những ngân hàng nào?',
        answer: [
            'Hiện tại Wee cho phép liên kết với một trong những ngân hàng sau: ABBank',
            'Nếu ngân hàng của bạn không có trong danh sách liên kết, hãy giúp Wee kết nối với ngân hàng của bạn nhanh hơn bằng cách:',
            'Bước 1: Chọn liên kết tài khoản ngân hàng',
            'Bước 2: Chọn ngân hàng của bạn',
            'Bước 3: Nhập địa chỉ email và phản hồi của bạn về Wee',
            'Wee sẽ thông báo cho bạn ngay khi ngân hàng của bạn có trong danh sách liên kết'
        ]
    },
    {
        id: 3,
        question: 'Ngân hàng của tôi không có trong danh sách liên kết thì phải làm sao?',
        answer: [
            'Wee rất tiếc về sự bất tiện này. Hãy giúp Wee kết nối với ngân hàng của bạn nhanh hơn bằng cách:',
            'Bước 1: Chọn liên kết tài khoản ngân hàng',
            'Bước 2: Chọn ngân hàng của bạn',
            'Bước 3: Nhập địa chỉ email và phản hồi của bạn về Wee',
            'Wee sẽ thông báo cho bạn ngay khi ngân hàng của bạn có trong danh sách liên kết'
        ]
    },
    {
        id: 4,
        question: 'Tôi có thể liên kết bao nhiêu ngân hàng một lúc?',
        answer: [
            'Bạn chỉ có thể liên kết một tài khoản ngân hàng với Wee. Để đổi tài khoản liên kết, bạn hãy hủy kết nối với tài khoản ngân hàng hiện tại trước khi kết nối với tài khoản khác nhé',
        ]
    },
    {
        id: 5,
        question: 'Tôi có thể nạp tiền vào tài khoản Wee như thế nào?',
        answer: [
            'Sau khi bạn liên kết tài khoản ngân hàng, tài khoản Wee của bạn sẽ được kết nối trực tiếp với tài khoản ngân hàng đã liên kết.',
        ]
        },
    {
        id: 6,
        question: 'Tôi muốn xem lịch sử giao dịch của tôi ở đâu?',
        answer: [
            'Để xem lịch sử giao dịch bấm vào Main Space.',
            'Để lọc giao dịch :',
            'Bước 1: Từ giao diện trang lịch sử giao dịch bấm biểu tượng ở góc phải',
            'Bước 2: nhập ngày tháng hoặc loại giao dịch bạn muốn lọc. Sau đó nhất nút “Tiến hành”',
        ]
    },
    {
        id: 7,
        question: 'Làm thế nào để chuyển tiền cho bạn của mình?',
        answer: [
            'Để thực hiện chuyển tiền cho bạn bè trên Wee:',
            'Bước 1: Từ giao diện trang chủ chọn chuyển tiền',
            'Bước 2: Bấm vào  “biểu tượng cây bút” để tìm hoặc chọn bạn bè từ danh bạ',
            'Bước 3: Nhập số tiền cần gửi',
            'Bước 4: Nhập nội dung',
            'Bước 5: Xác nhận giao dịch bằng khuôn mặt',
            'Sau khi giao dịch thành công, hệ thống sẽ gửi tin nhắn thông báo tới người nhận, và hiển thị trong giao diện trò chuyện',
            'Tip nhỏ cho bạn: Bạn cũng có thể chuyển tiền nhanh chóng ngay tại giao diện chat với bạn bè bằng cách bấm vào biểu tượng dấu + trong giao diện chat, chọn “Chuyển tiền” và thực hiện từ bước 2 nhé.',
        ]
    },
    {
        id: 8,
        question: 'Tôi muốn chia tiền trong một nhóm thì làm thế nào?',
        answer: [
            'Để thực hiện chia tiền cho bạn bè trên Wee:',
            'Bước 1: Từ giao diện trang chủ mục “Chuyển tiền” vuốt qua trái chọn chia tiền',
            'Bước 2: Bấm vào  “biểu tượng cây bút” để tìm hoặc chọn bạn bè từ danh bạ (có thể chọn nhiều người)',
            'Bước 3: Nhập tổng số tiền cần cần chia',
            'Bước 4: Nhập nội dung',
            'Bước 5: Kiểm tra lại thông tin (bạn có thể điều chỉnh số tiền cần chia cho mỗi người) sau đó nhấn tiếp tục.',
            'Sau khi gửi yêu cầu chia tiền thành công, hệ thống sẽ gửi tin nhắn thông báo tới người nhận, và hiển thị trong giao diện trò chuyện.',
            'Tip nhỏ cho bạn: Bạn cũng có thể chia tiền nhanh chóng ngay tại giao diện chat với bạn bè bằng cách bấm vào biểu tượng dấu + trong giao diện chat, chọn “Chia tiền” và thực hiện từ bước 2 nhé.'
        ]
    },
    {
        id: 9,
        question: 'Mã PIN là gì?',
        answer: [
            'Mã PIN là mã gồm 4 chữ số do bạn tạo ra khi đăng ký tài khoản Wee và được dùng để đăng nhập và ứng dụng Wee.',
        ]
    },
    {
        id: 10,
        question: 'Tôi quên mã PIN khi đăng nhập?',
        answer: [
            'Bạn có thể đặt lại mã PIN bằng cách:',
            'Bước 1: Bấm vào chữ “Quên mã?” ở màn hình đăng nhập',
            'Bước 2: Nhập tên đăng nhập',
            'Bước 3: Xác thực khuôn mặt',
            'Bước 4: Nhập mã OTP gồm 6 chữ số được gửi về số điện thoại mà bạn đã đăng ký SMS Banking với ngân hàng',
            'Bước 5: Thiết lập mã PIN mới',
            'Bước 6: Dùng mã PIN mới vừa được tạo để đăng nhập vào Wee',
        ]
    },
    {
        id: 11,
        question: 'Tôi muốn đổi mã PIN?',
        answer: [
            'Cách thực hiện để đổi mã PIN:',
            'Bước 1: Vào “Cài đặt” => Chọn “Thông tin người dùng” => Vuốt chọn “Đổi mã PIN”',
            'Bước 2: Nhập mã PIN hiện tại',
            'Bước 3: Nhập mã PIN mới',
            'Bước 4: Dùng mã PIN mới để đăng nhập lại vào Wee',
        ]
    },
    {
        id: 12,
        question: 'Một tài khoản ngân hàng có liên kết được với nhiều tài khoản Wee không?',
        answer: [
            'Một tài khoản ngân hàng chỉ liên kết với một tài khoản Wee.',
        ]
    },
    {
        id: 13,
        question: 'Tôi muốn hủy liên kết tài khoản ngân hàng?',
        answer: [
            'Để hủy liên kết tài khoản ngân hàng, bạn vào phần “Cài đặt” => Bấm chọn logo ngân hàng hiện đang liên kết => Bấm chọn “Hủy liên kết” ',
        ]
    },
    {
        id: 14  ,
        question: 'Tài khoản Sub space và tài khoản tiết kiệm khác nhau gì?',
        answer: [
            'Tài khoản tiết kiệm: Là tài khoản tiết kiệm thông thường, bạn có thể Mở mới hoặc Tất toán tài khoản tiết kiệm hiện có.',
            'Tài khoản Sub Space: Là tài khoản được thiết lập để giúp bạn tiết kiệm tiền cho một mục tiêu nào đó, bạn có thể:',
            '_  Đặt tên cho khoản tiết kiệm.',
            '_  Đặt số tiền mục tiêu cần tiết kiệm.',
            '_  Bạn có thể thay đổi số tiền trong Sub Space bằng cách chuyển tiền qua lại giữa Main Space và Sub Space, giữa các Sub Space.',
            '_  Chia sẻ Sub Space với bạn bè để cùng tiết kiệm.',
            '_  Xóa Sub Space, khi xóa thì tiền sẽ được chuyển về Main Space của bạn.',
        ]
    },
]
export const TermOfUseData = [
    {
        title: '',
        content: [
            'Vui lòng đọc kỹ các điều khoản này. Bằng cách sử dụng Dịch vụ, quý khách đồng ý rằng quý khách đã đọc, hiểu, chấp nhận và đồng ý với các Điều khoản sử dụng.',
            'Nếu quý khách không đồng ý, hoặc không thuộc phạm vi điều chỉnh của các Điều khoản sử dụng của Dịch vụ và mong muốn không tiếp tục sử dụng Dịch vụ, vui lòng không tiếp tục sử dụng Ứng dụng thanh toán Wee và Dịch vụ.',
            'Các Điều khoản sử dụng được quy định trong tài liệu này cấu thành một thỏa thuận pháp lý giữa quý khách và Công ty trách nhiệm hữu hạn Wee Digital (Công ty).',
        ]
    },
    {
        title: 'Điều 1: Giải thích từ ngữ',
        content: [
            '1.1. Điều khoản sử dụng Dịch vụ Wee (gọi tắt là "Điều khoản") là tài liệu thỏa thuận giữa Bạn/Người sử dụng Dịch vụ Wee (gọi là "Người dùng") và Công ty trách nhiệm hữu hạn Wee Digital (gọi là "Công ty"), quy định các điều khoản, điều kiện, quyền và nghĩa vụ của mỗi bên áp dụng khi người dùng sử dụng Dịch vụ Wee của Công ty.',
            '1.2. Dịch vụ Wee (gọi tắt là "Dịch vụ") là các dịch vụ do Công ty cung cấp trên Ứng dụng thanh toán Wee nhằm giúp Người dùng lưu trữ và thực hiện dịch vụ thanh toán trực tuyến cho hàng hóa, sản phẩm, dịch vụ thay thế cho hình thức thanh toán bằng tiền mặt.',
            '1.3. Giao dịch Wee là các giao dịch có thể được thực hiện thông qua Ứng dụng thanh toán Wee vào từng thời điểm, bao gồm (nhưng không giới hạn) các giao dịch sau đây:',
            '1.3.1. Chuyển tiền, nhận tiền, chia tiền, tặng tiền trên Ứng dụng thanh toán Wee.',
            '1.3.2. Mua thẻ điện thoại, nạp tiền trực tiếp vào tài khoản điện thoại, và/hoặc các thẻ và tài khoản sử dụng các tiện ích giải trí, học thuật khác được bổ sung, phát triển trong từng thời điểm.',
            '1.3.3. Thanh toán hóa đơn dịch vụ tiện ích sinh hoạt: điện, nước, truyền hình cáp, internet…',
            '1.3.4. Mua, thanh toán các giao dịch/dịch vụ khác, như: vé máy bay, vé xe, vé xem phim…',
            '1.4. Giấy tờ tùy thân: là bất cứ giấy tờ nào chứng minh nhân thân của một người, bao gồm (a) chứng minh nhân dân (“CMND”), (b) Căn cước công dân (“CCCD”), (c) Hộ chiếu và/hoặc (d) bất cứ loại giấy tờ tùy thân nào khác cơ quan thẩm quyền của Việt Nam hoặc quốc gia/vùng lãnh thổ được Việt Nam thừa nhận cấp, còn hiệu lực.',
            '1.5. Giấy tờ pháp lý: là bất cứ giấy tờ nào chứng minh tư cách pháp lý của một tổ chức, bao gồm: (a) Giấy chứng nhận đăng ký kinh doanh/doanh nghiệp, (b) Giấy chứng nhận đầu tư, (c) Quyết định thành lập và/hoặc (d) bất cứ loại giấy tờ pháp lý nào khác ghi nhận tư cách pháp lý của một tổ chức, kể cả có hay không có tư cách pháp nhân.',
            '1.6. Ngày làm việc: Là các ngày từ thứ hai đến thứ sáu mà Công ty làm việc bình thường; trừ các ngày nghỉ, lễ, tết theo quy định pháp luật.',
            '1.7. Nhà cung cấp: là các cá nhân, tổ chức cung cấp hàng hóa, dịch vụ sử dụng nền tảng công nghệ trực tuyến, đồng ý với Thỏa thuận sử dụng dịch vụ thu hộ với Công ty, ủy quyền cho Công ty thực hiện thu hộ các khoản tiền thanh toán từ Người dùng.',
            '1.8. Người dùng: là tổ chức, cá nhân có đăng ký và sử dụng Ứng dụng thanh toán Wee.',
            '1.9. Tài khoản Ứng dụng thanh toán Wee: là tài khoản định danh do Công ty phát hành trên nền tảng di động tích hợp các thông tin về Người dùng như Khuôn mặt, Giấy tờ tùy thân, Giấy tờ pháp lý, Tài khoản ngân hàng liên kết…',
            '1.10. Ứng dụng thanh toán Wee: là ứng dụng có sẵn bằng cách tải về trên các thiết bị công nghệ cho phép Người dùng truy cập để sử dụng Dịch vụ thanh toán Wee.',
        ]
    },
    {
        title: 'Điều 2: Đăng ký và sử dụng dịch vụ',
        content: [
            '2.1. Trước khi đăng ký sử dụng Dịch vụ, Người dùng cần đọc, đồng ý và chấp nhận tất cả các quy định trong Thỏa thuận này và/hoặc các quy định, chính sách, và các điều khoản liên quan khác trong quá trình sử dụng Dịch vụ.',
            '2.2. Khi đã sử dụng Dịch vụ, Người dùng được hiểu là đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ tất cả các quy định trong Thỏa thuận này và/hoặc các quy định, chính sách, và các điều khoản liên quan khác trong quá trình sử dụng Dịch vụ.',
            '2.3. Trong quá trình sử dụng Dịch vụ, Người dùng đồng ý cung cấp đầy đủ, chính xác thông tin về họ tên, địa chỉ liên lạc, giấy tờ tùy thân hợp lệ, số điện thoại liên hệ, email… và chịu trách nhiệm về tính chính xác, trung thực các thông tin mà Người dùng đã cung cấp.',
            '2.4. Nếu phát sinh rủi ro, khiếu nại trong quá trình sử dụng Dịch vụ, Công ty căn cứ vào những thông tin mà Người dùng đã cung cấp để tiếp nhận và giải quyết. Mọi trường hợp cung cấp không chính xác, có sai sót dẫn đến trường hợp Người dùng không được hỗ trợ hoặc giải quyết cho các khiếu nại liên quan, Công ty không chịu trách nhiệm. Người dùng phải tự chịu trách nhiệm về những rủi ro, khiếu nại không được giải quyết vì việc cung cấp thông tin thiếu, không đầy đủ hoặc không chính xác của mình trong quá trình sử dụng Dịch vụ.',
        ]
    },
    {
        title: 'Điều 3: Thay đổi nội dung các điều kiện và điều khoản',
        content: [
            '3.1. Công ty có thể sửa đổi, bổ sung nội dung Thỏa thuận này vào bất cứ thời gian nào bằng cách gửi thông báo thay đổi cho Người dùng.',
            '3.2. Người dùng được xem là đã nhận được thông báo nếu:',
            '3.2.1. Các thông báo này đã được đưa lên website chính thức của Công ty cho Dịch vụ tại địa chỉ: wee.vn; hoặc',
            '3.2.2. Các thông báo này đã được gửi tới địa chỉ thư điện tử (email) mà Người dùng đăng ký khi bắt đầu quá trình sử dụng Dịch vụ; hoặc',
            '3.2.3. Được gửi tới số điện thoại Người dùng đăng ký sử dụng Dịch vụ; hoặc',
            '3.2.4. Được gửi, thông báo, cập nhật qua Ứng dụng Wee.',
            '3.3. Trường hợp đối với những thay đổi như: (a) tăng trách nhiệm và nghĩa vụ của Người dùng, (b) hạn chế bớt các dịch vụ đang cung cấp trên Ứng dụng Wee, hoặc (c) giảm hạn mức giao dịch, trừ khi những thay đổi này phải thực hiện ngay vì yêu cầu an ninh hoặc theo quy định của pháp luật, Công ty sẽ thông báo cho Người dùng ít nhất 10 (mười) ngày làm việc trước ngày có hiệu lực của những thay đổi này.',
            '3.4. Nếu Người dùng không đồng ý với nội dung thay đổi thì có thể chấm dứt sử dụng Dịch vụ. Trường hợp Người dùng tiếp tục sử dụng Dịch vụ, thì được hiểu Người dùng đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ các nội dung thay đổi đó.',
        ]
    },
    {
        title: 'Điều 4: Sử dụng dịch vụ và Phí dịch vụ',
        content: [
            '4.1. Khi Người dùng chủ động thực hiện Giao dịch Wee thì hàng hóa, sản phẩm, dịch vụ được xác định là được bàn giao ngay cho Người dùng khi Giao dịch Wee được thực hiện thành công. Công ty không có trách nhiệm hoàn trả và/hoặc đổi lại hàng hóa, sản phẩm, dịch vụ này, trừ trường hợp phát sinh lỗi kỹ thuật từ Ứng dụng Wee và/hoặc hàng hóa, sản phẩm, dịch vụ bị lỗi từ phía Nhà cung cấp dẫn đến việc hàng hóa, sản phẩm, dịch vụ không sử dụng được. Công ty sẽ tiếp nhận, xác minh và xử lý đối với các trường hợp lỗi như quy định tại khoản này cho Người dùng theo quy định của Công ty và/hoặc Nhà cung cấp trong từng thời điểm. Trong mọi trường hợp Người dùng không nhận được hàng hóa, sản phẩm, dịch vụ mặc dù đã thanh toán thành công và nguyên nhân được xem là lỗi từ Giao dịch Wee, Công ty sẽ hoàn lại khoản tiền mà Người dùng đã thanh toán cho Giao dịch Wee bị lỗi đó vào Tài khoản ngân hàng Người dùng sử dụng để liên kết với Ứng dụng Wee.',
            '4.2. Phí sử dụng Dịch vụ được Công ty công bố cho từng thời kỳ, tùy theo đặc điểm của từng loại Dịch vụ.',
            '4.3. Trong phạm vi cho phép của pháp luật, Người dùng ủy quyền cho Công ty được quyền tự động trích nợ phí sử dụng Dịch vụ vào Tài khoản ngân hàng Người dùng sử dụng để liên kết với Ứng dụng Wee và/hoặc trên các phương tiện thanh toán khác có kết nối với Ứng dụng Wee để thanh toán phí sử dụng Dịch vụ này.',
            '4.4. Phí dịch vụ có thể được thu trước, sau hay ngay khi phát sinh Giao dịch Wee tùy vào từng loại Dịch vụ mà Công ty cung cấp. Đối với hình thức thu phí dịch vụ trước, Người dùng có thể không được hoàn trả lại phần phí chưa sử dụng trong trường hợp Người dùng không có nhu cầu sử dụng tiếp Dịch vụ, Người dùng có những thay đổi về thông tin nhận diện cá nhân dẫn đến việc không thể sử dụng tiếp khoản tiền chưa sử dụng.',
        ]
    },
    {
        title: 'Điều 5: Quyền và nghĩa vụ của Người dùng',
        content: [
            '5.1. Quyền của Người dùng',
            '5.1.1. Người dùng được quyền thay đổi, bổ sung thông tin tài khoản, mật khẩu đặt lệnh, giấy tờ tùy thân hoặc giấy tờ pháp lý, email, số điện thoại gắn với tài khoản bằng việc xác nhận với Công ty thông qua văn bản đề nghị thay đổi thông tin với các lý do cụ thể và các tài liệu chứng minh hợp lệ.',
            '5.1.2. Người dùng có thể chấm dứt sử dụng Dịch vụ vào bất kỳ lúc nào sau khi đã gửi cho Công ty văn bản yêu cầu ngừng sử dụng dịch vụ theo mẫu mà Công ty cung cấp. Sau khi chấm dứt sử dụng Dịch vụ, Người dùng không thể tiếp tục sử dụng Dịch vụ.',
            '5.2. Nghĩa vụ của Người dùng',
            '5.2.1. Cung cấp thông tin đúng và đầy đủ, tuân thủ các thủ tục đăng ký, trình tự giao dịch và các hướng dẫn của Công ty.',
            '5.2.2. Sử dụng Ứng dụng Wee đúng mục đích và tuân thủ các cảnh báo của Công ty về phương thức bảo mật tài khoản nhằm hạn chế rủi ro ở mức cao nhất.',
            '5.2.3. Phối hợp với Công ty và các cơ quan chức năng khi xảy ra tranh chấp, bị gian lận, khiếu nại liên quan đến Tài khoản ứng dụng Wee.',
            '5.2.4. Lưu trữ các thông tin Giao dịch Wee, hóa đơn trực tuyến và các chứng từ liên quan đến giao dịch để làm căn cứ đối chiếu khi cần thiết.',
            '5.2.5. Khi đồng ý sử dụng Dịch vụ và chấp thuận thanh toán cho hàng hóa, sản phẩm, dịch vụ cung ứng tại Ứng dụng Wee có nghĩa Người dùng đã hiểu rõ về hàng hóa, sản phẩm hoặc dịch vụ. Công ty không chịu trách nhiệm cho bất kỳ trường hợp nào do những nhầm lẫn của Người dùng khi chọn sai hàng hóa, sản phẩm, dịch vụ, chọn nhầm số tiền v.v…',
            '5.2.6. Khi Giao dịch Wee được thực hiện thông qua một đơn vị trung gian (ngân hàng, tổ chức tín dụng, đơn vị phát hành thẻ v.v.), Người dùng có trách nhiệm tuân thủ các quy định của đơn vị trung gian này, nếu có. Mọi vấn đề phát sinh về nghĩa vụ và quyền lợi của Người dùng và đơn vị trung gian này nằm ngoài sự kiểm soát và trách nhiệm của Công ty nên Công ty sẽ được miễn trách nhiệm trong trường hợp phát sinh khiếu nại của Người dùng đối với phạm vi công việc do hoặc thuộc trách nhiệm của đơn vị trung gian cung cấp.',
            '5.2.7. Nếu Người dùng cho rằng đã xảy ra một gian lận, sai sót trong khi thực hiện Dịch vụ Wee, bao gồm nhưng không giới hạn:',
            'a) Bất kỳ chậm chễ hoặc sai sót nào trong việc xử lý chỉ dẫn thanh toán của Người dùng; hoặc',
            'b) Tài khoản ứng dụng Wee của Người dùng phát sinh giao dịch mà không do Người dùng thực hiện; hoặc',
            'c) Mật khẩu đặt lệnh và/hoặc thông tin của Người dùng đã đăng ký trên hệ thống Công ty bị tiết lộ cho Bên thứ ba; hoặc',
            'd) Có sự truy cập không hợp lệ vào tài khoản của Người dùng; hoặc',
            'e) Các lỗi khác mà Người dùng phát hiện được xuất phát từ hệ thống của Công ty.',
            'Ngay khi phát hiện bất cứ tình huống nào nêu trên, Người dùng cần ngay lập tức liên hệ trực tiếp với bộ phận hỗ trợ của Công ty để được kiểm tra và xử lý.',
            '5.2.8. Người dùng chịu trách nhiệm trong việc áp dụng mọi biện pháp hợp lý nhằm đảm bảo an toàn, đảm bảo tính tương thích cho các loại máy móc, thiết bị kết nối, phần mềm hệ thống, phần mềm ứng dụng… do Người dùng sử dụng khi kết nối, truy cập vào Tài khoản ứng dụng Wee nhằm kiểm soát, phòng ngừa và ngăn chặn việc sử dụng hoặc truy cập trái phép Tài khoản ứng dụng Wee của mình.',
            '5.2.9. Người dùng có trách nhiệm trong việc đảm bảo an toàn và bí mật đối với bất kỳ thông tin cá nhân, mật khẩu truy cập, mật khẩu đặt lệnh và các yếu tố định danh khác mà Người dùng sử dụng tại dịch vụ Wee. Người dùng hoàn toàn chịu trách nhiệm trong trường hợp các thông tin này bị mất, lợi dụng, tiết lộ cho người thứ ba và sẽ chịu mọi rủi ro, thiệt hại gây ra do việc sử dụng trái phép của người thứ ba đó.',
            '5.2.10. Người dùng cam kết ủy quyền cho Công ty được tự động trích nợ trong các phương tiện thanh toán có kết nối với Tài khoản ứng dụng Wee mà Người dùng đã kết nối (thẻ tín dụng, thẻ ghi nợ, tài khoản ngân hàng, thẻ trả trước, v.v..) để thanh toán cho các giao dịch mua hàng hóa, dịch vụ với các nhà cung cấp mà Người dùng lựa chọn mua hàng hóa và/hoặc sử dụng dịch vụ. Việc thanh toán này chỉ được thực hiện theo yêu cầu của Người dùng hoặc theo sự thỏa thuận của chính Người dùng với nhà cung cấp.',
            '5.2.11. Người dùng có trách nhiệm hoàn lại theo bất cứ hình thức nào phù hợp và thuận tiện cho Công ty, Ngân hàng liên kết, Nhà cung cấp và/hoặc bất cứ bên thứ ba nào khác trong mọi trường hợp Tài khoản ứng dụng Wee của Người dùng được ghi nhận một giá trị lớn hơn giá trị thực tế mà Người dùng nhận từ Người dùng khác và/hoặc có được bằng bất cứ hình thức hợp pháp nào khác mà Người dùng có thể chứng minh được, dù với bất cứ lý do gì. Công ty bảo lưu quyền được yêu cầu Người dùng hoàn lại giá trị chênh lệch hơn trong toàn thời gian Người dùng sử dụng Dịch vụ và cho đến khi khoản giá trị chênh lệch được hoàn về cho Công ty và/hoặc bên thứ ba nào khác là chủ sở hữu hợp pháp của khoản tiền đó.',
        ]
    },
    {
        title: 'Điều 6: Quyền và nghĩa vụ của Công ty',
        content: [
            '6.1. Quyền của Công ty',
            '6.1.1. Nếu Công ty có lý do cho rằng Người dùng vi phạm các hành vi cấm quy định tại Điều 7, Công ty sẽ có toàn quyền thực hiện những biện pháp cần thiết, hợp pháp để bảo vệ Công ty và/hoặc Người dùng khác hoặc Bên thứ ba có liên quan. Những hành động Công ty có thể thực hiện được bao gồm nhưng không giới hạn ở những hành động này:',
            'a) Công ty có thể đóng, tạm ngừng hoặc giới hạn truy nhập của Người dùng vào Tài khoản ứng dụng Wee và Dịch vụ Wee mà Công ty cung cấp;',
            'b) Công ty có thể liên hệ với Nhà cung cấp/người Mua mà Người dùng giao dịch, liên hệ với ngân hàng của Người dùng, với Người dùng khác hoặc liên hệ tới bên thứ ba để cảnh báo hoặc lưu ý tới hành động của Người dùng;',
            'c) Công ty có quyền từ chối việc thực hiện Dịch vụ cho các Giao dịch Wee, trả thưởng cho các Tài khoản ứng dụng Wee mà Công ty nhận được tố cáo gian lận, trục lợi từ một bên thứ ba và các tố cáo này là hợp lệ và có cơ sở. Đồng thời, Công ty có thể phối hợp và cung cấp thông tin cho cơ quan chức năng và bên thứ ba để xử lý các hành vi gian lận, lừa đảo, trục lợi và/hoặc vi phạm pháp luật.',
            '6.1.2. Công ty không chịu trách nhiệm đối với những thiệt hại, tổn thất của Người dùng phát sinh trong quá trình sử dụng Dịch vụ, trừ phi những thiệt hại, tổn thất này là do lỗi cố ý của Công ty.',
            '6.1.3. Công ty không chịu trách nhiệm đối với những thiệt hại trực tiếp hoặc gián tiếp mà Người dùng phải chịu phát sinh từ hoặc do:',
            'a) Việc sử dụng Dịch vụ hoặc tiếp cận các thông tin mà Dịch vụ cung ứng của những người được Người dùng ủy quyền; hoặc',
            'b) Việc Người dùng để mất, thất lạc, mất cắp thiết bị di động dùng để đăng nhập Tài khoản ứng dụng Wee, lộ mật khẩu đặt lệnh và/hoặc các yếu tố định danh khác mà Công ty cung cấp dẫn đến người khác dùng những thông tin này để sử dụng Tài khoản ứng dụng Wee hoặc Dịch vụ Wee hoặc tiếp cận những thông tin từ Dịch vụ cung ứng; hoặc',
            'c) Sự ngắt quãng, trì hoãn, chậm trễ, tình trạng không sẵn sàng sử dụng hoặc bất kỳ sự cố nào xảy ra trong quá trình cung cấp Dịch vụ do các nguyên nhân ngoài khả năng kiểm soát hợp lý của Công ty, bao gồm nhưng không giới hạn ở tình trạng gián đoạn do Dịch vụ cần được nâng cấp, sửa chữa, lỗi đường truyền của nhà cung cấp dịch vụ Internet, lỗi gián đoạn do nhà cung cấp hệ thống kỹ thuật, cơ sở hạ tầng khác; hoặc',
            'd) Bất cứ sự kiện hoặc tình huống bất khả kháng nào bao gồm nhưng không giới hạn bởi thiên tai, đình công, hoặc các yêu cầu hay chỉ thị của Chính phủ và các cơ quan nhà nước, người có thẩm quyền khác hoặc các sự kiện có tính chất tương tự.',
            '6.1.4. Quyết định phạm vi, loại thẻ ngân hàng và/hoặc tài khoản ngân hàng mà Người dùng có thể sử dụng để liên kết với Ứng dụng Wee.',
            '6.1.5. Công ty có quyền thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ vào bất kỳ thời điểm nào mà không cần có sự đồng ý của Người dùng. Tuy nhiên, Công ty có trách nhiệm thông báo cho Người dùng về việc thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ.',
            '6.1.6. Công ty có thể từ chối không thực hiện chỉ dẫn thanh toán của Người dùng trong trường hợp trong các phương tiện thanh toán có kết nối với Tài khoản ứng dụng Wee mà Người dùng lựa chọn (thẻ tín dụng, thẻ ghi nợ, tài khoản ngân hàng, thẻ trả trước v.v...) không đủ số dư; hoặc do Tài khoản ứng dụng Wee của Người dùng đã đóng hoặc không hợp lệ; hoặc do Nhà cung cấp không cho phép thực hiện thanh toán bằng phương tiện điện tử, và trong trường hợp như vậy, Công ty sẽ gửi thông báo cho Người dùng.',
            '6.1.7. Công ty không chấp nhận việc hủy bỏ chỉ dẫn thanh toán mà Người dùng đã thực hiện thành công. Tất cả các chỉ dẫn thanh toán do Người dùng đưa ra khi sử dụng Dịch vụ được coi là không hủy ngang, cho dù chỉ dẫn thanh toán này do Người dùng hay một người khác được hiểu là Người dùng thực hiện. Việc hủy bỏ phải từ Nhà cung cấp và phải được Công ty đồng ý.',
            '6.1.8. Công ty được quyền sử dụng thông tin cá nhân và dữ liệu về giao dịch của Người dùng để tùy biến và cải tiến nhằm nâng cao chất lượng Dịch vụ. Công ty sẽ không sử dụng những dữ liệu này vào bất kỳ mục đích bất hợp pháp nào. Công ty được quyền cung cấp dữ liệu của Người dùng cho bên thứ ba trong các trường hợp nhưng không giới hạn:',
            'a) Công ty được Người dùng chấp thuận; hoặc',
            'b) Dịch vụ yêu cầu sự tương tác với một bên thứ ba; hoặc',
            'c) Theo quy định của pháp luật.',
            '6.1.9. Nếu Người dùng vi phạm các cam kết về sử dụng Dịch vụ hoặc sử dụng Dịch vụ vào mục đích vi phạm pháp luật (như các trường hợp gian lận thẻ hoặc tài khoản ngân hàng để giao dịch trên hệ thống Công ty, gian lận trong mua/bán hàng hóa, mua/bán hàng hóa cấm tiêu thụ tại Việt Nam…), Công ty có quyền ngay lập tức chấm dứt việc sử dụng Dịch vụ của Người dùng, chuyển giao cho các cơ quan có thẩm quyền xử lý.',
            '6.1.10. Công ty bảo lưu quyền thay đổi, chỉnh sửa, tạm ngưng hoặc chấm dứt tất cả hoặc bất kỳ phần nào của Dịch vụ vào bất cứ thời điểm nào theo quy định pháp luật. Công ty có thể áp dụng một số Dịch vụ hoặc tính năng của Dịch vụ với phiên bản thử nghiệm mà phiên bản này có thể không đúng hoặc không hoàn toàn giống với phiên bản cuối cùng, và Công ty không chịu trách nhiệm đối với phiên bản này. Công ty có toàn quyền giới hạn một số tính năng hoặc phạm vi sử dụng của Người dùng đối với một phần hoặc toàn bộ Dịch vụ mà không cần thông báo hay chịu trách nhiệm.',
            '6.1.11. Các website (bao gồm và không giới hạn các tên miền), ứng dụng, giải pháp, các sản phẩm/dịch vụ, logo và những nội dung khác liên quan đến Dịch vụ đều thuộc quyền quản lý, sở hữu và sử dụng hợp pháp, toàn vẹn, duy nhất và không chuyển giao của Công ty. Mọi sự sao chép, thay đổi, sửa chữa mà không được phép bằng văn bản của Công ty đều bị coi là vi phạm Điều khoản Dịch vụ này và quy định pháp luật.',
            '6.2. Nghĩa vụ của Công ty',
            '6.2.1. Công ty đảm bảo rằng hệ thống Dịch vụ chạy ổn định, an toàn và tuân thủ các quy định của pháp luật liên quan đến việc cung ứng Dịch vụ.',
            '6.2.2. Hỗ trợ Người dùng, tiếp nhận và giải quyết khiếu nại của Người dùng trong quá trình sử dụng Dịch vụ.',
            '6.2.3. Bảo mật thông tin cá nhân và dữ liệu về giao dịch của Người dùng, Công ty không được bán hoặc trao đổi những thông tin này với bên thứ ba, trừ trường hợp theo quy định của pháp luật hoặc được Người dùng cho phép.',
        ]
    },
    {
        title: 'Điều 7: Các hành vi cấm',
        content: [
            'Khi sử dụng Dịch vụ, Người dùng không được:',
            '7.1. Vi phạm Thỏa thuận này và/hoặc tất cả các chính sách, quy định khác mà Người dùng đã thỏa thuận, xác nhận hoặc đồng ý với Công ty.',
            '7.2. Vi phạm quyền tác giả, bằng sáng chế, bí mật kinh doanh hoặc quyền sở hữu trí tuệ khác của Công ty và của bất kỳ bên thứ ba có liên quan.',
            '7.3. Bán hàng giả, hàng nhái, hàng cấm kinh doanh, cấm lưu hành, hàng có nguồn gốc bất hợp pháp.',
            '7.4. Hành động có tính chất làm mất uy tín, phỉ báng, bôi nhọ, đe dọa hoặc quấy rối Công ty, bất cứ Nhà cung cấp hoặc bất cứ bên nào khác có hoặc không có liên quan đến Dịch vụ.',
            '7.5. Hành động có tính chất bạo lực, hành hung và/hoặc sử dụng ngôn từ tục tĩu, bôi nhọ, phỉ báng đối với nhân viên Công ty.',
            '7.6. Cung cấp thông tin sai sự thật, gây nhầm lẫn, sử dụng từ ngữ không phù hợp với thuần phong mỹ tục Việt Nam.',
            '7.7. Gửi hoặc nhận các khoản tiền mà theo Công ty thì đó là các khoản tiền bất hợp pháp hoặc các khoản tiền vi phạm các quy định về phòng chống rửa tiền của pháp luật Việt Nam và/hoặc các quy định quốc tế mà Việt Nam phải tuân thủ trong từng thời điểm.',
            '7.8. Từ chối hợp tác điều tra hoặc không cung cấp thông tin theo yêu cầu của Công ty.',
            '7.9. Quản lý một tài khoản kết nối tới một tài khoản khác vi phạm một trong những hành vi bị cấm quy định tại Điều này.',
            '7.10. Sử dụng Dịch vụ theo cách để cố ý tạo ra các tranh chấp, khiếu nại, đòi bồi thường.',
            '7.11. Tiết lộ thông tin của Người dùng khác cho bên thứ ba hoặc sử dụng thông tin vào những mục đích tiếp thị/quảng cáo mà Người dùng chưa được cho phép.',
            '7.12. Có những hành động vượt quá giới hạn hoặc chuyển các lệnh không hợp lệ lên hệ thống của Công ty.',
            '7.13. Phát tán virus, trojan, worms hoặc các chương trình máy tính có hại gây trở ngại, gian lận hoặc ảnh hưởng tới hệ thống, dữ liệu hoặc thông tin của Người dùng khác và/hoặc của Công ty.',
            '7.14. Sử dụng bất kỳ các loại robot, spider, các thiết bị tự động khác hoặc thực hiện thủ công để giám sát hoặc sao chép website, các ứng dụng, giải pháp của Công ty mà không được Công ty cho phép.',
            '7.15. Sử dụng các thiết bị, phần mềm để vượt qua (hoặc phá hủy, làm suy yếu) các biện pháp bảo vệ mà Công ty thiết lập nhằm đảm bảo an toàn hệ thống của Công ty.',
            '7.16. Hành động làm mất kết nối giữa Công ty đến Nhà cung cấp và/hoặc đối tác, đại lý… hoặc ngược lại từ Nhà cung cấp đến Công ty.',
            '7.17. Cố tình giả mạo địa chỉ IP, giả mạo thông tin Người dùng khi đăng nhập vào Tài khoản ứng dụng Wee.',
            '7.18. Cố tình thực hiện các hành vi spam tin nhắn, email liên tiếp qua các Dịch vụ của Công ty.',
            '7.19. Mở các Tài khoản ứng dụng Wee ảo để trục lợi. Tài khoản ứng dụng Wee ảo được định nghĩa bao gồm nhưng không giới hạn:',
            'a) Tài khoản tự tạo bằng cách cung cấp các thông tin cá nhân giả mạo, không chính xác với các thông tin về tên họ, địa chỉ liên lạc, giấy tờ tùy thân …; hoặc',
            'b) Tài khoản đăng ký nhiều lần với cùng một thông tin được tạo ra vì mục đích trục lợi; hoặc',
            'c) Tài khoản ứng dụng Wee chưa định danh theo quy định của Công ty.'
        ]
    },
    {
        title: 'Điều 8: Tích hợp với nền tảng của bên thứ ba',
        content: [
            '8.1. Dịch vụ của Công ty có thể được tích hợp trên nền tảng hoặc trang web của bên thứ ba. Khi sử dụng Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba, Người dùng đồng thời đồng ý và sẽ tuân thủ các điều khoản dịch vụ của bên thứ ba đó. Người dùng cũng đồng ý rằng Công ty không chịu trách nhiệm về dịch vụ của bên thứ ba và/hoặc tính chính xác của hàng hóa/dịch vụ, bao gồm các chức năng, độ tin cậy, an ninh, chính sách bảo mật hoặc các hoạt động khác của các nền tảng hoặc trang web bên thứ ba đó.',
            '8.2. Khi Người dùng sử dụng Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba, Công ty sẽ xử lý yêu cầu của Người dùng, bao gồm nhưng không giới hạn: yêu cầu đăng ký và yêu cầu hướng dẫn thanh toán, trên hệ thống của Công ty. Chúng tôi tuyệt đối không tiết lộ dữ liệu cá nhân, mà Người dùng đã cung cấp cho Công ty, cho bên thứ ba trừ khi việc tiết lộ đó là bắt buộc để xử lý yêu cầu của chính Người dùng và trong trường hợp đó, các nền tảng hoặc trang web của bên thứ ba cũng sẽ phải tuân thủ Chính sách bảo mật của chúng tôi.',
            '8.3. Trong trường hợp Người dùng tự cung cấp dữ liệu cá nhân của mình cho các nền tảng hoặc trang web của bên thứ ba trong quá trình bạn sử dụng Dịch vụ của Công ty, thông qua các cổng thông tin được phát triển bởi bên thứ ba, nghĩa là Người dùng đồng ý rằng mọi tiết lộ, việc sử dụng, thu thập và/hoặc xử lý dữ liệu cá nhân của bạn sẽ phải tuân theo chính sách bảo mật của bên thứ ba đó.',
            '8.4. Công ty có thể, theo quyết định riêng của mình, áp đặt các giới hạn đối với một số tính năng nhất định hoặc hạn chế Người dùng sử dụng một phần hoặc toàn bộ Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba.',
        ]
    },
    {
        title: 'Điều 9: Các nguyên tắc xử lý khiếu nại',
        content: [
            '9.1. Việc tiếp nhận và xử lý khiếu nại của Công ty tuân thủ quy định của Ngân hàng Nhà nước và các quy định luật pháp liên quan.',
            '9.2. Công ty chỉ tiến hành xử lý khiếu nại khi nhận được thông tin yêu cầu từ Người dùng và/hoặc một bên thứ ba khi cung cấp các thông tin hợp lý, hợp lệ và có liên quan đến hoạt động và Dịch vụ Wee.',
            '9.3. Nhằm tránh các thất thoát tiếp tục xảy ra, khi tiếp nhận khiếu nại, Công ty sẽ thực hiện việc phong tỏa các tài khoản, các giao dịch có liên quan đến khiếu nại và tài khoản bị khiếu nại.',
            '9.4. Công ty tiến hành kiểm tra thông tin và giải quyết khiếu nại trên cơ sở công bằng, minh bạch với Người dùng và các bên.',
            '9.5. Trong trường hợp vi phạm pháp luật và/hoặc hành vi lừa đảo có tổ chức, Công ty có trách nhiệm và sẽ phối hợp với các cơ quan chức năng để cùng giải quyết.',
            '9.6. Các khiếu nại chỉ được chấp thuận là phù hợp khi các giao dịch khiếu nại phát sinh trong vòng 60 ngày kể từ ngày thực hiện Giao dịch, trừ trường hợp Người dùng chứng minh không thể thực hiện việc khiếu nại do các yếu tố bất khả kháng, sau thời hạn này, Công ty có quyền từ chối giải quyết khiếu nại.',
            '9.7. Các đối tác của Công ty đều có quy trình xử lý khiếu nại với thời hạn đã được thiết lập và Công ty phải tuân thủ vấn đề này. Vì vậy, Công ty có thể mất tối đa đến 180 ngày để xử lý khiếu nại tùy theo tính chất của các khiếu nại và Công ty khuyến khích Người dùng hợp tác và giữ liên lạc với chúng tôi đồng thời tuân thủ thời gian phản hồi thông tin trong toàn thời gian phối hợp xử lý khiếu nại để Công ty có thể thực hiện tốt việc xử lý khiếu nại này.',
            '9.8. Trong quá trình xử lý khiếu nại, Công ty có quyền đề nghị Người dùng cung cấp các bằng chứng xác minh tính trung thực của khiếu nại, chúng tôi mong nhận được sự hợp tác của Người dùng để tiến hành xử lý khiếu nại. Các thông tin Người dùng cung cấp chỉ được trao đổi với mục đích xử lý khiếu nại.',
        ]
    },
    {
        title: 'Điều 10: Các quy định khác',
        content: [
            '10.1. Liên lạc với Công ty',
            'Người dùng có thể liên lạc với bộ phận hỗ trợ của Công ty TNHH Wee Digital theo một trong các phương thức sau:',
            'a) Gọi tới số điện thoại: 079 810 1010',
            'b) Gửi email tới địa chỉ: hello@wee.vn',
            'c) Trao đổi trực tuyến với nhân viên hỗ trợ thông qua fanpage chính thức của sản phẩm https://www.facebook.com/WEEMegaApp/',
            '10.2. Cam kết thực hiện và giải quyết tranh chấp',
            'Công ty và Người dùng đồng ý nghiêm chỉnh thực hiện các Điều khoản sử dụng của Thỏa thuận này và các bản sửa đổi, bổ sung, phụ lục đính kèm (nếu có). Nếu phát sinh tranh chấp trong quá trình thực hiện, hai bên sẽ cùng nhau giải quyết bằng thương lượng hoặc hòa giải. Trường hợp thương lượng hoặc hòa giải không thành, thì một trong các bên có quyền đưa vụ tranh chấp ra Tòa án có thẩm quyền để giải quyết.',
            '10.3. Tất cả các điều khoản và điều kiện của Thỏa thuận này vẫn có hiệu lực sau khi Người dùng ngừng hoặc chấm dứt Dịch vụ. Ngay cả khi ngừng, chấm dứt sử dụng Dịch vụ, Người dùng vẫn bị ràng buộc bởi các điều khoản và điều kiện này ở chừng mực liên quan đến quyền và nghĩa vụ của Người dùng trong thời gian sử dụng Dịch vụ',
        ]
    },
]
export const PrivacyPolicyData = [
    {
        title: 'Điều 1: Nguyên tắc chung',
        content: [
            '1.1. Chào mừng bạn đến với Ứng dụng thanh toán di động Wee (“Ứng dụng”) được Công ty TNHH Wee Digital (“Công ty” hoặc “Chúng tôi”) và các công ty liên kết, các đơn vị liên quan vận hành. Công ty nghiêm túc chịu trách nhiệm theo luật pháp và các quy định về quyền riêng tư và cam kết tôn trọng những quyền riêng tư của tất cả Người dùng (“bạn”) Ứng dụng của chúng tôi. Chúng tôi thừa nhận tầm quan trọng của dữ liệu cá nhân mà bạn đã tin tưởng cung cấp cho chúng tôi và tin rằng chúng tôi có trách nhiệm quản lý, bảo vệ và xử lý đúng mực các dữ liệu cá nhân của bạn. Chính sách bảo mật này ("Chính sách") được xây dựng để hỗ trợ bạn hiểu cách chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân mà bạn đã cung cấp cho chúng tôi và/hoặc dữ liệu mà chúng tôi nắm giữ về bạn, dù là hiện tại hay tương lai, cũng như giúp bạn trong việc đưa ra quyết định đã được thông báo trước khi cung cấp cho chúng tôi bất cứ dữ liệu cá nhân nào của bạn. Vui lòng đọc kỹ Chính sách bảo mật này. Nếu bạn có bất kỳ thắc mắc nào về những thông tin này hoặc về cách thức bảo mật của chúng tôi, vui lòng xem phần có tiêu đề "Câu hỏi, Thắc mắc hoặc Khiếu nại? Hãy liên hệ với chúng tôi" ở phần cuối Chính sách bảo mật này.',
            '1.2. "Dữ liệu Cá nhân" có nghĩa là dữ liệu, dù thật hay không, về một cá nhân có thể được định danh từ dữ liệu đó, hoặc từ dữ liệu đó và các thông tin khác mà một tổ chức đã hoặc gần như có thể tiếp cận. Các ví dụ thông thường về dữ liệu cá nhân bao gồm tên, số chứng minh nhân dân, căn cước công dân và thông tin liên lạc.',
            '1.3. Bằng việc sử dụng Dịch vụ trên Ứng dụng, đăng ký một tài khoản với chúng tôi, truy cập Ứng dụng của chúng tôi, bạn thừa nhận và đồng ý rằng bạn chấp thuận với các phương thức, yêu cầu, và/hoặc chính sách được quy định trong Chính sách bảo mật này, và rằng bạn theo đây đồng ý cho chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như được mô tả trong Chính sách này. NẾU BẠN KHÔNG ĐỒNG Ý CHO CHÚNG TÔI XỬ LÝ DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ ĐƯỢC QUY ĐỊNH TRONG CHÍNH SÁCH BẢO MẬT NÀY, VUI LÒNG KHÔNG SỬ DỤNG DỊCH VỤ CỦA CHÚNG TÔI HOẶC TRUY CẬP NỀN TẢNG CỦA CHÚNG TÔI. Nếu chúng tôi thay đổi Chính sách của chúng tôi, chúng tôi sẽ đăng những thay đổi đó hoặc Chính sách đã được sửa đổi trên Ứng dụng/website và các công cụ thông tin khác của chúng tôi. Chúng tôi bảo lưu quyền sửa đổi Chính sách này bất cứ lúc nào.',
        ]
    },
    {
        title: 'Điều 2: Công ty sẽ thu thập dữ liệu cá nhân của bạn khi nào?',
        content: [
            '2.1. Chúng tôi sẽ/có thể thu thập Dữ liệu Cá nhân về bạn:',
            '(a) Khi bạn đăng ký và/hoặc sử dụng các dịch vụ hoặc Ứng dụng của chúng tôi, hoặc mở một tài khoản với chúng tôi.',
            '(b) Khi bạn nộp bất cứ biểu mẫu nào bao gồm nhưng không giới hạn ở các mẫu đơn hoặc các mẫu khác liên quan đến các sản phẩm và dịch vụ của chúng tôi, kể cả trực tuyến hay bằng các hình thức tài liệu vật lý.',
            '(c) Khi bạn ký kết bất kỳ thỏa thuận nào hoặc cung cấp bất kỳ tài liệu hoặc thông tin khác liên quan đến sự tương tác của bạn với chúng tôi, hoặc khi bạn sử dụng sản phẩm và dịch vụ của chúng tôi.',
            '(d) Khi bạn tương tác với chúng tôi, chẳng hạn như qua điện thoại, (có thể được ghi âm), thư, fax, gặp trực tiếp, mạng xã hội và email.',
            '(e) Khi bạn sử dụng các dịch vụ điện tử hoặc tương tác với chúng tôi thông qua Ứng dụng của chúng tôi hoặc sử dụng dịch vụ trên Ứng dụng của chúng tôi. Điều này bao gồm, nhưng không giới hạn thông qua cookie chúng tôi có thể triển khai khi bạn tương tác với ứng dụng hoặc Ứng dụng của chúng tôi.',
            '(f) Khi bạn thực hiện giao dịch thông qua Ứng dụng hoặc các dịch vụ của chúng tôi.',
            '(g) Khi bạn gửi chúng tôi phản hồi hoặc khiếu nại.',
            '(h) Khi bạn cung cấp dữ liệu cá nhân của bạn cho chúng tôi vì bất kỳ lý do nào.',
            'Các quy định trên đây không bao hàm đầy đủ và chỉ đưa các ví dụ thông thường khi nào dữ liệu cá nhân về bạn có thể được thu thập.',
            '2.2. Khi bạn truy cập, sử dụng hoặc tương tác với Ứng dụng của chúng tôi, chúng tôi có thể thu thập một số thông tin nhất định bằng các phương tiện tự động hoặc thụ động bằng nhiều công nghệ khác nhau, trong đó các công nghệ có thể được tải về thiết bị của bạn và có thể thiết lập hoặc sửa đổi cài đặt trên thiết bị của bạn. Thông tin mà chúng tôi thu thập có thể bao gồm, nhưng không giới hạn, địa chỉ giao thức Internet (IP), hệ điều hành máy tính/thiết bị di động và loại trình duyệt, chủng loại thiết bị di động, các đặc tính của thiết bị di động, đặc điểm nhận dạng thiết bị duy nhất (UDID) hoặc định danh thiết bị di động (MEIDs) cho thiết bị di động của bạn, địa chỉ của trang web giới thiệu (nếu có) và các trang mà bạn ghé thăm trên Nền tảng của chúng tôi và các ứng dụng di động và số lần truy cập. Chúng tôi có thể thu thập, sử dụng, tiết lộ và/hoặc xử lý các thông tin này chỉ cho các Mục đích (như được định nghĩa dưới đây).',
            '2.3. Các ứng dụng di động của chúng tôi có thể thu thập thông tin chính xác về vị trí của thiết bị di động của bạn, ví dụ như GPS, Wi-Fi, v.v ... Chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý các thông tin này cho một hoặc nhiều Mục đích bao gồm, nhưng không giới hạn các dịch vụ dựa trên vị trí bạn yêu cầu hoặc cung cấp nội dung liên quan cho bạn dựa trên vị trí của bạn hoặc cho phép bạn chia sẻ vị trí của mình với những người dùng khác như là một phần của các dịch vụ theo ứng dụng di động của chúng tôi. Đối với hầu hết các thiết bị di động, bạn có thể thu hồi quyền cho phép chúng tôi nhận thông tin về vị trí của bạn thông qua các cài đặt trên thiết bị của bạn. Nếu bạn có bất kỳ câu hỏi nào về cách vô hiệu hóa các dịch vụ liên quan đến vị trí thiết bị di động của mình, vui lòng liên hệ với nhà cung cấp dịch vụ thiết bị di động hoặc nhà sản xuất thiết bị.',
        ]
    },
    {
        title: 'Điều 3: Dữ liệu Cá nhân nào Công ty sẽ thu thập?',
        content: [
            '3.1. Dữ liệu Cá nhân mà Công ty có thể thu thập bao gồm, nhưng không giới hạn:',
            'Tên;',
            'Địa chỉ email;',
            'Địa chỉ thanh toán;',
            'Tài khoản ngân hàng và thông tin thanh toán;',
            'Thông tin thẻ tín dụng/ghi nợ và thông tin thanh toán;',
            'Số điện thoại;',
            'Giới tính;',
            'Khuôn mặt;',
            'Bất kỳ thông tin nào khác về người dùng khi người dùng đăng ký sử dụng dịch vụ hoặc Ứng dụng của chúng tôi, và khi người dùng sử dụng các Dịch vụ hoặc Ứng dụng, cũng như thông tin liên quan đến phương thức người dùng sử dụng các Dịch vụ hoặc Ứng dụng của chúng tôi;',
            'Dữ liệu tổng về nội dung do người dùng đã tương tác.',
            '3.2. Nếu bạn không muốn chúng tôi thu thập dữ liệu/thông tin cá nhân được đề cập trên, bạn có thể lựa chọn hủy bất cứ lúc nào bằng cách thông báo cho chúng tôi bằng văn bản. Những thông tin thêm về hủy dữ liệu sẽ được tìm thấy trong phần dưới đây có tiêu đề "Làm thế nào bạn có thể hủy, xóa, yêu cầu tiếp cận hoặc chỉnh sửa thông tin bạn đã cung cấp cho chúng tôi?" Lưu ý, tuy nhiên, rằng việc hủy bỏ việc chúng tôi thu thập dữ liệu cá nhân hoặc rút lại chấp thuận cho chúng tôi thu thập, sử dụng hoặc xử lý dữ liệu cá nhân của bạn có thể ảnh hưởng đến việc sử dụng các Dịch vụ.',
            '3.3. Chi tiết thẻ tín dụng của bạn sẽ không bị bán, trao đổi, chuyển giao, hoặc trao cho bất cứ công ty nào khác vì bất cứ lý do gì, mà không có sự đồng ý của bạn, khác hơn mục đích rõ ràng cho việc giao dịch vụ mà bạn đã yêu cầu. Quy định này loại trừ việc chuyển giao dữ liệu thẻ tín dụng của bạn cho bên thứ ba là công ty xử lý thanh toán của chúng tôi. Công ty xử lý thanh toán của chúng tôi lưu giữ tất cả các chi tiết thẻ tín dụng của bạn (chúng tôi không lưu giữ dữ liệu thẻ tín dụng của bạn, và những thông tin tương tự khác được chuyển giao trực tiếp đến hệ thống lưu trữ an toàn của họ, mà không đi qua các máy chủ của chúng tôi).',
        ]
    },
    {
        title: 'Điều 4: Thiết lập tài khoản',
        content: [
            'Để sử dụng một số chức năng của các Dịch vụ, bạn sẽ cần phải tạo một tài khoản nơi bạn cần gửi một số dữ liệu cá nhân nhất định. Khi bạn đăng ký và tạo một tài khoản, chúng tôi yêu cầu bạn cung cấp số điện thoại của bạn, và theo tùy chọn, tên bạn, địa chỉ email và tên người dùng. Khi kích hoạt tài khoản, bạn sẽ đăng nhập với số điện thoại di động và mật khẩu dùng một lần (“OTP”). Cho việc mở khóa sau đó của ứng dụng, một mật khẩu hoặc hình mở khóa mà bạn đã lựa chọn có thể được sử dụng.'
        ]
    },
    {
        title: 'Điều 5: Xem trang web',
        content: [
            'Như hầu hết các Ứng dụng, máy tính/thiết bị di động của bạn sẽ gửi thông tin có thể chứa dữ liệu cá nhân về bạn sẽ được lưu lại bởi máy chủ khi bạn tìm kiếm các trang web thông qua Ứng dụng của chúng tôi. Điều này thường bao gồm nhưng không giới hạn địa chỉ IP của máy tính/thiết bị di động, hệ điều hành, tên/phiên bản trình duyệt, trang web giới thiệu, ngày/tháng và đôi lúc một "cookie" (bạn có thể tắt bằng cách tùy chỉnh trình duyệt của bạn) để giúp Ứng dụng gợi nhớ lần cuối bạn ghé thăm. Khi bạn đăng nhập, thông tin này sẽ được liên kết với tài khoản cá nhân của bạn. Thông tin này cũng bao gồm các thống kê ẩn danh cho phép chúng tôi hiểu cách mà khách sử dụng Ứng dụng của chúng tôi.',
        ]
    },
    {
        title: 'Điều 6: Cookies',
        content: [
            '6.1. Chúng tôi có thể tùy từng thời điểm sử dụng "cookies" hoặc các tính năng khác để giúp chúng tôi hoặc những bên thứ ba thu thập hoặc chia sẻ thông tin để giúp chúng tôi cải tiến Ứng dụng và các dịch vụ mà chúng tôi cung cấp, hoặc giúp chúng tôi giới thiệu các dịch vụ và tính năng mới. "Cookies" là những điểm định danh, chúng tôi gửi đến máy tính hoặc thiết bị di động của bạn để giúp chúng tôi nhận ra máy tính hoặc thiết bị di động của bạn và cho chúng tôi biết cách và khi nào các dịch vụ hoặc Ứng dụng được sử dụng hoặc truy cập, bởi bao nhiêu người và theo dõi dữ liệu hoạt động trên Ứng dụng chúng tôi. Cookies có thể liên kết tới thông tin liên quan đến món hàng mà bạn đã chọn cho các trang mua sắm mà bạn đã ghé thăm, hoặc các trò chơi mà bạn đã chơi. Cookies cũng được sử dụng để cung cấp nội dung cụ thể cho mối quan tâm của bạn và để kiểm tra việc sử dụng trang web hoặc Ứng dụng.',
            '6.2. Bạn có thể từ chối cookie bằng cách chọn cài đặt thích hợp trong trình duyệt của bạn. Tuy nhiên, lưu ý rằng nếu bạn từ chối cookie, bạn có thể không sử dụng đầy đủ chức năng của trang web được truy cập hoặc các Dịch vụ.',
        ]
    },
    {
        title: 'Điều 7: Xem và tải nội dung và quảng cáo',
        content: [
            'Khi bạn lướt web, khi bạn truy cập phần mềm khác hoặc những trang web trên Nền tảng của chúng tôi hoặc thông qua các dịch vụ, hầu hết các thông tin tương tự được gửi cho chúng tôi (bao gồm, nhưng không giới hạn địa chỉ IP, hệ điều hành, v.v.), nhưng thay vì gửi lượng truy cập, máy tính/thiết bị di động của bạn gửi đến chúng tôi thông tin về nội dung, mẩu quảng cáo và/hoặc phần mềm được cài đặt bởi các dịch vụ và Ứng dụng và thời gian.'
        ]
    },
    {
        title: 'Điều 8: Cộng đồng & Hỗ trợ',
        content: [
            'Chúng tôi cung cấp hỗ trợ dịch vụ khách hàng thông qua đường dây nóng, email và tính năng phản hồi trên Ứng dụng. Để hỗ trợ khách hàng, chúng tôi có thể yêu cầu địa chỉ email của bạn. Ngoài những thông tin này, chúng tôi không yêu cầu bất cứ dữ liệu cá nhân nào khác để hỗ trợ khách hàng. Chúng tôi chỉ sử dụng thông tin nhận được các yêu cầu hỗ trợ khách hàng, bao gồm, nhưng không giới hạn địa chỉ email, cho các dịch vụ hỗ trợ khách hàng và chúng tôi không chuyển giao hoặc chia sẻ thông tin này với bất kỳ bên thứ ba nào.',
        ]
    },
    {
        title: 'Điều 9: Khảo sát',
        content: [
            'Trong từng thời điểm, chúng tôi có thể yêu cầu thông tin từ người dùng thông qua các cuộc khảo sát. Tham gia những khảo sát hoàn toàn là tự nguyện và bạn, vì vậy có thể chọn có hoặc không chia sẻ thông tin của bạn với chúng tôi. Thông tin được yêu cầu có thể bao gồm, nhưng không giới hạn (ví dụ như địa chỉ email), và thông tin nhân khẩu (như sở thích hoặc tuổi). Thông tin thu được từ khảo sát sẽ được sử dụng cho các mục đích kiểm soát và cải tiến việc sử dụng và sự hài lòng về các dịch vụ và sẽ không bị chuyển giao cho bất cứ bên thứ ba nào, khác các đơn vị tư vấn giúp chúng tôi quản lý và thực hiện khảo sát.',
        ]
    },
    {
        title: 'Điều 10: Chúng tôi sử dụng thông tin bạn cung cấp cho chúng tôi như thế nào?',
        content: [
            '10.1. Chúng tôi có thể thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn cho một hoặc nhiều mục đích sau:',
            '(a) Để xem xét và/hoặc xử lý các hành vi/giao dịch với chúng tôi hoặc giao dịch hoặc các giao tiếp của bạn với các bên thứ ba thông qua các dịch vụ;',
            '(b) Để quản lý, vận hành, cung cấp và/hoặc quản trị việc sử dụng của bạn và/hoặc truy cập các dịch vụ và ứng dụng của chúng tôi, cũng như mối quan hệ và tài khoản người dùng với chúng tôi;',
            '(c) Để quản lý, vận hành, quản trị và cung cấp cho bạn cũng như tạo điều kiện cho việc cung cấp các dịch vụ của chúng tôi, bao gồm, nhưng không giới hạn, việc ghi nhớ các sở thích của bạn;',
            '(d) Tùy chỉnh trải nghiệm của bạn thông qua các dịch vụ bằng cách hiển thị nội dung dựa trên mối quan tâm và sở thích, cung cấp cho bạn một phương thức nhanh chóng hơn truy cập tài khoản của bạn và gửi thông tin cho chúng tôi và cho phép chúng tôi liên lạc với bạn, nếu cần;',
            '(e) Để phản hồi, xử lý hoặc giải quyết hoặc hoàn thành một giao dịch, và/hoặc hoàn tất các yêu cầu của bạn cho các sản phẩm và dịch vụ nhất định và thông báo cho bạn các vấn đề dịch vụ và các hoạt động tài khoản bất thường;',
            '(f) Để thực thi Điều khoản Dịch vụ hoặc những thỏa thuận cấp phép cho người dùng cuối được áp dụng;',
            '(g) Để bảo vệ an toàn cá nhân và quyền, tài sản hoặc an toàn của người khác;',
            '(h) Cho việc định danh và/hoặc xác minh;',
            '(i) Để duy trì và kiểm soát bất kỳ cập nhật phần mềm nào và/hoặc cập nhật và hỗ trợ cần thiết theo thời gian để đảm bảo các dịch vụ của chúng tôi được vận hành suôn sẻ.',
            '(j) Để giải quyết hoặc tạo điều kiện cho dịch vụ khách hàng, thực hiện các chỉ thị của bạn, xử lý hoặc trả lời bất cứ câu hỏi đưa ra (hoặc ngụ ý được đưa ra) từ bạn hoặc nhân danh bạn.',
            '(k) Để liên lạc hoặc giao tiếp với bạn thông qua cuộc gọi thoại, tin nhắn văn bản và/hoặc fax, email và/hoặc thư hoặc hình thức nào khác cho những mục đích quản trị và/hoặc quản lý mối quan hệ của bạn với chúng tôi hoặc việc sử dụng các dịch vụ của chúng tôi. Bạn thừa nhận và đồng ý rằng cách giao tiếp như vậy của chúng tôi có thể bằng phương thức gửi mail tương ứng, tài liệu hoặc thông báo cho bạn, và có thể liên quan đến việc tiết lộ các dữ liệu cá nhân nhất định về bạn để chuyển giao những tài liệu tương tự cũng như trên các bao bì bên ngoài của phong bì, bao gói của thư tín.',
            '(l) Để thông báo cho bạn khi những người dùng khác gửi tin nhắn riêng hoặc đăng nhận xét về bạn trên Nền tảng.',
            '(m) Để tiến hành các hoạt động nghiên cứu, phân tích và phát triển (bao gồm, nhưng không giới hạn phân tích dữ liệu, khảo sát, phát triển sản phẩm và dịch vụ và/hoặc tạo hồ sơ), để phân tích cách bạn sử dụng các dịch vụ, cải tiến các dịch vụ hoặc sản phẩm của chúng tôi và/hoặc nâng cao trải nghiệm của khách hàng.',
            '(n) Để cho phép quảng cáo và các hoạt động kiểm toán khác và khảo sát, trong số những hoạt động khác, xác định phạm vi và thành phần của khách hàng mục tiêu, và hiểu trải nghiệm của khách hàng với các dịch vụ của Công ty.',
            '(o) Trong trường hợp bạn đồng ý trước, cho mục đích marketing và liên quan đến việc này, Công ty sẽ gửi đến bạn bằng nhiều phương thức giao tiếp khác nhau như thư tín, email, các dịch vụ dự trên vị trí hoặc các hình thức khác, thông tin marketing và khuyến mại và các tài liệu liên quan đến sản phẩm và/hoặc dịch vụ (bao gồm, nhưng không giới hạn các sản phẩm và/hoặc dịch vụ của những bên thứ ba mà Công ty có thể hợp tác hoặc liên kết) rằng Công ty (và/hoặc các công ty liên kết hoặc liên quan) có thể bán, tiếp thị hoặc quảng bá, kể cả những sản phẩm hoặc dịch vụ như vậy hiện có hoặc được tạo ra trong tương lai.',
            '(p) Để đáp ứng các quy trình pháp lý hoặc tuân thủ hoặc như được yêu cầu bởi bất cứ luật hiện hành, các yêu cầu luật định hoặc chính phủ của bất cứ thẩm quyền tài phán liên quan nào bao gồm, nhưng không giới hạn việc đáp ứng các yêu cầu công bố theo các yêu cầu của bất cứ luật nào ràng buộc Công ty hoặc các công ty liên quan hoặc liên kết.',
            '(q) Để tạo ra các thống kê và nghiên cứu để báo cáo nội bộ và luật định và/hoặc những yêu cầu lưu giữ hồ sơ.',
            '(r) Để thực hiện thẩm định hoặc bất kỳ hoạt động sàng lọc nào khác (bao gồm, nhưng không giới hạn việc kiểm tra lý lịch) theo các nghĩa vụ pháp lý hoặc luật định hoặc quy trình quản lý rủi ro của chúng tôi được điều chỉnh bởi luật pháp hoặc nơi những vấn đề liên quan có thể chúng tôi đưa ra.',
            '(s) Để kiểm toán các dịch vụ của chúng tôi hoặc hoạt động kinh doanh của Công ty.',
            '(t) Để ngăn ngừa hoặc điều tra bất cứ gian lận, hoạt động bất hợp pháp, hành vi sai trái hoặc thiếu sót, bất kể liên quan đến việc sử dụng các dịch vụ của chúng tôi hay bất kỳ hoạt động nào khác phát sinh từ mối quan hệ của bạn với chúng tôi, và bất kể có hay bất kỳ nghi ngờ nào của những điều ở trên.',
            '(u) Để lưu trữ, quản lý, dự phòng (bất kể cho việc phục hồi sự cố hoặc cho mục đích khác) dữ liệu cá nhân của bạn, cho dù trong hoặc ngoài thẩm quyền tài phán đối với bạn.',
            '(v) Để giải quyết và/hoặc tạo điều kiện cho một giao dịch kinh doanh tài sản hoặc một giao dịch kinh doanh tài sản tiềm năng, khi giao dịch này liên quan đến Công ty như là một người tham gia hoặc liên quan đến một công ty liên quan hoặc liên kết của Công ty như một người tham gia, và có thể có các tổ chức thứ ba là những người tham gia vào giao dịch như vậy. Một "Giao dịch kinh doanh tài sản" dẫn chiếu đến việc mua, bán, cho thuê, hợp nhất, sáp nhập hoặc bất cứ hoạt động mua lại, xử lý hoặc cấp vốn nào khác cho một tổ chức hoặc bất kỳ phần nào của một tổ chức hoặc bất kỳ hoạt động kinh doanh hoặc tài sản của một tổ chức; và/hoặc',
            '(w) Bất kỳ mục đích nào khác mà chúng tôi thông báo cho bạn vào thời điểm nhận được chấp thuận của bạn.',
            '10.2. Cho những mục đích mà chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý đối với dữ liệu cá nhân của bạn phụ thuộc vào những trường hợp cụ thể, những mục đích như vậy có thể không xuất hiện ở trên. Tuy nhiên, chúng tôi sẽ thông báo cho bạn mục đích khác như vậy vào thời điểm nhận được sự chấp thuận của bạn, trừ khi việc xử lý dữ liệu liên quan không cần sự đồng ý của bạn là phù hợp với quyền riêng tư.',
        ]
    },
    {
        title: 'Điều 11: Chia sẻ thông tin từ các Dịch vụ',
        content: [
            'Các dịch vụ của chúng tôi có thể cho những người dùng chia sẻ thông tin cá nhân với người khác, trong hầu hết các trường hợp mà không cần sự tham gia của Công ty, để hoàn thành các giao dịch. Trong một giao dịch điển hình, những người dùng có thể tiếp cận tên,user ID, địa chỉ email và các thông tin liên lạc khác và thông tin thư tín của người khác. Điều khoản Dịch vụ yêu cầu những người dùng đang sở hữu dữ liệu cá nhân của người dùng khác (“Bên nhận thông tin”) phải i) tuân thủ tất cả quyền riêng tư hiện hành; ii) cho phép người dùng khác (“Bên tiết lộ”) xóa bỏ thông tin của họ ra khỏi cơ sở dữ liệu của Bên nhận thông tin; và iii) cho phép Bên tiết lộ xem xét các thông tin đã được thu thập về họ bởi Bên nhận thông tin.'
        ]
    },
    {
        title: 'Điều 12: Công ty bảo vệ thông tin khách hàng như thế nào?',
        content : [
            'Chúng tôi thực hiện các biện pháp an ninh khác nhau để bảo đảm tính an toàn các dữ liệu cá nhân của bạn trên hệ thống của chúng tôi. Dữ liệu cá nhân của người dùng được lưu trữ trong hệ thống mạng an toàn và chỉ có thể được truy cập bởi một số lượng nhân viên hạn chế người có đặc quyền truy cập vào các hệ thống như vậy. Chúng tôi sẽ lưu giữ dữ liệu cá nhân của bạn phù hợp với quyền riêng tư và các luật hiện hành khác. Và như vậy, chúng tôi sẽ hủy hoặc chuyển chế độ ẩn danh ngay khi đã hợp lý xác nhận rằng: (i) mục đích mà dữ liệu cá nhân đã được thu thập không còn phù hợp cho việc giữ lại các dữ liệu cá nhân; Và (ii) việc giữ lại không còn cần thiết cho bất cứ mục đích pháp lý hoặc kinh doanh nào. Nếu bạn ngừng sử dụng Ứng dụng hoặc quyền sử dụng Ứng dụng và/hoặc các dịch vụ kết thúc, chúng tôi có thể tiếp tục lưu trữ, sử dụng và/hoặc tiết lộ dữ liệu cá nhân theo Chính sách Bảo mật này và nghĩa vụ của chúng tôi theo Luật Riêng tư. Căn cứ luật pháp hiện hành, chúng tôi có thể hủy bỏ dữ lệu cá nhân của bạn một cách an toàn mà không cần báo trước.',
        ]
    },
    {
        title: 'Điều 13: Công ty có tiết lộ thông tin thu thập từ khách ghé thăm cho các bên khác không?',
        content: [
            '13.1. Khi thực hiện hoạt động kinh doanh của chúng tôi, chúng tôi sẽ/có thể cần tiết lộ dũ liệu cá nhân của bạn cho các bên thứ ba đơn vị cung cấp dịch vụ, đại lý và/hoặc công ty liên kết hoặc đơn vị liên quan của chúng tôi và/hoặc bên thứ ba khác cho dù tọa lạc trong hay ngoài Việt Nam, cho một hoặc nhiều Mục đích đã đề cập ở trên. Các bên thứ ba đơn vị cung cấp dịch vụ, đại lý và/hoặc công ty liên kết hoặc đơn vị liên quan và/hoặc các bên thứ ba khác có thể được xử lý dữ liệu cá nhân của bạn thay mặt chúng tôi hoặc bằng cách khác, cho một hoặc nhiều Mục đích đã đề cập ở trên. Những bên thứ ba như vậy bao gồm, nhưng không giới hạn:',
            '(a) Công ty con, công ty liên kết và các đơn vị liên quan của chúng tôi;',
            '(b) Nhà thầu, đại lý, nhà cung cấp dịch vụ và bên thứ ba khác chúng tôi sử dụng để hỗ trợ hoạt động kinh doanh của chúng tôi. Những bên này bao gồm nhưng không giới hạn là những bên cung cấp các dịch vụ hành chính hoặc dịch vụ khác như là các công ty thư tín, công ty viễn thông, công ty công nghệ thông tin và trung tâm dữ liệu',
            '(c) Người mua hoặc người kế nhiệm trong trường hợp sáp nhập, chia sách, tái cơ cấu, tổ chức lại, giải thể hoặc bán hoặc chuyển nhượng một phần hoặc toàn bộ tài sản của Công ty, dù là đang diễn ra hay là một phần của việc phá sản, thanh lý hoặc thủ tục tương tự, trong đó dữ liệu cá nhân mà Công ty đang nắm giữ về người dùng của chúng tôi là một trong số tài sản được chuyển nhượng; hoặc đối với một đối tác trong một giao dịch liên quan đến tài sản kinh doanh mà Công ty hoặc bất kỳ công ty liên kết nào của Công ty hoặc đơn vị liên quan tham gia vào; và',
            '(d) Các bên thứ ba được chúng tôi tiết lộ cho một hoặc nhiều Mục đích và các bên thứ ba đó có thể thu thập và xử lý dữ liệu cá nhân của bạn cho một hoặc nhiều Mục đích.',
            '13.2. Chúng tôi có thể, trong số những thông tin khác, chia sẻ thông tin thống kê và nhân khẩu về người dùng của chúng tôi và việc sử dụng Các Dịch vụ của họ cho các nhà cung ứng quảng cáo và lập trình. Điều này sẽ không bao gồm bất cứ nội dung nào có thể sử dụng để nhận dạng bạn một cách riêng biệt hoặc tìm ra thông tin cá nhân về bạn.',
            '13.3. Để tránh nghi ngờ, trong trường hợp Luật Riêng tư hoặc các luật hiện hành khác cho phép một tổ chức như chúng tôi thu thập, sử dụng hoặc tiết lộ dữ liệu cá nhân của bạn mà không sự chấp thuận của bạn, quyền như vậy được cho phép theo luật định sẽ tiếp tục áp dụng.',
            '13.4. Các bên thứ ba có thể chặn hoặc truy cập bất hợp pháp dữ liệu cá nhân được chuyển đến hoặc lưu trữ trên Ứng dụng, công nghệ có thể trục trặc hoặc không hoạt động như mong đợi, hoặc ai đó có thể truy cập, lạm dụng hoặc sử dụng sai thông tin mặc dù không có lỗi của chúng tôi. Dù sao chúng tôi sẽ tìm các biện pháp bảo mật hợp lý để bảo vệ dữ liệu cá nhân của bạn như được yêu cầu trong Luật Riêng tư; tuy nhiên, chúng tôi không thể bảo đảm tuyệt đối an toàn chẳng hạn như, nhưng không giới hạn, khi việc tiết lộ trái phép phát sinh từ xâm nhập độc hại và phức tạp bởi những người bất mãn mặc dù không có lỗi của chúng tôi.',
        ]
    },
    {
        title: 'Điều 14: Thông tin về trẻ em',
        content: [
            'Các Dịch vụ không được chủ ý cho trẻ em dưới 10 tuổi. Chúng tôi không thu thập hoặc duy trì bất cứ dữ liệu cá nhân hoặc thông tin định danh không mang tính cá nhân của bất kỳ ai dưới 10 tuổi hoặc bất cứ phần của Ứng dụng của chúng tôi hoặc các Dịch vụ khác hướng tới trẻ em dưới 10 tuổi. Chúng tôi sẽ đóng bất cứ tài khoản nào được sử dụng duy nhất bởi những trẻ em như vậy và sẽ hủy và/hoặc xóa bỏ bất cứ dữ liệu cá nhân nào mà chúng tôi cho rằng đã được cung cấp bởi bất kỳ trẻ em nào dưới 10 tuổi.',
        ]
    },
    {
        title: 'Điều 15: Thông tin thu thập của bên thứ ba',
        content: [
            '15.1. Ứng dụng của chúng tôi sử dụng dịch vụ phân tích bên thứ ba, tận dụng cookies, là các tệp văn bản được lưu trữ trên máy tính/thiết bị di động của bạn, để giúp Ứng dụng phân tích phương thức sử dụng Ứng dụng của người dùng. Thông tin tạo ra do cookies về việc sử dụng Ứng dụng (bao gồm địa chỉ IP của bạn) sẽ được gửi đến và lưu trữ bởi các dịch vụ phân tích trên các máy chủ ở nhiều quốc gia. Các dịch vụ phân tích sẽ sử dụng thông tin này để đánh giá việc sử dụng Ứng dụng, biên soạn báo cáo về hoạt động Ứng dụng cho những người vận hành Ứng dụng và cung cấp các dịch vụ khác liên quan đến hoạt động Ứng dụng và sử dụng Internet. Các dịch vụ phân tích cũng có thể chuyển thông tin này cho các bên thứ ba theo yêu cầu của luật pháp, hoặc khi bên thứ ba xử lý thông tin thay mặt các dịch vụ phân tích. Các dịch vụ phân tích sẽ không liên kết địa chỉ IP của bạn với bất kỳ dữ liệu nào khác được các dịch vụ phân tích nắm giữ.',
            '15.2. Chúng tôi, và các bên thứ ba, có thể trong từng thời điểm tải xuống ứng dụng phần mềm để bạn có thể sử dụng hoặc thông qua các dịch vụ. Các ứng dụng này có thể được truy cập riêng, và cho phép một bên thứ ba xem, thông tin nhận dạng cá nhân của bạn, ví dụ như tên, user ID và địa chỉ IP máy tính/thiết bị di động của bạn hoặc bất kỳ thông tin nào khác trong suốt thời hạn được cung cấp; và bất kỳ cookie nào mà bạn có thể đã cài đặt trước đó hoặc đã được cài đặt cho bạn bởi một bên website hoặc ứng dụng thứ ba. Ngoài ra, các ứng dụng này cũng có thể yêu cầu bạn cung cấp thêm thông tin trực tiếp cho bên thứ ba. Các sản phẩm hoặc dịch vụ của bên thứ ba được cung cấp thông qua các ứng dụng này không được sở hữu hoặc kiểm soát bởi Công ty. Bạn được khuyến khích đọc các điều khoản và chính sách khác từ các bên thứ ba như vậy trên trang web của họ hoặc bằng phương thức khác.',
        ]
    },
    {
        title: 'Điều 16: Miễn trách nhiệm liên quan đến bảo mật và trang web của bên thứ ba',
        content: [
            '16.1. CHÚNG TÔI KHÔNG ĐẢM BẢO TÍNH AN TOÀN CỦA DỮ LIỆU CÁ NHÂN VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN CUNG CẤP TRÊN TRANG CỦA BÊN THỨ BA. Chúng tôi thực hiện các biện pháp an ninh khác nhau để duy trì sự an toàn cho dữ liệu cá nhân của bạn mà chúng tôi sở hữu hoặc kiểm soát. Dữ liệu cá nhân của bạn được lưu trữ trong một mạng an toàn và chỉ có thể được truy cập bởi một số lượng nhân viên hạn chế là người có đặc quyền truy cập các hệ thống như vậy, và được yêu cầu giữ dữ liệu cá nhân bảo mật. Khi bạn đặt các đơn hàng hoặc truy cập dữ liệu cá nhân của bạn, chúng tôi khuyên bạn nên sử dụng máy chủ bảo mật. Tất cả dữ liệu cá nhân hoặc thông tin nhạy cảm bạn cung cấp được mã hóa trong cơ sở dữ liệu của chúng tôi để chỉnđược truy cập như nêu trên.',
            '16.2. Trong nỗ lực cung cấp cho bạn giá trị cộng thêm, chúng tôi có thể chọn các trang web của bên thứ ba khác nhau để liên kết hoặc cài đặt trong phạm vi, Nền tảng. Chúng tôi cũng có thể tham gia mối quan hệ đồng thương hiệu hoặc mối quan hệ khác để giới thiệu thương mại điện tử và các dịch vụ và tính năng khác cho khách truy cập của chúng tôi. Các trang web được liên kết này sẽ có các chính sách bảo mật độc lập và riêng biệt cũng như các biện pháp an ninh. Kể cả bên thứ ba có liên kết với chúng tôi; chúng tôi không có quyền kiểm soát các trang liên kết này, mỗi trang web có các thông lệ thu thập dữ liệu và riêng tư riêng biệt, độc lập với chúng tôi. Thông tin được thu thập từ các đối tác đồng thương hiệu của chúng tôi hoặc các trang web của bên thứ ba (kể cả được cung cấp trên hoặc thông qua Nền tảng của chúng tôi) mà chúng tôi có thẻ không được nhận.',
            '16.3. Chúng tôi vì vậy không chịu trách nhiệm hoặc có nghĩa vụ đối với nội dung, biện pháp an ninh (hoặc các thiếu hụt đó) và các hoạt động của các trang liên kết này. Các trang liên kết này chỉ giúp cho sự thuận tiện của bạn và vì thế việc truy cập của bạn sẽ do bạn tự chịu rủi ro. Tuy nhiên, chúng tôi muốn bảo vệ tính minh bạch của Nền tảng của chúng tôi và các liên kết được đặt trên đó và vì vậy chúng tôi khuyến khích bất cứ phản hồi nào về các trang web liên kết này, (bao gồm, nhưng không giới hạn nếu một liên kết cụ thể không hoạt động).',
        ]
    },
    {
        title: 'Điều 17: Công ty sẽ chuyển dữ liệu của bạn ra nước ngoài?',
        content: [
            'Dữ liệu cá nhân và/hoặc thông tin của bạn có thể được chuyển tới, lưu trữ hoặc xử lý bên ngoài Việt Nam. Công ty sẽ chỉ chuyển thông tin ra nước ngoài theo Luật Riêng tư.',
        ]
    },
    {
        title: 'Điều 18: Làm thế nào bạn có thể hủy, bỏ, yêu cầu truy cập hoặc chỉnh sửa thông tin bạn cung cấp cho chúng tôi?',
        content: [
            '18.1. Hủy bỏ và Rút chấp thuận:',
            '(a) Để sửa đổi đăng ký qua email, xin vui lòng cho chúng tôi biết bằng cách gửi email tới Công ty tại địa chỉ được hiển thị bên dưới. Xin lưu ý rằng theo lịch trình quản lý email, bạn vẫn có thể nhận được email đã được tiến hành.',
            '(b) Bạn có thể rút lại chấp thuận của bạn đối với việc thu thập, sử dụng và/hoặc tiết lộ dữ liệu cá nhân của bạn mà chúng tôi sở hữu hoặc kiểm soát bằng cách gửi email tới Công ty qua địa chỉ email được trình bày dưới đây trong Điều 19.',
            '(c) Một khi chúng tôi nhận được một lệnh rút chấp thuận rõ ràng từ bạn và đã xác minh định danh của bạn, chúng tôi sẽ có xử lý yêu cầu rút chấp thuận của bạn, và sau đó chúng tôi sẽ không thu thập, sử dụng và/hoặc tiết lộ dữ liệu cá nhân của bạn theo phương thức được mô tả trong yêu cầu của bạn. Nếu chúng tôi không thể xác minh định danh của bạn hoặc không hiểu chỉ thị của bạn, chúng tôi sẽ liên lạc với bạn để hiểu được yêu cầu.',
            '(d) Tuy nhiên, việc rút lại chấp thuận có thể dẫn đến hậu quả pháp lý phát sinh từ việc rút lại như vậy. Về vấn đề này, tùy thuộc vào mức độ rút lại chấp thuận của bạn để chúng tôi xử lý dữ liệu cá nhân của bạn, điều này có nghĩa là chúng tôi sẽ không thể tiếp tục cung cấp các dịch vụ cho bạn, chúng tôi có thể cần chấm dứt mối quan hệ hiện tại và/hoặc hợp đồng với bạn, v…v.. tùy từng trường hợp, chúng tôi sẽ thông báo cho bạn biết.',
            '18.2. Yêu cầu truy cập và/hoặc sửa đổi dữ liệu cá nhân',
            '(a) Nếu bạn có một tài khoản với chúng tôi, bạn có thể tự truy cập và/hoặc chỉnh sửa dữ liệu cá nhân của bạn mà chúng tôi hiện sở hữu hoặc kiểm soát thông qua trang Cài đặt Tài khoản trên Ứng dụng. Nếu bạn không có tài khoản với chúng tôi, bạn có thể yêu cầu quyền truy cập và/hoặc chỉnh sửa dữ liệu cá nhân của bạn mà chúng tôi hiện sở hữu hoặc kiểm soát bằng cách gửi yêu cầu bằng văn bản cho chúng tôi. Chúng tôi sẽ cần đủ thông tin của bạn để xác định danh tính của bạn cũng như bản chất yêu cầu của bạn để có thể xử lý với yêu cầu của bạn. Vì vậy, hãy gửi yêu cầu bằng văn bản hoặc bằng cách gửi email tới Công ty.',
            '(b) Đối với yêu cầu truy cập dữ liệu cá nhân, một khi chúng tôi có đủ thông tin từ bạn để xử lý yêu cầu, chúng tôi sẽ cung cấp cho bạn dữ liệu cá nhân liên quan trong vòng 30 ngày. Nếu chúng tôi không thể phản hồi yêu cầu của bạn trong vòng 30 ngày, chúng tôi sẽ thông báo cho bạn thời gian sớm nhất mà chúng tôi có thể cung cấp cho bạn thông tin được yêu cầu. Lưu ý rằng Luật Riêng tư có thể loại trừ một số loại nhất định của dữ liệu cá nhân khỏi yêu cầu truy cập của bạn.',
            '(c) Đối với một yêu cầu sửa đổi dữ liệu cá nhân, một khi chúng tôi có đủ thông tin từ bạn để xử lý yêu cầu, chúng tôi sẽ:',
            '·         Sửa đổi dữ liệu cá nhân của bạn trong vòng 30 ngày. Nếu chúng tôi không thể giải quyết nó trong khoảng thời gian đã nêu, chúng tôi sẽ cho bạn biết về thời gian thực tế sớm nhất mà chúng tôi có thể chính sửa. Xin lưu ý rằng Luật Riêng tư có thể loại trừ một số loại thông tin nhất định của dữ liệu cá nhân khỏi yêu cầu sửa đổi cũng như quy định các tình huống mà việc sửa đổi là không cần thiết mặc dù bạn có yêu cầu;',
            '·         Chúng tôi sẽ gửi dữ liệu cá nhân được chỉnh sửa cho bất kỳ tổ chức nào khác mà dữ liệu cá nhân đã được chúng tôi tiết lộ trong vòng một năm trước ngày sửa đổi, trừ phi tổ chức đó không cần dữ liệu cá nhân sửa đổi cho mục đích pháp lý hoặc kinh doanh nào.',
            '(d) Chúng tôi có thể, nếu bạn yêu cầu, gửi dữ liệu cá nhân sửa đổi chỉ cho một số tổ chức cụ thể mà chúng tôi đã tiết lộ trong vòng một năm trước ngày sửa đổi.',
            '(e) Chúng tôi sẽ/có thể thu một khoản phí hợp lý để giải quyết và xử lý yêu cầu của bạn để truy cập dữ liệu cá nhân của bạn. Nếu chúng tôi chọn tính phí, chúng tôi sẽ cung cấp cho bạn một ước tính bằng văn bản về khoản phí mà chúng tôi sẽ tính. Xin lưu ý rằng, chúng tôi không cần phản hồi hoặc giải quyết yêu cầu truy cập của bạn trừ khi bạn đã đồng ý việc trả phí.',
            '(f) Chúng tôi bảo lưu quyền từ chối sửa đổi dữ liệu cá nhân của bạn theo các điều khoản của Luật Riêng tư, khi có yêu cầu và/hoặc cho phép một tổ chức từ chối sửa đổi dữ liệu cá nhân trong các trường hợp cụ thể.',
        ]
    },
    {
        title: 'Điều 19: Câu hỏi, thắc mắc hoặc khiếu nại? Liên hệ với chúng tôi?',
        content: [
            'Nếu bạn có bất cứ câu hỏi hay quan ngại về thông lệ bảo mật của chúng tôi hoặc việc xử lý với các dịch vụ; có khiếu nại hoặc than phiền về cách chúng tôi xử lý dữ liệu cá nhân của bạn, vui lòng sử dụng:',
            '(a) tính năng phản hồi trên ứng dụng, website;',
            '(b) thông qua hello@wee.vn;',
            '(c) qua số đường dây nóng 079 810 1010.',
            'Chúng tôi chắc chắn sẽ cố gắng để giải quyết bất kỳ khiếu nại hoặc than phiền nào càng sớm càng tốt.',
        ]
    }
]

export const popupType = {
    popupSendMail: 1
}