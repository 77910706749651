import React, { Component } from 'react';
import styled from 'styled-components';

import {DESKTOP} from '../../data/constant'

//Images
import wee_logo_white from '../../images/wee_logo_white.svg';
import MenuContainer from '../Menu/MenuContainer';
//data
import { menu, language } from '../../data/data';

const Bound = styled.div`
    display: flex;
    position: fixed;
    justify-content:center;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props=>props.height}rem;
    z-index: 9;
    background-color:rgba(255,255,255,0);
    .header-wrapper{
        display:flex;
        flex-direction:row;
        width: 80%;
        margin: 0 auto;
        max-width:1440px;
        /* left: 50%;
        transform:translate(-50%,0); */
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
    }
    .header__left{
        display:flex;
        align-items: center;
        img{
            opacity:1;
            width: 6.75rem;
            height: 2.75rem;
        }
        @media screen and (min-width: 577px) and (max-width: 992px){
            margin-left:2.5rem;
            img{
                width: 4.5rem;
                height: 1.875rem;
            }
        }
        @media screen and (max-width: 576px){
            margin-left:1.25rem;
            img{
                width: 4.125rem;
                height: 1.6875rem;
            }
        }
    }
    .header__right{
        display: flex;
        align-items: center;
        margin-right:1.25rem;
        p{
            font-size:0.75rem;
            color:#FFF;
            margin-right:1.25rem;
            transition: color .3s;
        }    
        .icon{
            position:relative;
            display:flex;
            z-index:10;
            width:1.375rem;
            height:1.375rem;
            cursor: pointer;
            .icon--bar{
                content: '';
                position: absolute;
                width: 1.3125rem;
                height:1.3125rem;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                .line-1, .line-2{
                    position: absolute;
                    width: 1.3125rem;
                    height: 0.09375rem;
                    border-radius:0.0625rem;
                    background-color:#FFF;
                    transition: transform .3s, background-color .3s;
                }
                .line-1{
                    top: 0.375rem;
                }
                .line-2{
                    top: 0.9375rem;
                }
            }
            &.active .icon--bar{
                .line-1{
                    background-color: #050c1d;
                    top:0.6875rem;
                    transform: rotate(45deg);
                }
                .line-2{
                    background-color: #050c1d;
                    top: 0.6875rem;
                    transform: rotate(135deg);
                }
            }
        }
        .active{
            top: 0;
        }
    }
    button{
        width: 8rem;
        height: 2.5rem;
        background: linear-gradient(84.5deg, #00CFFF -45.32%, #005BEC 169.02%);
        border-radius: 1.5rem;
        text-transform: uppercase;
        color: #ffffff;
        font-family: 'SVN-Gilroy';
        font-size: 0.8125rem;
        font-weight: bold;
        letter-spacing: 0.125rem;
        margin-right:2.5rem;
        /* position: absolute; */
        /* right: 2.5rem; */
        /* right:0; */
        /* top: 0.75rem; */
        /* top:50%;
        transform:translate(0,-50%); */
    }
    @media screen and (max-width: 576px) {
        /* width: 100%;
        height: 59px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        .header__left{
            flex: 1;
            padding-left: 1.25rem;
            img{
                width: 65.57px;
                height: 1.6875rem;
            }
        }
        .header__right{
            width: 100%;
            flex: 1;
            p{
                display: block;
                font-family: UTM-Avo;
                font-size: 0.75rem;
                line-height: 1;
                color: #ffffff;
                padding-left: 30px;
                flex: 2;
                text-align: right
            }
            .icon{
                display: block;
            }
        } */
    }
    @media screen and (min-width: 577px) and (max-width: 992px) {
        /* width: 100%;
        height: 59px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        .header__left{
            flex: 1;
            margin-left: 1.25rem;
            img{
                width: 65.57px;
                height: 1.6875rem;
            }
        }
        .header__right{
            width: 100%;
            flex: 1;
            p{
                display: block;
                font-family: UTM-Avo;
                font-size: 0.75rem;
                line-height: 1;
                color: #ffffff;
                padding-left: 30px;
                flex: 2;
                text-align: right;
            }
            .icon{
                display: block;
            }
        } */
    }
`

class Header extends Component {
    state={
        logo:wee_logo_white,
        device:this.props.device,
        currentPage:''
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device){
                this.setState({device})
            }
        }
    }
    onToggle = () => {
        let icon = document.getElementById('icon');
        let menu  = document.getElementById('menu');
        menu.classList.toggle('active');
        icon.classList.toggle('active');
    }
    changeHeaderStyle(headerStyle){
        let {device} = this.state
        if(headerStyle){
            let headerBound = document.getElementById('header__menu');
            if(headerBound){
                headerBound.style.backgroundColor='rgba(255,255,255,'+headerStyle.opacity+')'
                
            }
            let logoEle = document.getElementById('logo')
            if(logoEle){
                logoEle.style.opacity=headerStyle.logo.opacity
                logoEle.src= headerStyle.logo.image
            }
            let titleEle = document.getElementById('title')
            if(titleEle){
                titleEle.style.color=headerStyle.titleColor
            }
            let iconBarArr = document.getElementsByClassName('icon-line')
            if(iconBarArr&&iconBarArr.length>0){
                for(let i=0;i<iconBarArr.length;i++){
                    iconBarArr[i].style.backgroundColor=headerStyle.titleColor
                }
            }
            if(device&&device===DESKTOP){
                let menuArr = document.getElementsByClassName('menu-a')
                if(menuArr&&menuArr.length>0){
                    for(let i=0;i<menuArr.length;i++){
                        menuArr[i].style.color=headerStyle.titleColor
                    }
                }
                let lineActive = document.getElementById('menu-line-active')
                if(lineActive){
                    lineActive.style.backgroundColor=headerStyle.titleColor
                }
            }
        }
    }
    activeLink=(key)=>{
        let {device,currentPage} = this.state
        if(this.menuContainer){
            this.menuContainer.activeLink(key)
        }
        if(device!==DESKTOP){
            let {lang} = this.props
            for(let i = 0; i < menu.length; i++){
                if(menu[i].key===key){
                    if(menu[i].label){
                        currentPage=menu[i].label[lang].toUpperCase()
                    }
                    else{
                        currentPage=''
                    }
                    this.setState({currentPage})
                    break;
                }
            }
        }
    }
    handleSubmit =() =>{
       
    }
    render() {
        let {height, lang} = this.props
        let {logo, device, currentPage} = this.state
        return (
            <Bound id = 'header__menu' 
                height={height}>
                <div className='header-wrapper'>
                    <div className = 'header__left'>
                        <img src = {logo} alt = 'wee_logo' id = 'logo'/>
                    </div>
                    {
                        device!==DESKTOP&&
                        <div className = 'header__right'>
                            <p id = 'title'>{currentPage}</p>
                            <div className = 'icon' id='icon' onClick = {this.onToggle}>
                                <div className = 'icon--bar'>
                                    <div className='icon-line line-1'/>
                                    <div className='icon-line line-2'/>
                                </div>
                            </div>
                        </div>
                    }
                    <MenuContainer lang={lang} device={device} ref={ref => this.menuContainer = ref} 
                        menuData={menu}
                        languageData={language}/>
                    {/* {
                        device&&device===DESKTOP?
                        <button type="button" onClick={()=>{this.handleSubmit()}}>đăng nhập</button>
                        :
                        null
                    } */}
                </div>
                
                
            </Bound>
        );
    }
}

export default Header;