import React, { Component } from 'react'
import styled from 'styled-components';
import background_header from '../../images/background_header.svg';
import address from '../../images/address.svg';
import hotline from '../../images/hotline.svg';
import email from '../../images/email.svg';
import logo_wee from '../../images/wee_logo_white.svg'
// Component
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom'
import toastr from 'toastr';

const Bound = styled.div`
    overflow: auto;
    width: 100%;
    height: 100%;
    .header{
        background-color: #78c4f1;
        display:flex;
        justify-content:center;
        .header-wrapper{
            position: relative;
            width:80%;
            max-width:1440px;
            margin: 0 auto;
            .logo-wee{
                position: absolute;
                top: 5%;
                left: 0;
                z-index: 1;
                img{
                    height: 2.75rem;
                    width: 6.75rem;
                }
                @media screen and (min-width: 577px) and (max-width: 992px){
                    img{
                        height: 2rem;
                        width: auto;
                    }
                }
                @media screen and (max-width: 576px){
                    img{
                        height: 2rem;
                        width: auto;
                    }
                }
            }
            .popup-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 15rem;
                margin: 0 10%;
                position: relative;                
                .title-header{
                    font-family: 'SVN-Gilroy';
                    font-size: 3rem;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.14;
                    letter-spacing: normal;
                    color: #ffffff;
                    width: 24.6875rem;
                    & p:last-child{
                        font-family: 'UTM-Avo';
                        font-size: 0.9375rem;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        color: #ffffff;
                        text-align: justify;
                        width: 19.25rem;
                    }  
                }
                img{
                    width: 32.875rem;
                    height: 14.375rem;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                @media screen and (min-width: 577px) and (max-width: 992px){
                    margin: unset;
                    .title-header{
                        font-size: 2.125rem;
                        width: 23.625rem; 
                    }
                    img{
                        right: -25%;
                    }                
                }
                @media only screen and (max-width:576px){
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    height: 22.5rem;
                    margin: unset;
                    .title-header{
                        font-size: 2rem;
                        width: fit-content; 
                        padding-top: 15%;
                        & :first-child{
                            margin-top: 14%;
                            margin-bottom: 2%;
                        }
                    }
                    img{
                        width: 20rem;
                        height: auto;
                        bottom: 0%;
                        left: 50%;
                        transform: translate(-50%, 0%);
                    }
                }                 
            }            
        }  
    }
    .content{
        display: grid;
        grid-template-columns: repeat(2,1fr); 
        grid-column-gap: 6.25rem; 
        padding-top: 2rem;
        width:80%;
        max-width:1440px;
        margin:0 auto;
        .info-wrapper{
            width: 100%;
            .info-company{
                display: flex;
                flex-direction: column;
                margin-bottom:  1.875rem ;
                .title-content{
                    font-family: 'SVN-Gilroy';
                    font-size: 1rem;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.25;
                    letter-spacing: 0.03125rem;
                    color: #050c1d;
                    margin-bottom: 0.25rem;
                    img{
                        padding-right: 2.1875rem;
                    }
                }
                p{
                    font-family: 'UTM-Avo';
                    font-size: 0.8125rem;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    color: #050c1d;
                    padding-left: 3.125rem;
                }
                .input-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    input{
                        display: flex;
                        flex: 2;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        height: 2.8125rem;
                        border-radius: 0.625rem;
                        border: solid 0.03125rem #c9cdd6;
                        font-family: 'UTM-Avo';
                        font-size: 0.8125rem;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        margin: 0 1rem 0 0; 
                        ::placeholder{
                            color: ${props=>props.error? '#E43A3A':'rgba(0,0,0,0.25)'}
                        }                                       
                    }
                    span{
                        font-family: 'UTM-Avo';
                        font-size: 0.8125rem;
                        font-weight: normal;
                        color: ${props=>props.error.email? '#E43A3A':'transparent'};
                        position: absolute;
                        top: 37%;
                        right: 10%;
                        @media screen and (min-width: 577px) and (max-width: 992px){
                            top: 24%;
                        }  
                        @media screen and (max-width: 576px) {
                            top: 55%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 13.75rem;
                            text-align: center;
                        }                          
                    }    
                    .name{
                        flex: 1;
                    }
                    .email{
                        text-align: start;
                        padding-left: 1.25rem;
                        flex: 2;
                    }
                }
                .textarea-wrapper{
                    display: flex;
                    flex: 2;
                    height: 9.5rem;
                    textarea{
                        flex: 2;
                        height: 8.8125rem;
                        text-align: start;
                        padding: 0.625rem 0 0 1.25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;                    
                        border-radius: 0.625rem;
                        border: solid 0.03125rem #c9cdd6;
                        font-family: 'UTM-Avo';
                        font-size: 0.8125rem;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.6;
                        letter-spacing: normal;                        
                        margin: 0 1rem 1.5625rem 0; 
                        ::placeholder{
                            color: ${props=>props.error?'#E43A3A':'rgba(0,0,0,0.25)'}
                        }   
                    }
                }                
            }
            button{
                width: 12.5rem;
                height: 3.175rem;
                background-image: linear-gradient(84.5deg, #00CFFF -45.32%, #005BEC 169.02%);
                border-radius: 3.125rem;
                font-family: 'SVN-Gilroy';
                font-size: 1rem;
                font-weight: bold;
                color: #ffffff;
                text-decoration: none;
                cursor: pointer;
                @media screen and (min-width: 577px) and (max-width: 992px){
                    margin-bottom: 5%;
                }
            }       
        }
        
        @media screen and (min-width: 577px) and (max-width: 992px){
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0% 10.5%;
            width:unset;
            .info-wrapper{
                margin-top: 5%;
                .info-company{
                    margin-bottom: unset;
                    p{
                        margin-bottom: 1.25rem;;
                    }
                    .input-wrapper{
                        input{
                            margin: 0 1rem 1.5625rem 0;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width:576px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.875rem; 
            width:unset;
            .info-wrapper{
                .info-company{
                    margin-bottom: unset;
                    justify-content: center;
                    .title-content{
                        margin-bottom: 0.25rem;
                    }
                    p{
                        margin-bottom: 1.5625rem;
                    }
                    .input-wrapper{
                        input{
                            margin: 0 0.5rem 1.5625rem 0;
                        }
                    }
                    .textarea-wrapper{
                        textarea{
                            margin: 0 0.5rem 1.5625rem 0;
                        }
                    }
                }
                button{
                    margin-top: 10%;
                    width: 18.875rem;
                    height: 2.75rem;
                } 
            }             
        }
    }
    .footer-wrapper{ 
        position:relative;
        height: 4.375rem;
        /* margin: 0 auto;
        width: 80%;
        max-width: 1440px; */
        @media only screen and (max-width:576px){
            height: 14.625rem;
        }
    } 
`

export default class PopUpContact extends Component {
    state = {
        company: '',
        firstname: '',
        lastname: '',
        email: '',
        content: '',
        errors: false,
        formIsVald: false,
        device: this.props.device,
    }
    handleCancel() {
        this.setState({
            company: '',
            firstname: '',
            lastname: '',
            email: '',
            content: '',
            errors: false,
        });
    }
    handleChange = (e) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value})
        if (this.state.email !== "") {
            this.setState({ errors: {...this.state.errors, isEmtyEmail:false, isValid:false }})
        }
    }
    validateEmail = () => {
        let errors = {} 
        let formIsVald = true
        // let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)
        if (!this.state.company || this.state.company.length < 1) {
            errors.company = 'Nội dung bắt buộc'
            formIsVald = false
        };
        if (!this.state.firstname || this.state.firstname.length < 1) {
            errors.firstname = 'Nội dung bắt buộc'
            formIsVald = false
        };
        if (!this.state.lastname || this.state.lastname.length < 1) {
            errors.lastname = 'Nội dung bắt buộc'
            formIsVald = false
        };
        if (!this.state.content || this.state.content.length < 1) {
            errors.content = 'Nội dung bắt buộc'
            formIsVald = false
        };
        if (!this.state.email || this.state.email.length < 1 ) {
            errors.email = 'Nội dung Email bắt buộc'
            errors.isEmtyEmail = true;
            formIsVald = false
        };
        if (!pattern.test(this.state.email) ) {
            errors.email = 'Không đúng định dạng email'
            errors.isValid = true;
            formIsVald = false
        };
        this.setState({
            errors: {...errors, isEmtyEmail: errors.isEmtyEmail, isValid: errors.isValid }
        }, () => {
            console.log('errors', this.state.errors);
        })
        return formIsVald
    }
    handleSentEmail = (e) => {
        e.preventDefault()
        if (!this.validateEmail()){
            return
        }    
        const { company, firstname, lastname, email, content } = this.state
        var templateParams = {
            to_name: 'WEE_DIGITAL',
            message_html: content,
            representative_name: firstname + ' ' + lastname,
            from_name: company,
            reply_to: email,
        }
        window.emailjs.send(
            'gmail',
            'template_fJd1hGHL',
            templateParams,
            'user_ssva2y8HfbM0YDUb1uWHT',
        )
            .then(responce => {
                toastr.success('Email sent successfull')
                console.log('Email successfully sent!',responce)
            })
            .catch(err => {
                toastr.error('Email sent failed')
                console.error('Oh well, you failed. Here some thoughts on the error that occured:', err)
            })
        setTimeout(() => {
            this.props.showPopupContact()
            this.handleCancel()
        }, 1000);
    }
    render() {
        const { device } = this.state  
        return (
            <Bound error={this.state.errors}>
                <div className='header'>
                    <div className='header-wrapper'>
                        <div className='logo-wee'>
                            <Link to='/'><img src={logo_wee} alt='wee_logo' id='logo' /></Link>
                        </div>
                        <div className='popup-wrapper'>
                            <div className='title-header'>
                                <p>Kết nối cùng Wee</p>
                                <p>Wee chào đón các đối tác mới cùng nhau xây dựng tương lai đầy hứa hẹn của ngành tài chính ngân hàng</p>
                            </div>
                            <img src={background_header} alt='bg-header' />
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='info-wrapper'>
                        <div className='info-company'>
                            <span className='title-content'><img src={address} alt='address' />Địa chỉ</span>
                            <p>B20 Bạch Đằng, Phường 02, Quận Tân Bình,TP Hồ Chí Minh</p>
                        </div>
                        <div className='info-company'>
                            <span className='title-content'><img src={hotline} alt='hotline' />Hotline</span>
                            <p>079 810 1010</p>
                        </div>
                        <div className='info-company'>
                            <span className='title-content'><img src={email} alt='email' />Email</span>
                            <p>hello@wee.vn</p>
                        </div>
                    </div>
                    <div className='info-wrapper' >
                        <div className='info-company'>
                            <div className='title-content'>Quý công ty/tổ chức</div>
                            <div className='input-wrapper'>
                                <input onChange={(e) => { this.handleChange(e) }} name='company' type="text" value={this.state.company} 
                                    placeholder={
                                        this.state.errors && this.state.errors.company?
                                        this.state.errors.company
                                        :
                                        "Tên  công ty / tổ chức"
                                    }/>
                            </div>
                        </div>
                        <div className='info-company'>
                            <div className='title-content'>Người đại diện</div>
                            <div className='input-wrapper'>
                                <input onChange={(e) => { this.handleChange(e) }} name='firstname' className='name' type="text" value={this.state.firstname}
                                    placeholder={
                                        this.state.errors && this.state.errors.firstname?
                                        this.state.errors.firstname
                                        :
                                        "Họ và tên đệm"
                                    }/>
                                <input onChange={(e) => { this.handleChange(e) }} name='lastname' className='name' type="text" value={this.state.lastname}
                                    placeholder={
                                        this.state.errors && this.state.errors.lastname?
                                        this.state.errors.lastname
                                        :
                                        "Tên"
                                    }/>                                
                            </div>
                        </div>
                        <div className='info-company'>
                            <div className='title-content'>Địa chỉ email</div>
                            <div className='input-wrapper'>
                                <input onChange={(e) => { this.handleChange(e) }} name='email' className='email' type="text" value={this.state.email}
                                    placeholder={
                                        this.state.errors && this.state.errors.email ?
                                        this.state.errors.email 
                                        :
                                        "Ví dụ: example@email.com"
                                    }
                                    />
                                {
                                   this.state.errors && this.state.errors.email && this.state.errors.isValid && !this.state.errors.isEmtyEmail ?
                                   <span>{this.state.errors.email}</span>
                                   :
                                   null 
                                }                                
                            </div>
                        </div>
                        <div className='info-company'>
                            <div className='title-content'>Nội dung</div>
                            <div className='textarea-wrapper'>
                                <textarea onChange={(e) => { this.handleChange(e) }} name='content' type="text" value={this.state.content} 
                                    placeholder={
                                        this.state.errors && this.state.errors.content?
                                        this.state.errors.content
                                        :
                                        'Nhập nội dung ở đây ...'
                                    }/>
                            </div>
                        </div>
                        <div className='info-company'>
                            <button type='button' name='submit' onClick={(e) => {this.handleSentEmail(e)}}>GỬI</button>
                        </div>
                    </div>
                    
                </div>
                <div className='footer-wrapper'>
                    <Footer device={device} onChangeStep={this.props.onChangeStep} />
                </div>
            </Bound>
        )
    }
}
