import React, { Component } from 'react';
import styled from 'styled-components';

import {DESKTOP, MOBILE, TABLET} from '../../../data/constant'
// img
import pattern01 from '../../../images/pattern01.svg';
import pattern02 from '../../../images/pattern02.svg';
import ic_girl from '../../../images/ic_girl.png';
import ic_girl_tb from '../../../images/ic_girl_tb.png';
import ic_girl_mb from '../../../images/ic_girl_mb.png';
// import saving_final from '../../../media/saving_final.mp4'
// phone
import phone_tb from '../../../images/phone_tb.svg';
import phone_mb from '../../../images/phone_mb.svg';
import img_mobile from '../../../images/iphone.svg';

import sub_space_history_01 from '../../../images/sub_space_history_01.png';
import sub_space_history_02 from '../../../images/sub_space_history_02.png';
import sub_space_history_03 from '../../../images/sub_space_history_03.png';
import sub_space_history_04 from '../../../images/sub_space_history_04.png';

const Bound = styled.div`
    display:flex;
    align-items: center;
    position: relative;
    justify-content:center;
    @media screen and (max-width: 992px){
        align-items: unset;
    }
    .bg-left{
        position: absolute;
        left: 0;
        z-index: -1;
        @media screen and (max-width: 992px) {
            display:none;
        }
    }
    .bg-right{
        position: absolute;
        right: 0;
        z-index: -1;
        @media screen and (max-width: 992px) {
            display:none;
        }
    }
   
    .main-content{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width:1440px;
        @media screen and (max-width: 992px) {
            flex-direction:column;
            justify-content: space-between;
            align-items: flex-end;
        }
        .content{
            display:flex;
            flex-direction:column;
            flex:1;
            min-width: 23.4375rem;
            padding-left: 10%;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                flex:unset;
                width: auto;
                padding: 0 6.25rem;
            }
            @media screen and (max-width: 576px) {
                flex:unset;
                width: auto;
                padding: 0 2rem;
                min-width: unset;
            }
            .head{
                display:flex;
                flex-direction:column;
                h2{
                    background-image: linear-gradient(76.36deg, #00CFFF -45.32%, #005BEC 169.02%);;
                    font-family: 'SVN-Gilroy';
                    font-size: 2.5rem;
                    font-weight: bold;
                    line-height: 1.14;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    padding-bottom: 0.9375rem;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        background-image: linear-gradient(70.2deg, #00CFFF -45.32%, #005BEC 169.02%);
                        font-size: 2.125rem;
                        padding-bottom: 1.125rem;
                    }
                    @media screen and (max-width: 576px) {
                        background-image: linear-gradient(65.56deg, #00CFFF -45.32%, #005BEC 169.02%);
                        font-size: 2rem;
                        padding-bottom: 1.25rem;
                    }
                }
                p{
                    font-family: 'UTM-Avo';
                    font-size: 1rem;
                    line-height: 1.6;
                    color: #050c1d;
                    max-width:23.4375rem;
                    text-align: justify;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        font-size: 0.9375rem;
                        max-width:unset;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 0.875rem;
                        max-width:unset;
                        padding-bottom: 1.25rem;
                    }
                }
            }
            .block-detail{
                position: relative;
                display:flex;
                flex-direction:column;
                flex:1;
                margin-bottom: 4rem;
                margin-top:6.25rem;
                opacity:0;
                transition-property:opacity, margin-top;
                transition-duration:800ms;
                div.blocks{    
                    position: absolute;
                    height: 5rem;
                    object-fit: contain;
                    margin-bottom: 0.75rem;
                    opacity:0;
                    transition-property:bottom, opacity;
                    transition-duration:300ms;
                    left: 0;
                    bottom:0;
                    img{
                        box-shadow: 0rem 0.4375rem 4rem rgba(0, 0, 0, 0.07);
                    }
                }
            }
        }
        .diff-wrapper{
            display: flex;
            flex:2;
            flex-direction: row;
            justify-content: space-evenly;
            @media screen and (max-width: 992px){
                position:relative;
                justify-content: center;
                align-items:center;
                width:100%;
            }
        }
        .diff-phone-container{
            position:relative;
            display:flex;
            min-width:17.375rem;
            flex:1;
            @media screen and (max-width: 992px) {
                position:absolute;
                width:100%;
                height:100%;
            }
            .phone{
                position: absolute;
                left:200%;
                z-index:9;
                transition-property:left;
                transition-duration:800ms;
                img{
                    z-index:1;
                }
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    order: -1;
                    display: flex;
                    align-items: center;
                    left:100vw;
                    top:50%;
                    transform:translate(0,-50%);
                    img{
                        width:13.469375rem;
                    }
                }
                @media screen and (max-width: 576px) {
                    order: -1;
                    display: flex;
                    align-items: center;
                    top:50%;
                    transform:translate(0,-50%);
                    left:100vw;
                    img{
                        width:11.05125rem;
                    }
                }
                .video-phone{
                    width: 15.1875rem;
                    background-color: #ffffff;
                    position: absolute;
                    top: 4.375rem;
                    left: 1.125rem;
                    z-index:-1;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        width: 11.75rem;
                        top: 3.125rem;
                        left: 0.9375rem;
                    }
                    @media screen and (max-width: 576px) {
                        width: 10.125rem;
                        height: 17.9375rem;
                        top: 2.875rem;
                        left: 0.75rem;
                    }
                }
            }
        }
        .tranfer{
            display:flex;
            flex:1;
            justify-content: flex-start;
            transform: scale(0.85);
            @media screen and (min-width: 577px) and (max-width: 992px) {
                flex:unset;
                position:absolute;
                right:10%;
                transform: unset;
            }
            @media screen and (max-width: 576px) {
                flex:unset;
                position:absolute;
                right:-3.6875rem;
                transform: unset;
            }
            img{
                width: 21rem;
                height: 40.4375rem;
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    width:17rem;
                    height:32.75rem;
                }
                @media screen and (max-width: 576px) {
                    width: 14.5rem;
                    height:27.9375rem;
                } 
            }
        }
    }
    #saving_final{
        width: 100%;
        height: 100%;
    }
`

export default class Difference extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: '',
            ic_girl:'',
            movB:false,
            dataDiff:'',
            device:this.props.device
        }
    }
    componentDidMount() {
        this.refreshUI()
        this.handleLoadingAnimation(this.audio)
        this.handlePlayVideo()
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }

    refreshUI=()=>{
        let {device} = this.state
        switch (device) {
            case DESKTOP:{
                this.setState({
                    phone: img_mobile,
                    ic_girl:ic_girl
                })
                break;
            }
            case TABLET:{
                this.setState({
                    phone: phone_tb,
                    ic_girl:ic_girl
                })
                break;
            }
            case MOBILE:{
                this.setState({
                    phone: phone_mb,
                    ic_girl:ic_girl
                })
                break;
            }
            default:
                break;
        }
        const mainBound = document.getElementById('difference');
        const title = document.getElementById('diff-head-id')
        const mainContent = document.getElementById('diff-main-content-id');
        const mainWrapper = document.getElementById('diff-wrapper-id');
        setTimeout(() => {
            if(mainBound&&mainWrapper&&title){
                let height = mainBound.offsetHeight-title.offsetHeight
                if(device!==DESKTOP){
                    mainContent.style.height=mainBound.offsetHeight+'px'
                    mainWrapper.style.height=height+'px'
                }
            }
        }, 200);
    }

    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }

    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('difference');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    handlePlayVideo=()=>{
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('difference');
        const video = document.getElementById('saving_final');
        let lastScrollTop = 0;
        const checkScrollIn = this.checkScrollIn = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log('test',topPosition)
            if ((topPosition >= 0 && topPosition <= window.innerHeight/5) ||
                (topPosition + heightPosition >= window.innerHeight*4/5 && topPosition + heightPosition <= window.innerHeight)){
                video.play()
                rootEle.removeEventListener("scroll", checkScrollIn)
                rootEle.addEventListener("scroll", checkScrollOut)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollOut = this.checkScrollOut = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log(topPosition, window.innerHeight)
            if(topPosition + heightPosition < 0 ||
                topPosition > window.innerHeight){
                video.pause()
                video.currentTime= 0;
                rootEle.removeEventListener("scroll", checkScrollOut)
                rootEle.addEventListener("scroll", checkScrollIn)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollIn)
    }
    playMainAnimation=(direction)=>{
        let {device} = this.state
        let head = document.getElementById('diff-head-id')
        let phone = document.getElementById('diff-phone-id')
        let blockArr = document.getElementsByClassName('blocks')
        if(direction===1){
            if(head){
                head.style.marginTop=0;
                head.style.opacity=1;
            }
            if(phone){
                if(device===DESKTOP){
                    phone.style.left='0%';
                }
                else{
                    phone.style.left='10%';
                }
            }
            let timeOut=0
            for(let i = blockArr.length-1;i>=0;i--){
                timeOut=(blockArr.length-1-i)*300;
                let bottom = i*(blockArr[i].offsetHeight+12)
                this.playBlock(blockArr,i, bottom, 1, timeOut)
            }
        }
        else{
            if(head){
                head.style.marginTop='6.25rem';
                head.style.opacity=0;
            }
            if(phone){
                if(device===DESKTOP){
                    phone.style.left='200%';
                }
                else{
                    phone.style.left='100vw';
                }
            }
            let timeOut=0
            for(let i = 0; i<blockArr.length;i++){
                timeOut=i*300;
                this.playBlock(blockArr,i, 0, 0, timeOut)
            }
        }
    }

    playBlock = (blockArr, i, bottom, opacity, timeOut) => {
        setTimeout(() => {
            blockArr[i].style.bottom=bottom+'px';
            blockArr[i].style.opacity=opacity;
        }, timeOut);
    }

    render() {
        const { phone , ic_girl, device} = this.state
        return (
            <Bound id="difference" className='snap-child'>
                {
                    device&&device===DESKTOP&&
                    <div className='bg-left'>
                        <img src={pattern01} alt='patter01' />
                    </div>
                }
                {
                    device&&device===DESKTOP&&
                    <div className='bg-right'>
                        <img src={pattern02} alt='patter02' />
                    </div>
                }

                <div className='main-content' id= 'diff-main-content-id'>
                    <div className='content'>
                        <div className='head' ref = { ref => this.text = ref}>
                            <h2>Khác biệt</h2>
                            <p>
                                Cho dù bạn muốn đến Santorini hay là Bali, đừng chờ đợi, hãy bắt đầu tiết kiệm cùng với bạn bè ngay từ hôm nay - tính năng chỉ có ở Wee.
                            </p>
                        </div>
                        {
                            device&&device===DESKTOP&&
                            <div className='block-detail' id='diff-head-id'>
                                <div ref={ref=>this.elmBlock_4=ref} className = 'blocks'>
                                    <img src = {sub_space_history_04} alt ='sub_space_history_04'/>
                                </div>
                                <div ref={ref=>this.elmBlock_3=ref} className = 'blocks'>
                                    <img src = {sub_space_history_03} alt ='sub_space_history_03'/>
                                </div>
                                <div ref={ref=>this.elmBlock_2=ref} className = 'blocks'>
                                    <img src = {sub_space_history_02} alt ='sub_space_history_02'/>
                                </div>
                                <div ref={ref=>this.elmBlock_1=ref} className = 'blocks'>
                                    <img src = {sub_space_history_01} alt ='sub_space_history_01'/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='diff-wrapper' id= 'diff-wrapper-id'>
                        <div className='tranfer'>
                            <img src={ic_girl} alt='girl'/>
                        </div>
                        <div className='diff-phone-container'>
                            <div className='phone' id='diff-phone-id' ref = {ref => this.audio = ref}>
                                <img src={phone} alt='mobi' />
                                <div className='video-phone'>
                                    <video autoPlay={false} id='saving_final' loop muted playsInline >
                                        <source src='media/saving_final.mp4' type="video/mp4" />
                                    </video>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}
