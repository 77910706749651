import React, { Component } from 'react';
import styled from 'styled-components';

//data
import {DESKTOP, TABLET, MOBILE, ANDROID, IOS } from '../../../data/constant'
import {downloadApp} from '../../../tools'

//images
import bg_download_dk from '../../../images/bg_download_dk.svg';
import wee_logo_white from '../../../images/wee_logo_white.svg';
import wee_logo_white_tb from '../../../images/wee_logo_white_tb.svg';
import wee_logo_white_mb from '../../../images/wee_logo_white_mb.svg';

import bg_partners_tb from '../../../images/bg_partners_tb.svg';

import bg_partners_mb from '../../../images/bg_partners_mb.svg';

import img_phone_dk from '../../../images/img_phone_dk.svg';

import badge_google from '../../../images/google_Play_Badge.svg'
import badge_ios from '../../../images/app_Store_Badge_VN.svg'

//component
import Footer from '../../Footer/Footer';

const Bound = styled.div`
    position:relative;
    height: 100vh;
    @media screen and (max-width: 576px) {
        min-height: calc(158vh - 3.9375rem) !important;
    }
    .downloads__bg{
        width: 100%;
        height: calc(100% - 4.375rem);
        background-position: left top;
        background-size: cover;
        background-repeat:no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        left:0;
        bottom:4.375rem;
        overflow: hidden;
        img.downloads__phone{
            position: absolute;
            left: 50%;
            bottom: -20%;
        }
        @media screen and (min-width: 577px) and (max-width: 992px) {
            align-items: flex-start;
            background-position: unset !important;
            img.downloads__phone{
                transform: scale(0.75);
                position: absolute;
                left: -15.5rem;
                top: 22rem;
            }
        }
        @media screen and (max-width: 576px) {
            height: calc(100vh - 4.375rem);
            align-items: flex-start;
            background-position: unset !important;
            top:0;
            bottom:unset;
            overflow:unset;
            img.downloads__phone{
                z-index: 1;
                height: 22.28125rem;
                width: 38.6875rem;
                position: absolute;
                left: -20%;
                top: 70%;
            }
        }
    }
    .downloads{
        &__content{
            max-width: 1440px;
            margin: 15% auto 0;
            width: 80%;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                margin: 25% auto 0;
            }
            @media screen and (max-width: 576px) {
                margin: 30% auto 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            h2{
                width: 25rem;
                font-family: 'SVN-Gilroy';
                font-size: 3rem;
                line-height: 114%;
                color: #FFFFFF;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;
                img{
                    width: auto;
                    height: 2.65rem;
                    padding-left: 0.625rem;
                }
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    font-size: 2.125rem;
                    line-height: 125%;
                    padding-left: 6.25rem;
                    position: relative;
                    img{
                        width: auto;
                        height: 1.875rem;
                        padding-left: 0.5rem;
                    }
                }

                @media screen and (max-width: 576px) {
                    font-size: 2rem;
                    line-height: 114%;
                    position: relative;
                    text-align: center;
                    justify-content: center;
                    img{
                        width: auto ;
                        height: 1.8rem;
                        padding-left: 0.5rem;
                    }

                }
            }
            p{
                font-family: 'UTM-Avo';
                font-size: 0.9375rem;
                line-height: 160%;
                color: #FFFFFF;
                margin-top:1.5625rem;
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    font-size: 0.9375rem;
                    line-height: 165%;
                    padding-left: 6.25rem;
                }
                @media screen and (max-width: 576px) {
                    font-size: 0.875rem;
                    line-height: 165%;
                    padding:  0 3.04rem;
                    text-align: center;
                }
            }
           
        }
        &__contact {
                text-align: center;
                border-radius: 6.25rem;
                line-height: 3.125rem;
                width: 12.5rem;
                height: 3.125rem;
                background: #FFFFFF;
                margin: 2.4375rem auto 4.375rem auto;
                cursor: pointer;
                visibility: hidden;
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    margin: 1.875rem 0 0 6.25rem;
                    visibility: visible;
                }
                @media screen and (max-width: 576px) {
                    margin: 3.1875rem 3.75rem 3.55625rem 3.75rem;
                    visibility: visible;
                }
                span{
                    font-size: 1rem;
                    color: #01befc;
                    line-height: 125%;
                    font-weight: bold;
                    letter-spacing: 0.03125rem;
                    text-transform: uppercase;
                }
            }
    }
    .wrapper-badge{
        display: flex;
        flex-direction: row;
        justify-content: start;
        margin-top: 2.1875rem;
        .google, .ios{
            cursor: pointer;
        }
        .google{
            margin-right: 0.75rem;
        }
        @media screen and (min-width: 577px) and (max-width: 992px){
            padding-left: 6.25rem;
            .google{
                width: 9.716875rem;
                height: 2.75rem;
            }
            .ios{
                width: 138.11px;
                height: 2.75rem;
            }
        }
        @media screen and (max-width: 576px){
            justify-content: center;
            .google{
                width: 8.833125rem;
                height: 2.5rem;                
            }
            .ios{
                width: 7.8475rem;
                height: 2.5rem;
            }
        }
    }
`

class Download extends Component {
    isScrollUp=false
    state = {
        bg: '',
        logo: '',
        device: this.props.device
    }

    componentDidMount() {
        this.refreshUI()
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }

    refreshUI(){
        let {device} = this.state
        switch (device) {
            case DESKTOP:
                this.setState({
                    bg: bg_download_dk,
                    logo: wee_logo_white
                })
                break;
            case TABLET:
                this.setState({
                    bg: bg_partners_tb,
                    logo: wee_logo_white_tb
                })
                break;
            case MOBILE:
                this.setState({
                    bg: bg_partners_mb,
                    logo: wee_logo_white_mb
                })
                break;
            default:
                break;
        }
        // let title = document.getElementById('tech-title');
        // let phoneBound = document.getElementById('phone-id')
        // if(title&&phoneBound){
        //     setTimeout(() => {
        //         phoneBound.style.height='calc(100% - '+title.offsetHeight+'px - 30px)'
        //     }, 200);
        // }
    }

    handleRedirectScrollTo(scrollBound){
        let downloadsBound = document.getElementById('downloads')
        if(!this.isScrollUp){
            if(scrollBound.scrollTop+scrollBound.offsetHeight>=downloadsBound.offsetTop+100){
                this.isScrollUp=true
                downloadsBound.scrollIntoView()
            }
        }
        else{
            if(scrollBound.scrollTop+scrollBound.offsetHeight<downloadsBound.offsetTop){
                this.isScrollUp=false
            }
        }
    }
    downloadApp(type){
        downloadApp(type)
    }
    render() {
        const { bg, logo, device } = this.state
        return (
            <Bound 
                id='downloads' 
                // className='snap-child'
            >
                <div className = 'downloads__bg' style = {{backgroundImage: `url(${bg})`}}>
                    <img src = {img_phone_dk} alt = 'downloads__phone' className = 'downloads__phone'/>
                    <div className = 'downloads__content'>
                        <h2>Kết nối với<img src = {logo} alt = 'wee_logo_white'/></h2>
                        <h2>ngay hôm nay</h2>
                        <p>Ứng dụng Wee đang có sẵn trên nền tảng iOS và Android.</p>
                        <div className='wrapper-badge'>
                            <img className='google' src={badge_google} alt='badge-google' onClick={this.downloadApp.bind(this,ANDROID)}/>
                            <img className='ios' src={badge_ios} alt='badge_ios' onClick={this.downloadApp.bind(this,IOS)}/>
                        </div>
                    </div>
                </div>
                    <Footer device={device} onChangeStep={this.props.onChangeStep}/>
            </Bound>
        );
    }
}

export default Download;