import React, { Component } from 'react'
import styled from 'styled-components'
import { popupType } from '../../data/data';
import PopUpSentEmail from './PopUpSentEmail';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    background-color:${props => props.nonOverlay ? 'transparent' : 'rgba(34,34,34, 0.65)'} ;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
`

export default class PopupContainer extends Component {
    constructor(props) {
        super(props);
        this.isMouseEnterPopup = false
        this.state = {
            nonOverlay: false,
            content: null,
        }        
    }
    componentDidMount() {
        this.getPopupContent(this.props.popupRef);
    }
    getPopupContent(popupRef) {
        let content = null;
        switch (popupRef.type) {
            case popupType.popupSendMail:
                {
                    content = <PopUpSentEmail
                    onClosePopup= {this.props.onClosePopup}
                     />
                    break;
                }
            default:
                break;
        }
        this.setState({ content })
    }
    onClosePopup = () => {
        this.props.onClosePopup()
    }
    render() {
        return (
            <Bound nonOverlay = {this.state.nonOverlay}
                onMouseDown = { () => {
                    if (this.props.popupRef.type !== popupType.popupSendMail)
                    return;
                    if(!this.isMouseEnterPopup) {
                        this.onClosePopup()
                    }
                }}
            >
                <div
                    onMouseEnter={() => {
                        if (this.props.popupRef.type !== popupType.popupSendMail)
                        return;
                        return this.isMouseEnterPopup = true
                    }}
                    onMouseLeave={() => {
                        if (this.props.popupRef.type !== popupType.popupSendMail)
                        return;
                        return this.isMouseEnterPopup = false
                    }}
                    onMouseOver={() => {
                        if (this.props.popupRef.type !== popupType.popupSendMail)
                        return;
                        if (!this.isMouseEnterPopup)
                        this.isMouseEnterPopup = true
                    }}
                >
                    {this.state.content}
                </div>
            </Bound>
        )
    }
}
