import React, { Component } from 'react'
import styled  from 'styled-components'
// component
import ShowContent from './ShowContent'
import Footer from '../Footer/Footer'
import {Link} from 'react-router-dom'
//image
import ic_logo_wee from '../../images/ic_wee_logo_white.svg'
import image_privacyPolicy from '../../images/vuphong01.png'

const Bound = styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    .header{
        background: #FF7D34;
        display:flex;
        justify-content:center;
        .header-wrapper{
            position: relative;
            width:80%;
            margin: 0 auto;
            max-width:1440px;
            .logo-wee{
                position: absolute;
                top: 5%;
                left: 0%;
                z-index: 1;
                img{
                    height: 2.75rem;
                    width: 6.75rem;
                }
            }
            .content-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 10%;
                height: 25rem;
                position: relative;
                p{
                    display: flex;
                    align-items: center;
                    font-family: 'SVN-Gilroy';
                    font-size: 4.5rem;
                    font-weight: bold;
                    color: #ffffff;
                    width: 24rem;
                    height: 10.8125rem;
                }
                img{
                    height: 19.625rem;
                    width: 22.25rem;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
    .content-wrapper{
        display:flex;
        flex:1;
        flex-direction:column;
        text-align: justify;
        width:80%;
        max-width:1440px;
        margin:0 auto;
        padding-bottom: 2rem;
        .title-realease{
            font-family: 'SVN-Gilroy';
            font-size: 1.5rem;
            font-weight: bold;
            color: #050C1D;
            padding: 3.75rem 0 0.3125rem 0;
        }
        .time-realease{
            font-family: 'UTM-Avo';
            font-size: 0.9375rem;
            line-height: 160%;
            color: rgba(5,12,29,0.5)
        }
    }
    .footer-wrapper{         
        height: 4.375rem;
        position:relative;
    }
    @media screen and (min-width: 577px) and (max-width: 992px){
        .header{
            .header-wrapper{
                .content-header{
                    margin: unset;
                    p{
                        width: 22rem;
                        font-size: 4rem;
                        line-height: 120%;
                    }
                    img{
                        height: 14.65rem;
                        width: auto;
                    }
                }
            }
        }
        .content-wrapper{
            .title-realease{
                font-size: 0.9375rem;
                line-height: 120%;
                padding: 2.8125rem 0 0.3125rem 0;
            }
            .time-realease{
                font-size: 0.9375rem;
                line-height: 160%;
            }
        }    
    }
    @media screen and (max-width: 576px){
        .header{
            .header-wrapper{
                .logo-wee{
                    img{
                        height: 1.6875rem;
                        width: 4.125rem;
                    }
                }
                .content-header{
                    height: 12.5rem;
                    margin: unset;
                    p{
                        padding-top: 5%;
                        width: 11rem;
                        font-size: 2rem;
                        line-height: 114%;
                    }
                    img{
                        width: auto;
                        height: 8.125rem;
                        bottom: 0;
                        right: 0rem;
                    }
                }
            }
        }
        .content-wrapper{
            .title-realease{
                font-size: 1rem;
                line-height: 125%;
                padding: 1.8125rem 0 0.3125rem 0;
            }
            .time-realease{
                font-size: 0.875rem;
                line-height: 165%;
            }
        }
        .footer-wrapper{ 
            height: 15rem;
        }
    }
`
export default class PrivacyPolicy extends Component {
    state ={
        device: this.props.device
    }
    render() {
        const { device } = this.state
        return (
            <Bound>
                <div className='header'>
                    <div className='header-wrapper'>
                        <div className='logo-wee'>
                            <Link to={'/'}>
                                <img src = {ic_logo_wee} alt ='image_logo_wee' id = 'logo'/>
                            </Link>
                        </div>
                        <div className='content-header'>
                            <p>Chính sách bảo mật</p>
                            <img src={image_privacyPolicy} alt='image_privacyPolicy' />
                        </div>
                    </div>
                </div>
                <div className='content-wrapper'>
                    <h3 className='title-realease'>Realease Patch Note v1.32</h3>
                    <h4 className='time-realease'>27/08/2019</h4>
                    <ShowContent type = "privacyPolicy"/>
                </div>
                <div className='footer-wrapper'>
                    <Footer device={device} onChangeStep={this.props.onChangeStep}/>
                </div>
            </Bound>
        )
    }
}
