import React, { Component } from 'react';
import styled from 'styled-components';
import { Route } from "react-router-dom";
import {Switch} from 'react-router';
//data
import {DESKTOP, TABLET, MOBILE} from '../data/constant'
//Component
import FeaturesSimPleEasyFast from './Pages/Features/FeaturesSimPleEasyFast';
import FeaturesConvenient from './Pages/Features/FeaturesConvenient';
import FeaturesAndMore from './Pages/Features/FeaturesAndMore';
import Header from './Header/Header';
import Introduction from './Pages/Introduction/Introduction';
import Technology from './Pages/Technology/Technology';
import Partners from './Pages/Partners/Partners';
import QAnswer from './Pages/Q&A/Q&A';
import Difference from './Pages/Features/Difference'
import PopupContact from './PopUpContact/PopUpContact'
import PopUpSentEmail from './PopUpContact/PopUpSentEmail'
//Image
import wee_logo_color from '../images/wee_logo_color.svg';
import wee_logo_white from '../images/wee_logo_white.svg';
import ic_wee_arrow_up from '../images/ic_wee_arrow_up.svg'

import Download from './Pages/Downloads/Download';
import Community from './Pages/Community/Community';
import TermOfUse from './PopUpContact/TermOfUse';
import PrivacyPolicy from './PopUpContact/PrivacyPolicy';
import { popupType } from '../data/data';
import PopupContainer from './PopUpContact/PopupContainer';

//constant
const heightProccessBar = 0.1875;
const heightHeader = 3.75;

const Bound = styled.div`
    display:flex;
    width:100%;
    height:100%;
    position:relative;
    top:0;
    left:0;
    z-index:0;
    .proccess-bar{
        display:flex;
        position:fixed;
        top: ${heightHeader}rem;
        left:0;
        z-index:2;
        height:${heightProccessBar}rem;
        width:100%;
        opacity:0.25;
        /* background: #FFF; 
        box-shadow: inset 0rem 0.0625rem 0rem rgba(255, 255, 255, 0.25); */
        .bar{
            width:0%;
            height:100%;
            background:linear-gradient(90deg, #00CFFF 0%, #005CEC 100%, #005BEC 100%);
        }
    }
    .main-bound{
        display:flex;
        width:100%;
        height:100vh;
        position:absolute;
        top:0;
        left:0;
        z-index:1;
        /* scroll-snap-type:y mandatory; */
        overflow-x:hidden;
        overflow-y:scroll;
        scroll-padding:calc(${heightHeader}rem + ${heightProccessBar}rem);
        .scroll-container{
            display:flex;
            flex-direction:column;
            width:100%;
            height:fit-content;
            .snap-child{
                scroll-snap-align:start;
                min-height:calc(100vh - ${heightHeader}rem - ${heightProccessBar}rem);
                height:fit-content;
                :first-child{
                    min-height:100vh;
                }
                /* :nth-child(odd){
                    background-color:blue;
                }
                :nth-child(even){
                    background-color:red;
                } */
            }
        }
        
        /* table */
        @media screen and (max-width: 992px) {
            scroll-snap-type:unset;
        }
    }
    #move-to-top{
        position:absolute;
        z-index:5;
        display:flex;
        align-items:center;
        justify-content:center;
        width:3.4375rem;
        height:3.4375rem;
        border-radius:50%;
        right:-100%;
        bottom:6.25rem;
        transition:right 0.5s;
        background: #FFFFFF;
        box-shadow: 0rem 0.25rem 2rem rgba(0, 0, 0, 0.1);
        cursor:pointer;
        background-image:url(${ic_wee_arrow_up});
        background-position:center center;
        background-repeat: no-repeat;
        background-size:1rem 1rem;
    }
    /* --------------- tablet --------------- */
    /*@media screen and (min-width: 576px) and (max-width: 992px) {}*/
    /* --------------- mobile --------------- */
    /*@media screen and (max-width: 576px) {}*/
`

export default class Main extends Component {
    headerEle=null;
    pushState='';
    constructor(props) {
        super(props);
        this.state={
            device:DESKTOP,
            language:'vn',
            isShowMoveTop:false,
            step:0,
            isShowPopup: false,
        }
        this.popupObj = {
            type: -1,
            data: {}
        }
    }
    
    componentDidMount() {
        this.getResolution()
        window.addEventListener('resize', this.getResolution)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getResolution)
    }

    getResolution=()=>{
        let width = window.innerWidth
        if(width >= 992){
            this.setState({
                device:DESKTOP
            });
        }if( width > 576 && width < 992){
            this.setState({
                device:TABLET
            });
        }else if(width <= 576){
            this.setState({
                device:MOBILE
            });
        }
    }
    
    onScroll = (e) => {
        let element = e.target
        let {isShowMoveTop} = this.state
        this.handlePagePosition(element)
        //move top button
        if(element.scrollTop >= 300){
            if(!isShowMoveTop){
                this.setState({isShowMoveTop:true})
            }
        }
        else{
            if(isShowMoveTop){
                this.setState({isShowMoveTop:false})
            }
        }
        // header
        let headerStyle={
            height:heightHeader,
            opacity:0,
            titleColor:'#fff',
            logo:{
                image:wee_logo_white,
                opacity:1
            }
        }
        let opacityProccessBar=0.25
        let scrollTop=element.scrollTop;
        // console.log(scrollTop)
        let delta = heightHeader+15;

        if(scrollTop>delta){
            scrollTop=delta;
        }
        let opacityHeader = scrollTop/delta;
        let opacityLogo = 0;
        if(opacityHeader<=0.5){
            opacityLogo = 1-(opacityHeader*0.7/0.5)
            headerStyle.logo.image=wee_logo_white
            headerStyle.titleColor='rgba(255,255,255,'+opacityLogo+')'
            
        }
        else{
            let deltaOpacity = opacityHeader-0.5
            opacityLogo = (deltaOpacity*0.7/0.5)+0.3
            headerStyle.logo.image=wee_logo_color
            headerStyle.titleColor='rgb(5,12,29,'+opacityLogo+')'
        }
        if(opacityHeader>=0.25){
            opacityProccessBar=opacityHeader
        }
        else{
            opacityProccessBar=0.25
        }
        headerStyle.logo.opacity=opacityLogo
        headerStyle.opacity=opacityHeader
        if(this.headerEle){
            this.headerEle.changeHeaderStyle(headerStyle)
        }
        //proccess bar
        let maxProccess=element.scrollHeight-element.offsetHeight;
        let proccessPercent = element.scrollTop * 100 / maxProccess;
        let proccessBar = document.getElementById('proccess-bar-id')
        if(proccessBar){
            proccessBar.style.opacity=opacityProccessBar
        }
        let bar = document.getElementById('bar-id')
        if(bar){
            bar.style.width=proccessPercent+'%'
        }
    }

    handlePagePosition=(scrollBound)=>{
        let introductionBound = document.getElementById('introduction')
        let featuresBound = document.getElementById('features')
        let technologyBound = document.getElementById('technology')
        let partnersBound = document.getElementById('partners')
        let qaBound = document.getElementById('q&a')
        let downloadsBound = document.getElementById('downloads')
        // console.log(scrollBound.scrollTop)
        // home
        if(scrollBound.scrollTop===0){
            if(this.pushState!=='home'){
                // window.history.pushState({}, "home", "/")
                this.pushState='/'
            }
        }
        // introduction
        if(scrollBound.scrollTop>=introductionBound.offsetTop-63&&
            scrollBound.scrollTop<featuresBound.offsetTop){
            if(this.pushState!=='introduction'){
                // window.history.pushState({}, "introduction", "introduction")
                this.pushState='introduction'
            }
        }
        // features
        if(scrollBound.scrollTop>=featuresBound.offsetTop-63&&
            scrollBound.scrollTop<technologyBound.offsetTop){
            if(this.pushState!=='features'){
                // window.history.pushState({}, "features", "features")
                this.pushState='features'
            }
        }
        // technology
        // console.log('technology ', technologyBound.offsetTop-63)
        if(scrollBound.scrollTop>=technologyBound.offsetTop-63&&
            scrollBound.scrollTop<partnersBound.offsetTop){
            if(this.pushState!=='technology'){
                // window.history.pushState({}, "technology", "technology")
                this.pushState='technology'
            }
        }
        // partners
        if(scrollBound.scrollTop>=partnersBound.offsetTop-63&&
            scrollBound.scrollTop<qaBound.offsetTop){
            if(this.pushState!=='partners'){
                // window.history.pushState({}, "partners", "partners")
                this.pushState='partners'
            }
        }
        // Q&A
        if(scrollBound.scrollTop>=qaBound.offsetTop-63&&
            scrollBound.scrollTop<downloadsBound.offsetTop){
            if(this.pushState!=='q&a'){
                // window.history.pushState({}, "q&a", "q&a")
                this.pushState='q&a'
            }
        }
        // downloads
        if(scrollBound.scrollTop>=downloadsBound.offsetTop-63&&
            scrollBound.scrollTop<downloadsBound.offsetTop+downloadsBound.offsetHeight){
            if(this.pushState!=='downloads'){
                // window.history.pushState({}, "downloads", "downloads")
                this.pushState='downloads'
            }
        }
        window.clearTimeout( this.isScrolling );
        this.isScrolling = setTimeout(() => {
            // console.log( 'Scrolling has stopped.' );
            let pathname=window.location.pathname;
            if(pathname&&pathname.length>2){
                pathname=pathname.slice(1,pathname.length)
                // console.log(pathname, this.pushState)
            }
            if(pathname!==this.pushState){
                window.history.pushState({}, this.pushState, this.pushState)
            }
            if(this.headerEle){
                this.headerEle.activeLink(this.pushState)
            }
        }, 66);
    }
    showPopup = (type,data) =>{
        this.popupObj.type = type;
        this.popupObj.data = data;
        this.setState({
            isShowPopup: true
        })
    }
    onClosePopup = () =>{
        this.popupObj = {};
        this.setState({
            isShowPopup: false
        })
    }
    render() {
        let {language, device, isShowMoveTop, step} = this.state
        return (
            <Bound>
                <Switch>
                    <Route exact path='/contact' render={()=>
                        <PopupContact ref={ref => this.popupcontact = ref} device={device}
                        showPopupContact = {()=>{
                            this.showPopup(popupType.popupSendMail, {})
                        }}
                        onChangeStep={(step)=>{this.setState({step})}}/>
                    }/>
                    <Route exact path='/term-of-use' render={()=>
                        <TermOfUse ref={ref => this.termofuse = ref} device={device}
                        onChangeStep={(step)=>{this.setState({step})}}/>
                    }/>
                    <Route exact path='/privacy-policy' render={()=>
                        <PrivacyPolicy ref={ref => this.privacypolicy = ref} device={device}
                        onChangeStep={(step)=>{this.setState({step})}}/>
                    }/>
                    <Route render={()=>
                        <React.Fragment>
                            <Header height={heightHeader} ref={div=>this.headerEle=div} lang={language} device={device}/>
                            <div className='proccess-bar' id='proccess-bar-id'>
                                <div className='bar' id='bar-id'></div>
                            </div>
                            <div className='main-bound' id='scroll-bound-id'
                                onScroll = {this.onScroll}>
                                <div className='scroll-container'>
                                    <Introduction device={device}/>
                                    <Community ref={ref => this.community = ref} device={device}/>
                                    <FeaturesSimPleEasyFast ref={ref => this.featuresSimPleEasyFast = ref} device={device}/>
                                    <FeaturesConvenient ref={ref => this.featuresConvenient = ref} device={device}/>
                                    <Difference ref={ref => this.difference = ref} device={device}/>
                                    <FeaturesAndMore ref={ref => this.featuresAndMore = ref} device={device}/>
                                    <Technology ref={ref => this.technology = ref} device={device}/>
                                    <Partners ref={ref => this.partners = ref} device={device}/>
                                    <QAnswer ref={ref => this.qAnswer = ref} device={device}/>
                                    <Download ref={ref => this.download = ref} device={device}
                                        onChangeStep={(step)=>{this.setState({step})}}/>
                                </div>
                            </div> 
                            <div id='move-to-top' style={{right:isShowMoveTop?'1.875rem':'-100%'}}
                                onClick={()=>{
                                    let scrollBound = document.getElementById('scroll-bound-id')
                                    if(scrollBound){
                                        scrollBound.scrollTo(0, 0);
                                    }
                                }}>
                            </div>  
                        </React.Fragment>
                    }/>
                </Switch>
                {
                    this.state.isShowPopup && (
                        <PopupContainer
                            popupRef={this.popupObj}
                            onClosePopup = {()=>this.onClosePopup()}
                        />
                    )
                }
            </Bound>
        )
    }
}
