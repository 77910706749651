import React, { Component } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie';

// icon
import ic_facebook from '../../../images/ic_facebook.svg'
import ic_insta from '../../../images/ic_insta.svg'
import ic_youtube from '../../../images/ic_youtube.svg'
import badge_google from '../../../images/google_Play_Badge.svg'
import badge_ios from '../../../images/app_Store_Badge_VN.svg'

//data
import { DESKTOP, ANDROID, IOS, MOBILE } from '../../../data/constant';
import {hostFacebook, hostInstagram, hostYoutube} from '../../../data/host'
import {downloadApp} from '../../../tools'

//json
import jsonVoice from '../../../json/voice_button.json'

//control
import MouseScrollControl from '../../Control/MouseScrollControl'

const Bound = styled.div`
    display:flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    position: relative;
    .content-center{
        display: flex;
        flex: 1;
        width:100%;
        max-width:1440px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            padding: 0 3.625rem;
        }
        @media screen and (max-width: 576px) {
            padding: 0 0.9375rem;
        }
        .title{
            display: flex;
            flex-direction: column;
            justify-content:center;
            font-family: 'SVN-Gilroy';
            font-size: 4.5rem;
            font-weight: bold;
            line-height: 1.25;
            letter-spacing: 0.0625rem;
            color: #ffffff;
            height: 20rem;
            animation: tracking-in-expand-fwd 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.5s both;   
            @keyframes tracking-in-expand-fwd {
                0% {
                    letter-spacing: -0.5em;
                    transform: translateZ(-43.75rem);
                    opacity: 0;
                }
                40% {
                    opacity: 0.6;
                }
                100% {
                    transform: translateZ(0);
                    opacity: 1;
                }
            }
            p{
                text-align:center;  
            }
            @media screen and (min-width: 577px) and (max-width: 992px) {
                text-align:center;
                margin-bottom: 6.25rem;
                font-size: 4rem;
                -webkit-animation: unset;
                        animation: unset;  
                .line{
                    color:#00B1FA;
                    padding: 0 8%;
                }
                p{
                    padding: 0 6%;
                }
            }
            @media screen and (max-width: 576px) {
                font-size: 2.75rem;
                margin-bottom: 5rem;
                text-align:center;
                -webkit-animation: unset;
                        animation: unset;  
                .line{
                    color:#00B1FA;
                }
                p{
                    padding: 0 6%;
                }
            }
        }  
    }
    .btn{
        width: 12.5rem;
        height: 3.125rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.875rem;
        cursor: pointer;
        color: #1a6282;
        font-weight: bold;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            background-image: linear-gradient(to top, #00cfff, #0065ff, #005bec);
            color: #ffffff;
        }
        @media screen and (max-width: 576px) {
            background-image: linear-gradient(to top, #00cfff, #0065ff, #005bec);
            color: #ffffff;
        }
    }
    .wrapper-badge{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .google,.ios{
            cursor: pointer;
        }
        .google{
            margin-right: 2.5rem;
        }
        @media screen and (min-width: 577px) and (max-width: 992px){
            .google{
                width: 13.25rem;
                height: 3.75rem;
                margin-right: 1.25rem;
            }
            .ios{
                width: 11.770625rem;
                height: 3.75rem;
            }
        }
        @media screen and (max-width: 576px){
            .google{
                width: 8.833125rem;
                height: 2.5rem;
                margin-right: 1.25rem;
            }
            .ios{
                width: 7.8475rem;
                height: 2.5rem;
            }
        }
    }
    .sidebar-bottom{
        width:100%;
        max-width:1440px;
        display: flex;
        height: 5rem;
        flex-direction:row;
        justify-content:space-between;
        .left{
            display: flex;
            flex: 1;
            align-items: center;
            padding-left: 2.25rem;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                padding-left: 2.5rem;
            }
            .voice_off{
                margin-right: 0.75rem;
            }
            @media screen and (max-width: 576px) {
                padding:0 0 0 1.875rem;
                justify-content: space-between;
            }
        }
        .center{
            display:flex;
            width:1.625rem;
            align-items: center;
            justify-content: center;
        }
        .right{
            padding-right: 2.25rem;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                padding-right: 2.5rem;
            }
            @media screen and (max-width: 576px) {
                padding-right: 1.875rem;
            }
            .group-icon{
                width: 6.25rem;
                display: flex;
                justify-content: space-between;
                a{
                    cursor: pointer;
                }
            }
            .active{
                font-weight: bold;
            }
            .language{
                display: flex;
                width: 3.5rem;
                justify-content: space-between;
                margin-right: 2.5rem;
                font-family: 'SVN-Gilroy';
                font-size: 0.8125rem;
                letter-spacing: 0.125rem;
                text-transform: uppercase;
                color: #ffffff;
                .vn{
                    font-weight: bold;
                }
                .en{
                    opacity: 0.5;
                } 
            }
        }
    }
    #Video{
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit:cover;
    }
    .home-main-bound{
        display: flex;
        flex-direction: column;
        align-items:center;
        flex: 1;
        position: absolute;
        top: 0;
        left:0;
        z-index:1;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
    }

`

export default class Introduction extends Component {
    constructor(props){
        super(props)
        this.soundButtonOption = {
            animationData: jsonVoice,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
                className:'voice'
            }
        };
        this.state = {
            video:null,
            muted: false,
            device:this.props.device
        }
    }

    componentDidMount(){
        this.refreshUI()
        this.handlePlayVideo()
    }

    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    refreshUI(){
        let {device} = this.state
        switch (device) {
            case DESKTOP:
                this.setState({
                    video:'media/video_background_desktop.mp4'
                })
                break;
        
            default:
                this.setState({
                    video:'media/video_background_mobile.mp4'
                })
                break;
        }
    }
    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }
    handlePlayVideo=()=>{
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('home');
        const video = document.getElementById('Video');
        let lastScrollTop = 0;
        const checkScrollIn = this.checkScrollIn = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log('test',topPosition)
            if ((topPosition >= 0 && topPosition <= window.innerHeight/5) ||
                (topPosition + heightPosition >= window.innerHeight*4/5 && topPosition + heightPosition <= window.innerHeight)){
                video.play()
                rootEle.removeEventListener("scroll", checkScrollIn)
                rootEle.addEventListener("scroll", checkScrollOut)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollOut = this.checkScrollOut = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log(topPosition, window.innerHeight)
            if(topPosition + heightPosition < 0 ||
                topPosition > window.innerHeight){
                video.pause()
                video.currentTime= 0;
                rootEle.removeEventListener("scroll", checkScrollOut)
                rootEle.addEventListener("scroll", checkScrollIn)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollIn)
    }
    onChangSound = () => {
        this.setState({
            muted:!this.state.muted
        })
    }
    downloadApp(type){
        downloadApp(type)
    }

    render() {
        const {video, muted, device} = this.state
        return (
            <Bound 
                id='home' 
                className='snap-child'>
                <video autoPlay={true} loop id='Video' muted playsInline>
                    <source src={video} type="video/mp4" id= 'audio' />
                </video>
                <div className='home-main-bound'>
                    <div className='content-center'>
                        <div className='title'>
                            <p>Ứng dụng công nghệ số,</p>
                            <p className='line'>kết nối ngân hàng số.</p>
                        </div>
                        <div className='wrapper-badge'>
                            <img className='google' src={badge_google} alt='badge-google' onClick={this.downloadApp.bind(this,ANDROID)}/>
                            <img className='ios' src={badge_ios} alt='badge_ios' onClick={this.downloadApp.bind(this,IOS)}/>
                        </div>
                    </div>
                    <div className='sidebar-bottom'>
                        <div className='left'>
                            {/* <div className='voice_off'>
                                <img src={ic_voice_off} alt="voice-off" />
                            </div> */}
                            
                            {/* <div className='sound-off' onClick={this.onChangSound}>
                                <img src={ic_sound_off} alt='sound-off' />
                            </div> */}
                            <Lottie isClickToPauseDisabled={true}
                                options={this.soundButtonOption}
                                margin='0 0 0 0'
                                height={16}
                                width={22}
                                style={{margin:0}}/>
                        </div>
                        <div className='center'>
                            <MouseScrollControl/>
                        </div>
                        <div className='right'>
                            {
                                device&&device!==MOBILE&&
                                <React.Fragment>
                                    <div className='language'>
                                        <span className='vn'>VN</span>
                                        <span className='en'>EN</span>
                                    </div>
                                    <div className='group-icon'>
                                        <a href = {hostFacebook} target="_blank"> 
                                            <img src={ic_facebook} alt='facebook' />
                                        </a>
                                        <a href = {hostInstagram} target="_blank">
                                            <img src={ic_insta} alt='insta' />
                                        </a>
                                        <a href = {hostYoutube} target="_blank">
                                            <img src={ic_youtube} alt='youtube' />
                                        </a>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}
