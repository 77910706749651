import React, { Component } from 'react'
import styled from 'styled-components'
//Image
import img_03 from '../../images/info_img_03.png'
import img_04 from '../../images/info_img_04.png'
import img_05 from '../../images/info_img_05.png'
import ic_close from '../../images/ic_menu_black.svg'
import ic_accept from '../../images/ic_accept.png'
const Bound = styled.div`
    width: 50.875rem;
    height: 31.9375rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 0.625rem;
    background-color: #FFFFFF;
    @media screen and (min-width: 577px) and (max-width: 992px){
        width: 43rem;
        height: 31.1875rem;   
    }
    @media screen and (max-width: 576px){
        width: 17.8125rem;
        height: 39.1875rem  ;
        flex-direction: column;
        overflow: hidden;
    }
    .icon-image{
        img{
            &:first-child{
                position: absolute;
                top: 4.125rem;
                left: 0;
            }
            &:nth-child(2){
                position: absolute;
                top: 9.0625rem;
                left: 11.875rem;
            }
            &:nth-child(3){
                position: absolute;
                top: 19rem;
                left: 4.375rem;
            } 
            &:nth-child(4){
                position: absolute;
                top: 0.625rem;
                right: 0.625rem;
                cursor: pointer;
            } 
        }
        @media screen and (min-width: 577px) and (max-width: 992px){
            img{
                &:first-child{
                    width: 10.1875rem;
                    height: 13.3125rem;
                    position: absolute;
                    top: 2.75rem;
                    left: 0;
                }
                &:nth-child(2){
                    width: 10.3125rem;
                    height: 10.3125rem;
                    position: absolute;
                    top: 7.8125rem;
                    left: 10.625rem;
                }
                &:nth-child(3){
                    width: 9.375rem;
                    height: 9.375rem;
                    position: absolute;
                    top: 17.5rem;
                    left: 3.5rem;
                } 
            }
        } 
        @media screen and (max-width: 576px){
            img{
                &:first-child{
                    width: 7.75rem;
                    height: 10.4375rem;
                    position: absolute;
                    top: -0.9375rem;
                    left: 0;
                }
                &:nth-child(2){
                    width: 8.625rem;
                    height: 8.625rem;
                    position: absolute;
                    top: 4.5rem;
                    left: unset;
                    right: -2rem;
                }
                &:nth-child(3){
                    width: 7.4375rem;
                    height: 7.4375rem;
                    position: absolute;
                    top: unset;
                    left: unset;
                    bottom: -0.9375rem;
                    right: 3.5rem;
                } 
            }
        }
    }
    .sent-email{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            margin-bottom: 2.25rem;
        }
        p{
            font-family: 'SVN-Gilroy';
            font-weight: bold;
            font-size: 2rem;
            line-height: 114%;
            color: #050C1D;
            text-align: center;
            margin: 0 10%;
            margin-bottom: 2.125rem;
            &:last-child{
                font-size: 1rem;
                line-height: 125%;
                letter-spacing: 0.03125rem;
                color: #9EA1AB;
                margin: 0 20%;
            }
        }
        @media screen and (min-width: 577px) and (max-width: 992px){
            img{
                width: 5.25rem;
                height: 5.25rem;
                margin-bottom: 2.0625rem;
            }
            p{
                font-size: 1.75rem;
                margin: 0 20%;
                margin-bottom: 2.125rem;
            }
        }
        @media screen and (max-width: 576px){
            height: 100%;
            img{
                width: 4.875rem;
                height: 4.875rem;
                margin-bottom: 1.3125rem;
            }
            p{
                font-size: 1.625rem;                
                margin: 0 8%;
                margin-bottom: 1.5625rem;
                &:last-child{
                    margin: 0 10%;
                }
            }
        }
    }
`

export default class PopUpSentEmail extends Component {
    render() {
        return (
            <Bound>
                <div className='icon-image'>
                    <img src={img_03} alt='image-03'/>
                    <img src={img_04} alt='image-04'/>
                    <img src={img_05} alt='image-05'/>   
                    <img src ={ic_close} onClick = {() =>{this.props.onClosePopup()}} alt ='imgae-icon-close' /> 
                </div>
                <div className='sent-email'>
                    <img src={ic_accept} alt='image-accept'/>
                    <p>Cảm ơn bạn</p>
                    <p>Chúng tôi sẽ phản hồi email của bạn trong thời gian sớm nhất</p>
                </div>
            </Bound>
        )
    }
}
