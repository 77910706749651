import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.div`
    width: 1.21875rem;
    height: 1.875rem;
    box-shadow: inset 0 0 0 0.0625rem #fff;
    border-radius: 1.5625rem;
    position: relative;
    ::before{
        position: absolute;
        left: 50%;
        content: '';
        width: 0.25rem;
        height: 0.25rem;
        background: #fff;
        margin-left: -0.125rem;
        top: 0.5rem;
        border-radius: 0.25rem;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scroll;
    }
    ::after{

    }
    @keyframes scroll{
        0% { 
            opacity: 1;
        }
        100%{
            opacity: 0;
            transform: translateY(1.4375rem);
        }
    }
`

export default class MouseScrollControl extends Component {
    render() {
        return (
            <Bound>
                
            </Bound>
        )
    }
}
