import React, { Component } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie';

//data
import {TABLET, MOBILE} from '../../../data/constant'
// json
import datajson from '../../../json/mobile_moneymanagement_json_convenient/mobile_money_json.json'

const Bound = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff; 
    z-index: -1;
    width:97%;
    max-width:1440px;
    height: fit-content; 
    /* min-height: calc(100vh - 3.9375rem);  */
    /* padding: 0 7%; */
    position: relative;
    margin-top: 2.5rem;
    align-self:center;
    @media screen and (min-width: 577px) and (max-width: 992px){
        padding: unset;
        width:100%;
    }
    @media screen and (max-width: 576px){
        padding: unset;
        width:100%;
    }
    .title-wrapper{   
        display:flex;
        flex-direction:column;
        flex:1;
        .title{
            background-image: linear-gradient(76.36deg, #00CFFF -45.32%, #005BEC 169.02%);;
            font-family: 'SVN-Gilroy';
            font-size: 2.5rem;
            font-weight: bold;
            line-height: 1.14;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 0.9375rem ;
        }
        p{            
            font-family: 'UTM-Avo';
            font-size: 1rem;
            line-height: 1.6;
            color: #050c1d;
            text-align: justify;
            max-width:25rem;
        }
    }
    .animation-bound{
        display: flex;
        order: -1;
        width:100%;
        height:100%;
        align-items:center;
        justify-content:flex-start;
        flex:1;
        opacity: 0;
        top: 5rem;
        transition-property:opacity, top;
        transition-duration:800ms;
        margin: 2.5rem 0;
        /* tablet  */
        @media screen and (min-width: 577px) and (max-width: 992px){
            order: inherit;
            justify-content:center;
            padding-left: unset;
        }
        /* mobile */
        @media screen and (max-width: 576px){
            order: inherit;
            justify-content:center;
            padding-left: unset;
        }
        .image-bound{
            width:34.4375rem;
            transform: scale(0.75);
            min-width:21.875rem;
            /* tablet  */
            @media screen and (min-width: 577px) and (max-width: 992px){
                width:29.3125rem;
                margin-right:0;
                transform: unset;
            }
            /* mobile */
            @media screen and (max-width: 576px){
                min-width:unset;
                width: 18rem;
                margin:0;
                transform: unset;
            }
        }  
    }
    @media screen and (min-width: 577px) and (max-width: 992px) {
        z-index: -1;
        display: flex;
        flex-direction: column;        
        .title-wrapper{
            display: flex;
            flex-direction: column; 
            padding:4.8125rem 6.25rem 0 6.25rem;
            flex:unset;
            .title{ 
                font-size: 2.125rem;              
                padding-bottom: 1.125rem;
            }
            p{    
                font-size: 0.9375rem;   
                max-width:unset;         
            } 
        }
    }
    @media screen and (max-width: 576px) {
        z-index: -1;
        display: flex;
        flex-direction: column;
        .title-wrapper{
            display: flex;
            flex-direction: column;
            flex:unset;
            padding: 3.75rem 2rem 0 2rem;
            .title{  
                font-size: 2rem;             
                padding-bottom: 1.25rem;
            }
            p{     
                font-size: 0.875rem;
                max-width:unset;     
            } 
        }
    }
`
export default class FeaturesConvenient extends Component {
    constructor(props) {
        super(props);
        this.isScrollUp=false
        this.state = {
            isStopped: false,
            isPaused: false,
            direction: 1,
            device:'',
        };
        this.lottie = React.createRef();
    }
    componentDidMount(){
        this.setState({isPaused: true})
        this.handleLoadingAnimation();
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    
    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollUp);
        rootEle.removeEventListener("scroll", this.checkScrollDown)
    }
    refreshUI(){
        let {device} = this.state
        switch (device) {
            case MOBILE:{
                break;
            }
            case TABLET:{
                
            }
                break;
            default:
                break;
        }
    }
    playAnimation = (lottie,direction) => {
        this.setState({isPaused: false, direction})
    }
    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('features-convenient');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    playMainAnimation=(direction)=>{
        let title = document.getElementById('convenient-title');
        if(direction===1){
            if(title){
                title.style.opacity=1;
                title.style.top='0rem';   
            }
        }
        else{
            if(title){
                title.style.opacity=0;
                title.style.top='5rem';
            }
        }
        this.playAnimation(this.lottie,direction);
    }
    
    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: datajson,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Bound  id='features-convenient' 
                    // className='snap-child'
            >
                <div className='title-wrapper' >
                    <div className='title'>Tiện lợi</div>
                    <p>Như một người quản gia tận tuỵ, ứng dụng còn tự động ghi nhớ các hoạt động thu chi của bạn và thống kê vào danh mục đấy.</p>
                </div> 
                <div className='animation-bound'id='convenient-title'>
                    <div className='image-bound' id='convenient-id'>
                        <Lottie
                            ref={this.lottie}
                            options={defaultOptions}                           
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => {
                                        this.setState({isPaused: true})
                                    },
                                }
                            ]}
                            isClickToPauseDisabled={true}
                            direction={this.state.direction}
                        />
                    </div> 
                </div>           
            </Bound>
        )
    }
}

