import React, { Component } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie';
//data
import {TABLET, MOBILE} from '../../../data/constant'
// json 
import datajson from '../../../json/mobile_banking_json_andmore/mobile_banking_json.json'
// image
import smile02 from '../../../images/smile02.svg'

const Bound = styled.div`
    display: flex;
    flex-direction: column;    
    background-color: #ffffff;
    z-index: -1;  
    position: relative; 
    overflow:hidden;
    margin-top: 2.5rem ;
    @media screen and (min-width: 577px) and (max-width: 992px){
        margin-top: unset;
    }
    @media screen and (max-width: 576px){
        /* height: 80vh; */
    }    
    .title{
        display: flex;
        /* width:100%; */
        align-items: center;
        flex-direction: column;
        .title-child{
            display: flex;
            flex-direction: row;
            h2{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-family: 'SVN-Gilroy';
                font-size: 2.5rem;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                background-clip: text;
                padding-bottom:0.9375rem;
                letter-spacing: normal;
                color: #050C1D;
                &:first-child{
                    padding-right: 0.5rem;
                }
                img{              
                    margin-left: 0.5rem;
                    height: 2rem;
                    width: 2rem;
                } 
            }
        }
        p{               
            font-family: 'UTM-Avo';
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.6;
            color: #050c1d;
            max-width:25rem;
            text-align: center;
        }
        /* tablet */
        @media screen and (min-width: 577px) and (max-width: 992px){
            padding: 0 6.25rem 0 6.25rem;
            align-items: flex-start;
            .title-child{
                flex-direction: column;
                h2{
                    max-width: 15.875rem;
                    font-size: 2.125rem;
                    padding-bottom: 1.125rem;
                    justify-content: start;
                    &:first-child{
                        padding-bottom: unset;
                    }
                    img{              
                        margin-left: 0.3125rem;
                        height: 1.75rem;
                        width: 1.75rem;
                    } 
                }
            }
            p{
                text-align: justify;
                font-size: 0.9375rem;
                max-width: unset;
            }
        }
        /* mobile */
        @media screen and (max-width: 576px){
            padding: 0 2rem; 
            align-items: flex-start;
            .title-child{
                flex-direction: column;
                h2{
                    font-size: 2rem;
                    padding-bottom: 1.25rem;
                    justify-content: start;
                    &:first-child{
                        padding-bottom: unset;
                    }
                    img{
                        height: 1.75rem;
                        width: 1.75rem;
                    } 
                }
            }
            p{
                font-size: 0.875rem;
                text-align:justify;
                max-width:unset;
            }
        }
    }
    .animation-bound{
        display: flex;
        width:100%;
        height: 100%;
        align-items:center;
        order: inherit;
        justify-content:center;
        flex:1;
        opacity: 0;
        top: 8.125rem;
        transition-property: top, opacity;
        transition-duration:800ms;
        margin: 2.5rem 0;
        /* tablet */
        @media screen and (min-width: 577px) and (max-width: 992px){
            order: inherit;
        }
        /* mobile */
        @media screen and (max-width: 576px){
            order: inherit;
            .image-bound{
                width:34.4375rem;
                transform: scale(0.75);
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width: 29.3125rem;
                    transform: unset;
                }
                @media screen and (max-width: 576px){
                    min-width:unset;
                    width:18rem;
                    transform: unset;
                }
            }  
        }
    }
`
export default class FeaturesAndMore extends Component {
    constructor(props) {
        super(props);
        this.isScrollUp=false
        this.state = {
            isStopped: false,
            isPaused: false,
            direction: 1
        };
        this.lottie = React.createRef();
    }
    
    componentDidMount(){
        this.setState({isPaused: true})
        this.handleLoadingAnimation();
        this.refreshUI()
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollUp);
        rootEle.removeEventListener("scroll", this.checkScrollDown)
    }
    refreshUI=()=>{
        // const mainBound = document.getElementById('features-more');
        // let title = document.getElementById('more-title');
        // let animationBound = document.getElementById('features-more-animation-bound-id')
        // let lottieBound = document.getElementById('andmore-id')
        // let vh = window.innerHeight-63;
        // if(mainBound){
        //     setTimeout(() => {
        //         let height = mainBound.offsetHeight - 100;
        //         let heightTitle = title.offsetHeight + 30;
        //         let heightAnimBound = animationBound.offsetHeight + 50;
        //         if(height < vh){
        //             height = vh
        //         }
        //         if(height < heightTitle+heightAnimBound){
        //             height = heightTitle+heightAnimBound
        //         }
        //         else{
        //             heightAnimBound = height - heightTitle;
        //             // console.log(height, heightTitle,heightAnimBound)
        //             animationBound.style.height = heightAnimBound + 'px'
        //         }
        //         mainBound.style.height=height + 'px'
        //     }, 200);
        // }
    }
    playAnimation = (lottie,direction) => {
        this.setState({isPaused: false,direction})
    }
    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('features-more');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    playMainAnimation=(direction)=>{
        let title = document.getElementById('more-title');
        if(direction===1){
            if(title){
                title.style.opacity=1; 
                title.style.top='8.875rem;';
                setTimeout(() => {
                    this.playAnimation(this.lottie,direction);     
                }, 300);
            }
        }
        else{
            if(title){
                this.playAnimation(this.lottie,direction); 
                setTimeout(() => {
                    title.style.opacity=0;
                    title.style.top='8.125rem';
                }, 300);
            }
        } 
    }
    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: datajson,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            },            
        };        
        return (
            <Bound  id='features-more' 
                    // className='snap-child'
            >
                <div className='title'>
                    <div className='title-child'>
                        <h2>Và còn nhiều</h2>
                        <h2> hơn thế nữa<img src={smile02} alt='image-smile02'/></h2>
                    </div>
                    <p>Wee được xây dựng chuyên biệt để thỏa mãn mọi nhu cầu tài chính cá nhân của bạn.</p>                    
                </div>
                <div className='animation-bound'  id='more-title'>
                    <div className='image-bound' id='andmore-id'>
                        <Lottie 
                            width='auto'
                            height='auto'
                            ref={this.lottie}
                            options={defaultOptions}                                                  
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                            isClickToPauseDisabled={true}
                            direction={this.state.direction}
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => {
                                        this.setState({isPaused: true})
                                    },
                                }
                            ]}
                        />
                    </div>
                </div>                
            </Bound>
        )
    }
}
