import React from 'react';
// import Main from './ui/Main';
import Main from './ui/MainV2';
import { BrowserRouter as Router, withRouter } from "react-router-dom";

class App extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log("Route change!", this.props.location.pathname);
    }
  }
  render(){
    return (
      <Router>
        <Main/>
      </Router>
    );
  }
}

export default withRouter(props => <App {...props} />);
