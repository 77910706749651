import {WINDOWSPHONE, ANDROID, IOS, DESKTOP} from './data/constant'
import {hostAndroid, hostIOS, hostAppleWeeApp} from './data/host'

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"”"
    console.log(userAgent)
    if (/windows phone/i.test(userAgent)) {
        return WINDOWSPHONE;
    }
    if (/android/i.test(userAgent)) {
        return ANDROID;
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return IOS;
    }    
    return 'DESKTOP';
}

export function downloadApp(type){
    let deviceType = getMobileOperatingSystem()
    console.log(deviceType)
    switch (deviceType) {
        case WINDOWSPHONE:
            // window.location.href = hostAppleWeeApp
            window.open(hostAppleWeeApp,'_blank')
            break;
        case ANDROID:{
                if(type === ANDROID){
                    // window.location.href = hostAndroid
                    window.open(hostAndroid,'_blank')
                }
                else{
                    // window.location.href = hostAppleWeeApp
                    window.open(hostAppleWeeApp,'_blank')
                }
                break;
            }
        case IOS:{
                if(type === IOS){
                    // window.location.href = hostIOS
                    window.open(hostIOS,'_blank')
                }
                else{
                    // window.location.href = hostAppleWeeApp
                    window.open(hostAndroid,'_blank')
                }
                break;
            }
        case DESKTOP:{
            if(type === ANDROID){
                // window.location.href = hostIOS
                window.open(hostAndroid,'_blank')                
            }
            else{
                // window.location.href = hostAppleWeeApp
                window.open(hostIOS,'_blank')
            }
            break;
        }
        default:
            window.open(hostAppleWeeApp,'_blank')
            // window.location.href = hostAppleWeeApp
            break;
    }
}