import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import {DESKTOP} from '../../data/constant'

const Bound = styled.div`
    display:flex;
    min-height: auto;
    flex: 1;
    justify-content:center;
    top:0;
    left:0;
    .menu-sidebar{
        position:relative;
        /* left: -3rem; */
        ul{
            display: flex;
            justify-content: flex-end;
            li{
                margin-right: 2.125rem;
                :last-child{
                    margin-right:0;
                }
                .menu-active{
                    color: #0075FF !important;
                }
                a{
                    font-family: 'SVN-Gilroy';
                    font-size: 0.8125rem;
                    font-weight: bold;
                    letter-spacing: 0.125rem;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    text-decoration:none;
                    :hover{
                        cursor: pointer;
                    }
                }
            }
        }
        #menu-line-active{
            /* position:absolute;
            bottom:-0.1875rem;
            left: -1rem;
            height:0.125rem;
            background:#FFFFFF;
            transition:left 0.5s, width 0.5s; */
        }
        .language{
            display:flex;
            flex-direction:row;
            justify-content:flex-end;
            align-items:center;
            padding-bottom: 10%;
            a{
                font-family: 'SVN-Gilroy';
                font-size: 1.125rem;
                font-weight: bold;
                letter-spacing: 0.125rem;
                color: #050c1d;
                text-transform: uppercase;
                text-decoration:none;
                margin-right:1.125rem;
                :hover{
                    cursor: pointer;
                }
                &:last-child{
                    margin:0;
                    font-weight: normal;
                }
            }
            @media screen and (max-width: 576px){
                a{
                  font-size: 1rem;  
                }
            }
        }
    }

    /* mobile */
    @media screen and (max-width: 576px) {
        position:absolute;
        .menu-sidebar{
            position: fixed;
            top:-100vh;
            left:0;
            height: calc(100vh - 6.25rem);
            width: calc(100% - 5rem);
            background-color: #ffffff;
            transition: .3s ease;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 3.125rem 2.5rem;
            overflow:hidden;
            ul{
                z-index: 9;
                display: flex;
                flex-direction:column;
                li{
                    line-height: 1.875rem;
                    /* margin-bottom: 3.8125rem; */
                    margin-bottom: 18%;
                    position: relative;
                    border-left:0.1875rem solid transparent;
                    &:last-child{
                        margin-bottom: unset;
                    }
                    a{
                        font-family: 'SVN-Gilroy';
                        font-size: 1.25rem;
                        font-weight: bold;
                        letter-spacing: 0.125rem;
                        color: #050c1d;
                        text-transform: uppercase;
                        padding-left: 0.6875rem;
                        /* &:hover{
                            position: absolute;
                            width: 0.1875rem;
                            height: 21px;
                            background-color: #0075ff;
                            transition: .4s;
                            display: block;
                        } */
                    }
                }
            }
        }
        .active{
            top: 0;
        }
    }
    
    /* tablet */
    @media screen and (min-width: 577px) and (max-width: 992px) {
        position:absolute;
        .menu-sidebar{
            position: fixed;
            top:-100vh;
            left:0;
            height: calc(100vh - 6.25rem);
            width: calc(100% - 5rem);
            background-color: #ffffff;
            transition: .3s ease;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 3.125rem 2.5rem;
            overflow:hidden;
            ul{
                z-index: 9;
                display: flex;
                flex-direction:column;
                li{
                    line-height: 1.875rem;
                    margin-bottom: 12%;
                    position: relative;
                    border-left:0.1875rem solid transparent;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    margin-left: 2.1875rem;
                    &:first-child{
                        margin-top: 3.0625rem;
                    }
                    a{
                        font-family: 'SVN-Gilroy';
                        font-size: 2.125rem;
                        font-weight: bold;
                        letter-spacing: 0.125rem;
                        color: #050c1d;
                        text-transform: uppercase;
                        padding-left: 0.6875rem;
                        /* &:hover{
                            position: absolute;
                            width: 0.1875rem;
                            height: 1.3125rem;
                            background-color: #0075ff;
                            transition: .5s;
                            display: block;
                        } */
                    }
                }
            }
        }
        .active{
            top: 0;
        }
    }
`

class MenuContainer extends Component {
    indexSelected=0;
    timeOut=800
    state={
        device:this.props.device,
        key:''
    }
    componentDidMount(){
        let pathname=window.location.pathname;
        if(pathname&&pathname.length>2){
            let key=pathname.slice(1,pathname.length)
            setTimeout(() => {
                this.activeLink(key,true)
            }, 500);
        }
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device){
                this.setState({device})
            }
        }
    }
    renderMenu = () => {
        let {lang,menuData, device} = this.props
        let {key} = this.state
        return menuData.map((item, i) => {
            if(item.label){
                if(i===7) {
                    {
                        if(device !== DESKTOP){
                            return(
                                <li  key = {i} id={'menu-item-'+i} style={{borderLeftColor:key===item.key?'#0075ff':'transparent'}}>
                                    <Link to={"/"+item.key} className='menu-a' onClick={this.activeLink.bind(this, item.key, true)}>{item.label[lang]}</Link>
                                </li>
                            )
                        }else{
                            return null
                        }
                    }
                }
                return(
                    <li key = {i} id={'menu-item-'+i} style={{borderLeftColor:key===item.key?'#0075ff':'transparent'}}>
                        <Link to={"/"+item.key} className={key===item.key?'menu-a menu-active':'menu-a'} onClick={this.activeLink.bind(this, item.key, true)}>{item.label[lang]}</Link>
                    </li>
                )
            }
        })
    }
    
    activeLink(key, isScroll){
        let {menuData} = this.props
        let {device} = this.state
        if(key !== this.state.key){
            this.setState({key})
        }
        if(device===DESKTOP){
            let index = -1;
            for(let i = 0; i < menuData.length; i++){
                if(menuData[i].key===key){
                    index = i
                    break;
                }
            }
            let lineActive = document.getElementById('menu-line-active')
            if(index<=0){
                lineActive.style.width='0rem';
                return;
            }
            let menuItem = document.getElementById('menu-item-'+index);
            
            if(menuItem && lineActive){
                lineActive.style.width=menuItem.offsetWidth+'px';
                lineActive.style.left=menuItem.offsetLeft+'px';
            }
        }else{
            let icon = document.getElementById('icon');
            let menu  = document.getElementById('menu');
            if(menu&&icon){
                setTimeout(() => {
                    menu.classList.remove('active');
                    icon.classList.remove('active');
                }, 100);
            }
        }
        
        if(isScroll){
            setTimeout(() => {
                let activeEle = document.getElementById(key)
                if(activeEle){
                    activeEle.scrollIntoView()
                }
            }, this.timeOut);
            this.timeOut=0;
        }
    }

    render() {
        let {languageData} = this.props
        let {device} = this.state
        return (
            <Bound>
                <div className = 'menu-sidebar' id= 'menu'>
                    <ul>
                        {this.renderMenu()}
                    </ul>
                    {
                        device&&device===DESKTOP?
                        <div id='menu-line-active'/>
                        :
                        <div className='language'>
                            {
                                languageData.map((data,i)=>
                                    <a to='' key={i} className='menu-a' >{data.label}</a>
                                )
                            }
                        </div>
                    }
                </div>
            </Bound>
        );
    }
}

export default MenuContainer;