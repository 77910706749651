import React, { Component } from 'react';
import styled from 'styled-components';

//data
import {DESKTOP, TABLET, MOBILE} from '../../../data/constant'
import { imageBank } from '../../../data/data';
//Image
import bg_partners_dk from '../../../images/bg_partners_dk.svg';
import bg_partners_mb from '../../../images/bg_partners_mb.svg';
//Component
import {Link} from 'react-router-dom'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-height: 610px;
    height: 100vh;
    position:relative;
    .partners {
        width: 100%;
        height:100%;
        font-family: SVN-Gilroy;
        color: #FFFFFF;
        text-align: center;
        position:absolute;
        left:0;
        bottom:0;
        background-size: cover;
        background-position:left top;
        background-repeat:no-repeat;
        &__bank{
            width:100%;
            max-width:1440px;
            height: 100%;
            left:50%;
            transform:translate(-50%,0);
            position: absolute;
            img{
                position: absolute;
                transform:scale(0);
                opacity:0;
                transition-property:opacity, transform;
                transition-duration:800ms;
                &:nth-child(2){
                    width: 10.5rem;
                    height: 10.5rem;
                }
                &:nth-child(6){
                    width: 13rem;
                    height: 13rem;
                }
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    &:first-child, &:nth-child(4){
                        display: none;
                    }
                    &:nth-child(3){
                        width: 5.5rem;
                        height: 5.5rem;
                    }
                    &:nth-child(6){
                        width: 11.25rem;
                        height: 11.25rem;
                    }
                }
                @media screen and (max-width: 576px) {
                    &:nth-child(1), &:nth-child(3), &:nth-child(4){
                        display: none;
                    }
                    &:nth-child(2){
                        width: 6.4375rem;
                        height: 6.6875rem;
                    }
                    &:nth-child(6){
                        width: 8.5rem;
                        height: 8.5rem;
                    }
                }
            }
        }
        &__title{
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,0);
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 114%;
            text-align: center;
            max-width: 35.625rem;
            margin: 0 auto;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                top:55%;
                width: 72%;
                font-size: 2.125rem;
                line-height: 125%;
            }
            @media screen and (max-width: 576px) {
                top:50%;
                width: 70%;
                font-size: 2rem;
                line-height: 114%;
            }

            @media screen and (max-width: 360px) {
                width: 100%;
                transform: translate(-50%,-25%);
            }
        }
        
        .contact{
            display:flex;
            flex-direction:column;
            align-items:center;
            position:absolute;
            bottom:0;
            left:50%;
            transform:translate(-50%, 0);
            @media screen and (max-width: 360px) {
                transform: translate(-50%,0%);
            }
            .content{
                font-family: 'UTM-Avo';
                font-size: 1.125rem;
                line-height: 1.4375rem;
                text-align: center;
                @media screen and (max-width: 992px) {
                    font-size: 0.875rem;
                    line-height: 165%;
                }
            }
            .contact-button {
                text-align: center;
                border-radius: 6.25rem;
                line-height: 3.125rem;
                width: 12.5rem;
                height: 3.125rem;
                background: #FFFFFF;
                margin: 2.5rem 0 2.8125rem 0;
                cursor: pointer;
                a{
                    text-decoration: none;
                }
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    margin: 2.019375rem auto 6.043125rem auto;
                    width: 11.5625rem;
                }
                @media screen and (max-width: 576px) {
                    width: 11.5625rem;
                    margin: 1.5625rem auto 2.8125rem auto;
                }
                span{
                    width: 12.5rem;
                    height: 3.125rem;
                    font-size: 1rem;
                    color: #01befc;
                    line-height: 125%;
                    font-weight: bold;
                    letter-spacing: 0.03125rem;
                    text-transform: uppercase;  
                }
            }
        }
    }
`
class Partners extends Component {
    imgAnimate = null
    isScrollUp = false
    images = []

    state = {
        bg_partners : '',
        icons: '',
        device:this.props.device,
        images: [],
    }
    componentDidMount(){
        this.refreshUI()
        this.handleLoadingAnimation()
    }
    
    // componentDidUpdate(prevProps, prevState) {
    //     if(prevState.icons !== this.state.icons){
    //         this.images.map(img => img && this.handleLoadingAnimation(img))
    //     } 
    // }

    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    
    refreshUI = () =>{
        let {device} = this.state
        switch (device) {
            case MOBILE:{
                this.setState({
                    icons: imageBank.map(item => ({
                        top: item.top_mb,
                        left: item.left_mb,
                        right: item.right_mb,
                        icon: item.icon_mb
                    })),
                    bg_partners: bg_partners_mb
                });
                break;
            }
            case TABLET:{
                this.setState({
                    icons: imageBank.map(item => ({
                        top: item.top_tb,
                        left: item.left_tb,
                        right: item.right_tb,
                        icon: item.icon_tb,
                    })),
                    bg_partners: bg_partners_dk
                });
                break;
            }
            case DESKTOP:{
                this.setState({
                    icons: imageBank.map(item => ({
                        top: item.top_dk,
                        bottom: item.bottom_dk,
                        left: item.left_dk,
                        right: item.right_dk,
                        icon: item.icon_dk,
                    })),
                    bg_partners: bg_partners_dk
                });
                break;
            }
            default:
                break;
        }
    }

    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }

    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('partners');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    playMainAnimation=(direction)=>{
        if(this.images){
            this.images.forEach(element => {
                if(element){
                    this.playAnimation(direction,element)
                }
            });
        }
    }
    playAnimation = (direction, img) => {
        let imgArr = document.getElementsByClassName('animation-img-partners')
        let scale=0;
        let opacity=0;
        if(direction === 1){
            scale=1;
            opacity=1;
        } else {
            scale=0;
            opacity=0;
        }
        if(imgArr){
            for(let i = 0;i < imgArr.length;i++){
                imgArr[i].style.transform='scale('+scale+')';
                imgArr[i].style.opacity=opacity;
            }
        }
    }

    render() {
        const { bg_partners, icons } = this.state
        return (
            <Bound id = 'partners' >
                <div className = 'partners' style = {{backgroundImage: `url(${bg_partners})`}}>
                    <div className = 'partners__bank'>
                        {     
                            icons&&icons.map((item, i) => {
                                return(
                                    <img 
                                    ref = {ref => this.images.push(ref)}
                                    className = 'animation-img-partners'
                                    src = {item.icon} alt = {`${"image"} ${i}`} key = {i}
                                    style = {{
                                        top: item.top, 
                                        bottom: item.bottom, 
                                        left: item.left, 
                                        right: item.right,
                                    }}/>
                                )
                            })
                        }
                    </div>
                    <p className = 'partners__title'>Kết nối các ngân hàng trong cùng một ứng dụng</p>
                    <div className='contact'>
                        <span className = 'content'>Bạn là ngân hàng / tổ chức tín dụng ?</span>
                        <div className='contact-button'>
                            <Link to='/contact'>
                                <span>liên hệ hợp tác</span>
                            </Link>   
                        </div>
                    </div>
                </div>
            </Bound>
        );
    }
}
export default Partners;