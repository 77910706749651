import React, { Component } from 'react';
import styled from 'styled-components';

//data
import {DESKTOP, TABLET, MOBILE} from '../../../data/constant'

//Image
import smile_dk from '../../../images/smile_dk.svg';
import smile_tb from '../../../images/smile_tb.svg';
import smile_mb from '../../../images/smile_mb.svg';

import iconic_vietnam_map_dk from '../../../images/iconic_vietnam_map_dk.png';
import iconic_vietnam_map_tb from '../../../images/img_iconic_vietnam_map_tb.png';
import iconic_vietnam_map_mb from '../../../images/img_iconic_vietnam_map_mb.png';
import img_papa_tb from '../../../images/img_1.png';
import img_co4 from '../../../images/img_co4.png';
import img_anh2 from '../../../images/img_anh2.png'
import ic_conversation_audio_pause from '../../../images/ic_conversation_audio_pause.svg'
import ic_conversation_audio_play from '../../../images/ic_conversation_audio_play.svg'

//Package
import Lottie from 'react-lottie';

//Json
import datajsonSimple from '../../../json/mobile_connect_json/anh_2/anh2_animation.json';
import datajsonTwo from '../../../json/mobile_connect_json/anh_papa/json_papa_animation.json';
import datajsonThree from '../../../json/mobile_connect_json/anh_4/json_co4_animation.json';

const Bound = styled.div`
    display:flex;
    width:100%;
    height: 100vh;
    position:relative;
    margin: 2.5rem 0;
    @media screen and (min-width: 577px) and (max-width: 992px){
    margin: 2.5rem 0 0 0;
    }
    @media screen and (max-width: 576px){
        margin-bottom: 0;
        min-height: auto;
    }
    .animation-fade-in-2{
        animation: fadeIn .5s ease-in 1;
    }
    .animation-fade-out-2{
        animation: fadeOut .5s ease-in 1;
    }
    .animation-fade-in{
        animation: fadeIn .7s ease-in 1;
    }
    .animation-fade-out{
        animation: fadeOut .7s ease-in 1;
    }
    .animation-fade-in-3{
        animation: fadeIn 1s ease-in 1;
    }
    .animation-fade-out-3{
        animation: fadeOut 1s ease-in  1;
    }
    @keyframes fadeIn {
        0% {transform: scale(0)}
        98%, 100% {transform: scale(1)}
    }

    @keyframes fadeOut {
        0% {transform: scale(1)}
        98%,100% {transform: scale(0)}
    }

    .community{
        &__container{
            display:flex;
            position:absolute;
            top: 0;
            left:50%;
            transform:translate(-50%,0);
            flex-direction:column;
            align-items:center;
            width:100%;
            max-width:1440px;
            height: 100%;
        }
        &__content{
            margin-top:1.875rem;
            display:flex;
            flex-direction:column;
            @media screen and (min-width: 577px) and (max-width: 992px){
                padding: 0 6.25rem;
            }
            @media screen and (max-width: 576px){
                padding: 0 2rem ;
            }
            h2{
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'SVN-Gilroy';
                font-size: 2.5rem;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #050C1D;
                padding-bottom: 0.9375rem;
                img{
                    width:2.5rem;
                    height:2.5rem;
                    margin: 0 0 0 1.3125rem;
                }
                @media screen and (min-width: 577px) and (max-width: 992px){
                    font-size: 2.125rem;
                    line-height: 125%;
                    justify-content: flex-start;
                    padding-bottom: 1.125rem;
                }
                @media screen and (max-width: 576px){
                    width: 19rem;
                    font-size: 2rem;
                    line-height: 114%;
                    justify-content: flex-start;
                    padding-bottom: 1.25rem;
                }
            }
            p{
                width: 45%;
                margin: 0 auto ;
                font-family: 'UTM-Avo';
                font-size: 1rem;
                line-height: 160%;
                text-align: center;
                color: #050C1D;
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width: 100%;
                    text-align: justify;
                    font-size: 0.9375rem;
                    line-height: 165%;
                }
                @media screen and (max-width: 576px){
                    width: 100%;
                    font-size: 0.875rem;
                    line-height: 165%;
                    text-align: justify;
                    padding-bottom: 1.25rem;
                }
            }
        }
        &__image{
            display: flex;
            flex:1;
            width:100%;
            justify-content: space-around;
            position: relative;
            margin:1.5625rem 0;
            opacity: 0;
            top: 6.25rem;
            transition-property: opacity, top;
            transition-duration:800ms;
            @media screen and (min-width: 577px) and (max-width: 992px){
                margin: 0;
            }
            @media screen and (max-width: 576px){
                margin: 2.5rem 0 2.5rem 0;
            }
            &--animation {
                width:18.75rem;
                position: absolute;
                display: flex;
                align-items: center;
                z-index: 99;
                top:50%;
                left:12%;
                transform:translate(0,-50%);
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    display: flex;
                    align-items: center;
                    padding-left: 0;
                    top:60%;
                    left: 0.8125rem;
                }
                @media screen and (max-width: 576px) {
                    display: flex;
                    align-items: center;
                    top: 30%;
                    left: -3.25rem;
                    padding-left: 0;
                    transform: scale(0.6)
                }
            }
            &--map{
                width: 15.8125rem;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9;
                text-align: center;
                img{
                    width: 15.8125rem;
                    height: 35.5rem;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        width: 13.25rem;
                        height: 36.25rem;
                    }
                    @media screen and (max-width: 576px) {
                        width: 12.4375rem;
                        height: 28.5rem;
                    }
                }
                #map-wrapper{
                    z-index: 9;
                    width: 8.78125rem;
                    height: 2rem;
                    background: #FFFFFF;
                    box-shadow: 0rem 0.4375rem 4rem rgba(0, 0, 0, 0.07);
                    border-radius: 1.25rem 1.25rem 1.25rem 0.1875rem;
                    display: flex;
                    align-items: center;
                    padding: 0.4375rem 0.503125rem 0.375rem 0.496875rem;
                    margin-left: -0.625rem;
                    transform-origin: top left;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        display: none;
                    }

                    @media screen and (max-width: 576px) {
                        display: none;
                    }
                    .button__select{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        button{
                            background-repeat:no-repeat;
                            background-position:center center;
                        }
                        #playButton{
                            width: 1.875rem;
                            height: 1.875rem;
                            background-repeat: no-repeat;
                            background-position: center;
                            border-radius:50%;
                            border:0.0625rem solid #222222;
                            cursor: pointer;
                            &:active{
                                position: relative;
                                top: 0.125;
                            }
                        }

                        #defaultBar{
                            width: 4.4375rem;
                            height: 0.125rem;
                            background: #EBECED;
                            border-radius: 0.0625rem;
                            position: relative;
                            margin: 0 0.3125rem 0 0.346875rem;
                            cursor: pointer;
                            #progressBar{
                                width: 0;
                                height: 0.125rem;
                                position: absolute;
                                top:0;
                                left: 0;
                                background: #0075FF;
                                border-radius: 0.0625rem;
                            }
                        }
                        span{
                            font-family: Roboto;
                            font-size: 0.75rem;
                            line-height: 153.5%;
                            text-align: center;
                            color: #000000;
                        }
                    }
                }
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width:13.25rem;
                }
                @media screen and (max-width: 576px){
                    width: 12.5rem;
                }                
                @media screen and (min-width: 577px) and (max-width: 992px){
                    img{
                        width: 13.875rem;
                        height: 36.25rem;
                    }
                }
                @media screen and (max-width: 576px){
                    img{
                        width: 12.4375rem;
                        height: 28.5rem;
                    }
                }
            }
            .anh_1,.anh_2,.anh_3{
                height: fit-content;
                position:absolute;
            }
            .anh_1{
                @media screen and (min-width: 577px) and (max-width: 992px){
                    top: -8rem;
                    left: 0rem;
                }
                @media screen and (max-width: 576px){
                    top: 7rem;
                    left: 0rem;
                }
            }
            .anh_2{
                width: 15rem;
                top:10%;
                right:13%;
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width: 11.375rem;
                    height:11.375rem;
                    top: 5rem;
                    right: -1.375rem;
                }
                @media screen and (max-width: 576px){
                    transform: scale(0.85);
                    width: 8.5rem;
                    height:8.5rem;
                    top: -2rem;
                    right: -1.375rem;
                }
            }
            .anh_3{ 
                width:14.1875rem;
                bottom:0;
                right:15%;
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width:9.6875rem;
                    height:9.6875rem;
                    right:3.3125rem;
                }
                @media screen and (max-width: 576px){
                    width: 6.8125rem;
                    height:6.8125rem;
                    right:-1.375rem
                }
            }
        }
    }
`
class Community extends Component {
    isScrollUp = false;
    state = {
        icon_smile: '',
        iconic_vietnam_map: '',
        playing: false,
        video: 'media/test.mp3',
        width: null,
        device: this.props.device,
        directionCommunityOne: 1,
        isPausedCommunityOne: false,
        directionCommunityTwo: 1,
        isPausedCommunityTwo: false,
        directionCommunityThree: 1,
        isPausedCommunityThree: false
    }

    componentDidMount() {
        this.refreshUI()

        let duration = document.getElementById('fullDuration')
        const audio = new Audio()
        audio.src = this.state.video;
        audio.onloadedmetadata = function () {
            // ---------- Count up ---------- //
            // let minutes = Math.floor(parseInt(mytrack.duration/60))
            // minutes = minutes < 10 ? '0' + minutes : minutes

            // let seconds = parseInt(mytrack.duration%60)
            // seconds = seconds < 10 ? '0' + seconds : seconds
            // duration.innerHTML = minutes + ':' + seconds

            // ---------- CountDown ---------- //
            var playedMinutes = Math.floor((((this.duration % 31536000) % 86400) % 3600) / 60)
            var playedSeconds = Math.round((((this.duration % 3153600) % 86400) % 3600) % 60)
            playedMinutes = playedMinutes < 10 ? '0' + playedMinutes : playedMinutes
            playedSeconds = playedSeconds < 10 ? '0' + playedSeconds : playedSeconds

            duration.innerHTML = playedMinutes + ':' + playedSeconds
        }

        this.setState({
            isPausedCommunityOne: true,
            isPausedCommunityTwo: true,
            isPausedCommunityThree: true
        })
        this.handleLoadingAnimation();
        // this.handleLoadingAnimation(this.lottieCommunityTwo);
        // this.handleLoadingAnimation(this.lottieCommunityThree);
    }

    componentWillReceiveProps(n) {
        if(n){
            let { device } = n
            if(device && device !== this.state.device){
                this.setState({device}, () => { 
                    this.refreshUI()
                });
                
            }
        }
    }

    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }

    refreshUI(){
        let {device} = this.state
        switch (device) {
            case DESKTOP:
                this.setState({
                    icon_smile: smile_dk,
                    iconic_vietnam_map: iconic_vietnam_map_dk
                })
                break;
            case TABLET:
                this.setState({
                    icon_smile: smile_tb,
                    iconic_vietnam_map: iconic_vietnam_map_tb
                })
                break;
            default:
                this.setState({
                    icon_smile: smile_mb,
                    iconic_vietnam_map: iconic_vietnam_map_mb
                })
                break;
        } 
        // const mainBound = document.getElementById('introduction');
        // const animationBound = document.getElementById('introduction-animation-bound')
        // if(mainBound&&animationBound){
        //     setTimeout(() => {
        //         console.log(animationBound.offsetHeight)
        //         mainBound.style.height=animationBound.offsetHeight+'px';
        //     }, 300);
        // }
    }

    // playOrPause = () => {
    //     let myTrack = document.getElementById('mytrack')
    //     if (!myTrack.paused) {
    //         myTrack.pause()
    //         this.setState({
    //             playing: false
    //         });
    //         window.clearInterval()
    //     } else {
    //         myTrack.play()
    //         this.setState({
    //             playing: true
    //         });
    //         setInterval(() => {
    //             this.updateTime()
    //         }, 500);
    //     }
    // }

    // updateTime = () => {
    //     let mytrack = document.getElementById('mytrack')
    //     let fullDuration = document.getElementById('fullDuration')
    //     let barSize = 71
    //     if (!mytrack.ended) {
    //         // ---------- Count up ---------- //
    //         // let playedMinutes = Math.floor(parseInt(mytrack.currentTime/60))
    //         // playedMinutes = playedMinutes < 10 ? '0' + playedMinutes : playedMinutes
    //         // let playedSeconds = parseInt(mytrack.currentTime%60)
    //         // playedSeconds = playedSeconds < 10 ? '0' + playedSeconds : playedSeconds
    //         // currentTime.innerHTML = playedMinutes + ':' + playedSeconds

    //         // ---------- CountDown ---------- //
    //         let remain = mytrack.duration - mytrack.currentTime
    //         let playedMinutes = Math.floor((((remain % 31536000) % 86400) % 3600) / 60)
    //         playedMinutes = playedMinutes < 10 ? '0' + playedMinutes : playedMinutes
    //         let playedSeconds = Math.round((((remain % 3153600) % 86400) % 3600) % 60)
    //         playedSeconds = playedSeconds < 10 ? '0' + playedSeconds : playedSeconds
    //         fullDuration.innerHTML = playedMinutes + ':' + playedSeconds
    //         let size = parseInt(mytrack.currentTime * barSize / mytrack.duration)
    //         this.setState({
    //             width: size
    //         });
    //     } else {
    //         this.setState({
    //             width: 0
    //         });
    //         let mytrack = document.getElementById('mytrack')
    //         let fullDuration = document.getElementById('fullDuration')
    //         let minutes = Math.floor(parseInt(mytrack.duration / 60))
    //         minutes = minutes < 10 ? '0' + minutes : minutes
    //         let seconds = parseInt(mytrack.duration % 60)
    //         seconds = seconds < 10 ? '0' + seconds : seconds
    //         fullDuration.innerHTML = minutes + ':' + seconds

    //         mytrack.pause()
    //         this.setState({
    //             playing: false,
    //         });
    //         window.clearInterval(this.uppdateTime)
    //     }
    // }
    playAnimation = (lottie,direction) => {
        switch(lottie){
            case this.lottieCommunityOne:
                this.setState({
                    directionCommunityOne:direction,
                    isPausedCommunityOne: false
                })
                break;
            case this.lottieCommunityTwo:
                this.setState({
                    directionCommunityTwo:direction,
                    isPausedCommunityTwo: false
                })
                break;
            case this.lottieCommunityThree:
                this.setState({
                    directionCommunityThree:direction,
                    isPausedCommunityThree: false
                })
                break;
            default:
                break;
        }
    }
    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('introduction');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    playMainAnimation=(direction)=>{
        const animationBound = document.getElementById('introduction-animation-bound')
        let {device} = this.state
        if(device === TABLET || device === MOBILE){
            if(direction === 1) {
                this.image01.classList.remove('animation-fade-out')
                this.image02.classList.remove('animation-fade-out-3')
                this.image03.classList.remove('anmation-fade-out-2')
                this.image01.classList.add('animation-fade-in')
                this.image02.classList.add('animation-fade-in-3')
                this.image03.classList.add('animation-fade-in-2')
            }else {
                this.image01.classList.add('animation-fade-in')
                this.image02.classList.add('animation-fade-in-3')
                this.image03.classList.add('animation-fade-in-2')
                this.image01.classList.remove('animation-fade-out')
                this.image02.classList.remove('animation-fade-out-3')
                this.image03.classList.remove('animation-fade-out-2')
            }
        }
        if(direction===1){
            if(animationBound){
                animationBound.style.opacity=1;
                animationBound.style.top='0';
            }
            setTimeout(() => {
                this.playAnimation(this.lottieCommunityOne,direction);
            }, 150);
            setTimeout(() => {
                this.playAnimation(this.lottieCommunityTwo,direction);
            }, 300);
            setTimeout(() => {
                this.playAnimation(this.lottieCommunityThree,direction);
            }, 450);
        }
        else{
            if(animationBound){
                animationBound.style.opacity=0;
                animationBound.style.top='6.25rem';
            }
            this.playAnimation(this.lottieCommunityThree,direction);
            setTimeout(() => {
                this.playAnimation(this.lottieCommunityTwo,direction);
            }, 150);
            setTimeout(() => {
                this.playAnimation(this.lottieCommunityOne,direction);
            }, 300);
        }
    }
    convert = (element) => {
        var playedMinutes = Math.floor((((element.duration % 31536000) % 86400) % 3600) / 60)
        var playedSeconds = Math.round((((element.duration % 3153600) % 86400) % 3600) % 60)
        playedMinutes = playedMinutes < 10 ? '0' + playedMinutes : playedMinutes
        playedSeconds = playedSeconds < 10 ? '0' + playedSeconds : playedSeconds
        return playedMinutes + ':' + playedSeconds
    }

    // clickBar = (e) => {
    //     let barSize = 71
    //     let bar = document.getElementById('defaultBar')
    //     let mytrack = document.getElementById('mytrack')
    //     if (!mytrack.ended) {
    //         let mouseX = e.pageX - bar.offsetLeft
    //         let newTime = mouseX * mytrack.duration / barSize
    //         mytrack.currentTime = newTime
    //         this.setState({
    //             width: mouseX + 'px'
    //         });
    //     }
    // }

    render() {
        let {device} = this.state
        // console.log(device)
        const defaultCommunityOne = {
            loop: false,
            autoplay: true,
            animationData: datajsonSimple,
            rendererSetting: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        const defaultCommunityTwo = {
            loop: false,
            autoplay: true,
            animationData: datajsonTwo,
            rendererSetting: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        const defaultCommunityThree = {
            loop: false,
            autoplay: true,
            animationData: datajsonThree,
            rendererSetting: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        const { icon_smile, iconic_vietnam_map } = this.state
        return (
            <Bound id='introduction' >
                <div className = 'community__container' >
                    <div className = 'community__content'>
                        <h2>cho mọi người...
                            <img src={icon_smile} alt='icon_smile' />
                        </h2>
                        <p>Với Wee, chúng tôi mong muốn đem lại trải nghiệm tuyệt vời cho người sử dụng. Một trải nghiệm “ngân hàng” hoàn toàn khác biệt.</p>
                    </div>
                    <div className='community__image'id='introduction-animation-bound'>
                        <div className='community__image--animation' id='community__image__left'>
                            {
                                device && device === DESKTOP?
                                <div className='anh_1'>
                                    <Lottie
                                        options={defaultCommunityOne}
                                        ref={ref => this.lottieCommunityOne = ref}
                                        isClickToPauseDisabled={true}
                                        direction={this.state.directionCommunityOne}
                                        isPaused={this.state.isPausedCommunityOne}
                                        eventListeners={[
                                            {
                                                eventName: 'complete',
                                                callback: () => {
                                                    this.setState({isPausedCommunityOne: true}) 
                                                },
                                            }
                                        ]}
                                        height='auto'
                                        width='auto'
                                    />
                                </div>
                                :
                                <img className='anh_1' src={img_anh2} alt='papa' ref = {ref => this.image03 = ref}/>
                            }
                        </div>
                        <div className='community__image--map'>
                            <img src={iconic_vietnam_map} alt='iconic_vietnam_map' 
                                ref = { ref => this.map =  ref }/>
                            <div id='map-wrapper' ref = { ref => this.audio = ref}>
                                {/* <audio id='mytrack' preload="auto">
                                    <source src={this.state.video}></source>
                                </audio> */}
                                <div className='button__select'>
                                    <button type='button' id='playButton'
                                        style={{ backgroundImage: this.state.playing ? `url(${ic_conversation_audio_pause})` : `url(${ic_conversation_audio_play})` }}
                                        onClick={this.playOrPause}>
                                    </button>
                                    <div id='defaultBar' onClick={this.clickBar}>
                                        <div id='progressBar' style={{ width: this.state.width }}></div>
                                    </div>
                                    <span id='fullDuration'></span>
                                </div>
                            </div>
                        </div>
                        {
                            device && device === DESKTOP?
                            <div className='anh_2'>
                                <Lottie
                                    options={defaultCommunityTwo}
                                    ref={ref => this.lottieCommunityTwo = ref}
                                    isClickToPauseDisabled={true}
                                    direction={this.state.directionCommunityTwo}
                                    isPaused={this.state.isPausedCommunityTwo}
                                    eventListeners={[
                                        {
                                            eventName: 'complete',
                                            callback: () => {
                                                this.setState({isPausedCommunityTwo: true}) 
                                            },
                                        }
                                    ]}
                                    height='auto'
                                    width='auto'
                                />
                            </div>
                            :
                            <img className='anh_2' src={img_papa_tb} alt='papa' ref = {ref => this.image01 = ref}/>
                        }
                        {
                            device && device === DESKTOP?
                                <div className='anh_3'>
                                    <Lottie
                                        options={defaultCommunityThree}
                                        ref={ref => this.lottieCommunityThree = ref}
                                        isClickToPauseDisabled={true}
                                        direction={this.state.directionCommunityThree}
                                        isPaused={this.state.isPausedCommunityThree}
                                        eventListeners={[
                                            {
                                                eventName: 'complete',
                                                callback: () => {
                                                    this.setState({isPausedCommunityThree: true}) 
                                                },
                                            }
                                        ]}
                                        height='auto'
                                        width='auto'
                                    />
                                </div>
                            :
                            <img className='anh_3' src={img_co4} alt='img_co4' ref = {ref => this.image02 = ref}/>
                        }
                    </div>
                </div>
            </Bound>
        );
    }
}

export default Community;