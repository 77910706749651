import React, { Component } from 'react';
import styled from 'styled-components';

//Data
import { imageQA, qaData } from '../../../data/data';

//Image
import plus_black from '../../../images/plus_black.svg';
import plus_blue from '../../../images/plus_blue.svg';
import line_black from '../../../images/line_black.svg';
import line_blue from '../../../images/line_blue.svg';
import { DESKTOP } from '../../../data/constant';

const Bound = styled.div`
    display: flex;
    position:relative;
    flex-direction: column;
    align-items:center;
    .QA__postion{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        margin-top: 9.375rem;
        opacity: 0;
        transition-property: margin-top, opacity;
        transition-duration:800ms;
        .animation-img-qa{
            position: absolute;
            transform:scale(0);
            opacity:0;
            transition-property:opacity, transform;
            transition-duration:800ms;
            &:first-child{
                width: 7.546875rem;
                height: 7.546875rem;
            }
            &:nth-child(2){
                width: 9rem;
                height: 9rem;
            }
            &:nth-child(3){
                width: 11.390625rem;
                height: 11.390625rem;
            }
            &:nth-child(4){
                width: 8.54296875rem;
                height: 8.54296875rem;
            }
            &:nth-child(3){
                width: 8.25rem;
                height: 8.25rem;
            }
            @media screen and (min-width: 577px) and (max-width: 992px) {
                &:first-child{
                    display: none;
                }
                &:nth-child(2){
                    width: 6.625rem;
                    height: 6.625rem;
                }
                &:nth-child(3){
                    width: 8.5625rem;
                    height: 8.5625rem;
                }
                &:nth-child(4){
                    display: none;
                }
                &:nth-child(5){
                    width: 6.5625rem;
                    height: 6.5625rem;
                }
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
        }    
    }
    
    .QA__main{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 3.75rem 0 ;
        font-family: 'SVN-Gilroy';
        width: 74%;
        max-width:1440px;
        z-index:1;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            padding: 0 6.25rem;
            width: unset;
        }
        @media screen and (max-width: 576px) {
            width: unset;
            padding: 0 2rem;
        }
        h2{
            display: flex;
            font-size: 2.5rem;
            line-height: 114%;
            color: #050C1D;
            margin-bottom: 2.3125rem;
            margin-left: 2rem;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                font-size: 2.125rem;
                line-height: 125%;
            }
            @media screen and (max-width: 576px) {
                font-size: 2rem;
                line-height: 114%;
                margin-bottom: 1.75rem;
                justify-content: center;
                margin-left: unset;
            }
        }
        .QA__inform{
            color: #050C1D;
            margin-bottom: 2.3125rem;
            width: 80%;
            @media screen and (min-width: 577px) and (max-width: 992px) {
                margin-bottom: 1.75rem;
                width: unset;
            }
            @media screen and (max-width: 576px) {
                margin-bottom: 2.125rem;
                width: unset;
            }
            .QA__question{
                display: flex;
                text-align: justify;
                @media screen and (min-width: 577px) and (max-width: 992px){
                    width: unset;
                }
                @media screen and (max-width: 576px){
                    width: unset;
                }
                img{
                    width: 1.125rem;
                    height: 1.125rem;
                }
                h4{
                    font-family: SVN-Gilroy;
                    padding-left: 2%;
                    font-size: 1.25rem;
                    line-height: 120%;
                    cursor: pointer;
                    width: 100%;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        line-height: 125%;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 1rem;
                    }
                }
            }
            p.QA__answer{
                font-family: 'UTM-Avo';
                text-align: justify;
                padding: 1% 0 0 4.5%;
                font-size: 0.9375rem;
                line-height: 160%;
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    font-size: 0.875rem;
                    line-height: 165%;
                    padding: 1% 0 0 5%;
                }
                @media screen and (max-width: 576px) {
                    font-size: 0.875rem;
                    line-height: 165%;
                    padding: 1% 0 0 0;
                    width: 100%;
                }
            }
        }
        
    }
`

class QAnswer extends Component {
    isScrollUp=false;
    images = [];
    state = {
        partners: qaData,
        isActive: false,
        line: '',
        plus: '',
        img: '',
        device:this.props.device,
        indexSelected:-1
    }

    onToggle = i => {
        let {indexSelected} = this.state
        if(indexSelected===i){
            indexSelected = -1;
        }
        else{
            indexSelected = i
        }
        this.setState({indexSelected})
    }

    handleLoadingAnimation = (img) => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('q&a');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    playMainAnimation=(direction)=>{
        if(this.images){
            this.images.forEach(element => {
                if(element){
                    this.playAnimation(direction,element)
                }
            });
        }
    }
    playAnimation = (direction, img) => {
        let imgArr = document.getElementsByClassName('animation-img-qa')
        let qaMain = document.getElementById('aq-main-id')
        let scale=0;
        let opacity=0;
        if(direction === 1){
            scale=1;
            opacity=1;
            if(qaMain){
                qaMain.style.marginTop='3.125rem'
                qaMain.style.opacity=1
            }
        } else {
            scale=0;
            opacity=0;
            if(qaMain){
                qaMain.style.marginTop='9.375rem'
                qaMain.style.opacity=0
            }
        }
        if(imgArr){
            for(let i = 0;i < imgArr.length;i++){
                imgArr[i].style.transform='scale('+scale+')';
                imgArr[i].style.opacity=opacity;
            }
        }
    }
    componentDidMount(){
        this.refreshUI()
        this.handleLoadingAnimation()
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    
    refreshUI = () => {
        let {device} = this.state
        switch (device) {
            case DESKTOP:
                this.setState({
                    plus: plus_black,
                    line: line_black,
                    img: imageQA.map(item => ({
                        top: item.top,
                        bottom: item.bottom,
                        left: item.left,
                        right: item.right,
                        img: item.img_dk
                    }))
                });
                break;
        
            default:
                this.setState({
                    plus: plus_blue,
                    line: line_blue,
                    img: imageQA.map(item => ({
                        top: item.top_mb,
                        bottom: item.bottom_mb,
                        left: item.left_mb,
                        right: item.right_mb,
                        img: item.img_mb
                    }))
                });
                break;
        }
        let qaPosition = document.getElementById('qa-postion-id');
        let qaMain = document.getElementById('aq-main-id')
        let vh = window.innerHeight
        if(qaPosition&&qaMain){
            setTimeout(() => {
                let height = qaMain.offsetHeight + 100;
                if(height < vh){
                    height = vh
                }
                
                qaPosition.style.height=height + 'px'
            }, 200);
        }
    }

    showQuestion = () =>{
        const {plus, line, indexSelected} = this.state
        return qaData.map((item,i) => {
           return(
               <div key = {i} style = {{transition: 'all .5s ease'}} className = 'QA__inform'>
                    <div onClick = {()=>this.onToggle(i)} className = 'QA__question'>
                        <img src ={ indexSelected !== i ? plus : line } alt = 'icon'/>
                        <h4 >{item.question}</h4>
                    </div>
                    {
                        indexSelected===i &&
                            item.answer.map((itemanswer,index)=>{
                                return(
                                    <p style = {{transition: 'all 100ms ease'}} className = 'QA__answer' key={index}>{itemanswer}</p>
                                )
                            })
                    }
               </div>
           ) 
        })
    }
    render() {
        let {img} = this.state
        return (
            <Bound id='q&a' className='snap-child'>
                <div className='QA__postion' id='qa-postion-id' id='aq-main-id'>
                {
                    img&&img.map((item, i) => {
                        return(
                            <img src = {item.img} alt = {i} key = {i} 
                            className = 'animation-img-qa'
                            ref = {ref=> this.images.push(ref)}
                            style = {{top: item.top, bottom: item.bottom, left: item.left, right: item.right}}/>
                        )
                    })
                }
                </div>
                <div className = 'QA__main' >
                    <h2>Hỏi đáp cùng bạn</h2>
                    {this.showQuestion() }
                </div>
            </Bound>
        );
    }
}

export default QAnswer;
