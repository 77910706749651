import React, { Component } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie';

//data
import {DESKTOP, TABLET, MOBILE} from '../../../data/constant'
// json
import datajsonSimple from '../../../json/mobile_send_json_simple/mobile_send_json.json'
import datajsonEasy from '../../../json/mobile_request_json_easy/mobile_request_json.json'
import datajsonFast from '../../../json/mobile_split_json_fast/mobile_split_json.json'

const marginWrapperDesktop=3.125;
const marginWrapperTablet=1.25;
const marginWrapperMobile=1.875;

const Bound = styled.div `
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height:fit-content;
    align-items:center;
    margin-top: 2.5rem;
    .title{                                 
        font-family: 'SVN-Gilroy';
        font-size: 2.5rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 0.9375rem;
    }
    p{   
        font-family: 'UTM-Avo';
        font-size: 1rem;
        line-height: 1.6;
        color: #050c1d;
        text-align: justify;
    } 
    .wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;        
        width:74%;
        max-width:1440px;
        height:16.875rem;
        margin:${marginWrapperDesktop}rem 0;
        position:relative;
        /* tablet  */
        @media screen and (min-width: 577px) and (max-width: 992px){
            width:87%;
            margin:${marginWrapperTablet}rem 0;
        }
        /* mobile */
        @media screen and (max-width: 576px){
            width:100%;
            height:24.6875rem;
            margin:${marginWrapperMobile}rem 0;
        }   
        .title-wrapper{ 
            display:flex;
            flex-direction:column;  
            height:fit-content;
            position:absolute;
            top:5rem;
            left:0;
            width:50%;
            .content{
                .simple{          
                    background-image: linear-gradient(22.38deg, #9000FF 0%, #482EF6 100%);              
            }   
                .easy{          
                    background-image: linear-gradient(22.38deg, #F7BB25 0%, #FF2424 100%);             
                }   
                .fast{          
                    background-image: linear-gradient(191.52deg, #00A548 0%, #BDD50A 100%);                
                }   
            }
            /* tablet */
            @media screen and (min-width: 577px) and (max-width: 992px){
                
            }
            /* mobile */
            @media screen and (max-width: 576px){
                position:unset;
                width:unset;
                .content{
                    max-width:unset !important;
                }
            }   
        }
        &:nth-child(2){
            justify-content:flex-start;
            .title-wrapper{
                left:unset;
                right:0;
            } 
        }
        .note{
            font-family: 'SVN-Gilroy';
            font-size: 9rem;
            font-weight: bold;
            line-height: 1.2;
            color: rgba(201,205,214,0.15);
            position:absolute;
            bottom:-35%;
            left:-1%;
            opacity:0;
            width:max-content;
            transition:opacity 800ms;
        }
        .animation-bound{
            display:flex;
            height:17.1875rem;
            width:50%;
            justify-content:center;
            opacity: 0;
            top: 5rem;
            transition-property:top, opacity;
            transition-duration:800ms;
            /* tablet */
            @media screen and (min-width: 577px) and (max-width: 992px){
                position:relative;
                .image-bound{
                    position:absolute;
                }
                .right{
                    right:-3.4375rem;
                }
                .left{
                    left:-1.875rem;
                }
            }
            /* mobile */
            @media screen and (max-width: 576px){
                width:unset;
                position:relative;
                .image-bound{
                    position:absolute;
                }
                .right{
                    right:-3.4375rem;
                }
                .left{
                    left:-1.875rem;
                }
            }
        }
        .right{
            order:-1;
        }      
    }
    @media screen and (min-width: 577px) and (max-width: 992px){
        z-index: -1;
        .title{
            font-size: 2.125rem;            
            padding: 0rem 3.125rem 1.125rem 3.125rem; 
        }
        p{
            font-size: 0.9375rem;
            padding: 0 3.125rem 
        }
        .note{
            display: none;
        }
        .wrapper{
            display: flex;
            flex-direction: row;              
            margin-bottom: 2.5rem ;
            .right{
                order:-1;
            } 
        }   
    }
    @media only screen and (max-width:576px){
        z-index: -1;
        .title{
            font-size: 2rem;            
            padding: 0 2rem 1.25rem 2rem; 
        }
        p{
            font-size: 0.875rem;
            padding: 0 2rem 1.25rem 2rem;
        } 
        .note{
            display: none;
        }
        .wrapper{
            display: flex;
            flex-direction: column;   
            .right{
                order:inherit;               
            } 
            .image-bound{                
                position: absolute;                
                top: 0;
            }
            .simple{
                right: -1.875rem;
            }
            .easy{
                left: -1.5625rem;
            }
            .fast{
                right: -2.1875rem;
            }
        }   
    }
    
`
export default class FeaturesSimPleEasyFast extends Component {
    state={
        device:this.props.device,
        isPausedOptionsSimple: false,
        directionOptionsSimple: 1,
        isPausedOptionsEasy: false,
        directionOptionsEasy: 1,
        isPausedOptionsFast: false,
        directionOptionsFast: 1
    }
    componentDidMount() {
        let wrapperArr = document.getElementsByClassName('wrapper')
        if(wrapperArr&&wrapperArr.length>0){
            let minHeight=0
            setTimeout(() => {
                let {device} = this.state
                let margin = 0;
                switch (device) {
                    case DESKTOP:
                        margin=marginWrapperDesktop
                        break;
                    case TABLET:
                        margin=marginWrapperTablet
                        break;
                    case MOBILE:
                        margin=marginWrapperMobile
                        break;
                    default:
                        break;
                }
                for(let i = 0; i < wrapperArr.length;i++){
                    // console.log(wrapperArr[i].offsetHeight)
                    minHeight+=wrapperArr[i].offsetHeight+margin*2;
                }
                // console.log(minHeight)
                let bound = document.getElementById('features')
                if(bound){
                    // bound.style.minHeight=minHeight+'px'
                }
            }, 200);
            
        }
        this.setState({
            isPausedOptionsSimple: true,
            isPausedOptionsEasy: true,
            isPausedOptionsFast: true
        })
        this.handleLoadingAnimation();
    }
    
    componentWillReceiveProps(n) {
        if(n){
            let { device } = n
            if(device && device !== this.state.device){
                this.setState({device}, () => { 
                    // this.refreshUI()
                });
            }
        }
    }

    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }

    playAnimation = (lottie,direction) => {
        switch(lottie){
            case this.lottieOptionsSimple:
                this.setState({
                    directionOptionsSimple:direction,
                    isPausedOptionsSimple: false
                })
                break;
            case this.lottieOptionsEasy:
                this.setState({
                    directionOptionsEasy:direction,
                    isPausedOptionsEasy: false
                })
                break;
            case this.lottieOptionsFast:
                this.setState({
                    directionOptionsFast:direction,
                    isPausedOptionsFast: false
                })
                break;
            default:
                break;
        }
    }

    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('features');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }

    playMainAnimation=(direction)=>{
        let wrapperSimple = document.getElementById('wrapper-simple')
        let noteSimple = document.getElementById('note-simple')
        let wrapperEasy = document.getElementById('wrapper-easy')
        let noteEasy = document.getElementById('note-easy')
        let wrapperFast = document.getElementById('wrapper-fast')
        let noteFast = document.getElementById('note-fast')
        if(direction===1){
            if(wrapperSimple && noteSimple){
                wrapperSimple.style.opacity=1;
                wrapperSimple.style.top='1.5625rem';
                noteSimple.style.opacity=1;
            }
            this.playAnimation(this.lottieOptionsSimple,direction);
            setTimeout(() => {
                if(wrapperSimple && noteEasy){
                    wrapperEasy.style.opacity=1;
                    wrapperEasy.style.top='1.5625rem';
                    noteEasy.style.opacity=1;
                }
                this.playAnimation(this.lottieOptionsEasy,direction);
            }, 500);
            setTimeout(() => {
                if(wrapperFast && noteFast){
                    wrapperFast.style.opacity=1;
                    wrapperFast.style.top='1.5625rem';
                    noteFast.style.opacity=1;
                }
                this.playAnimation(this.lottieOptionsFast,direction);
            }, 1000);
        }
        else{
            if(wrapperFast && noteFast){
                wrapperFast.style.opacity=0;
                wrapperFast.style.top='5rem';
                noteFast.style.opacity=0;
            }
            this.playAnimation(this.lottieOptionsSimple,direction);
            setTimeout(() => {
                if(wrapperSimple && noteEasy){
                    wrapperEasy.style.opacity=0;
                    wrapperEasy.style.top='5rem';
                    noteEasy.style.opacity=0;
                }
                this.playAnimation(this.lottieOptionsEasy,direction);
            }, 500);
            setTimeout(() => {
                if(wrapperSimple && noteSimple){
                    wrapperSimple.style.opacity=0;
                    wrapperSimple.style.top='5rem';
                    noteSimple.style.opacity=0;
                }
                this.playAnimation(this.lottieOptionsFast,direction);
            }, 1000);
        }
    }

    render() {
        const defaultOptionsSimple = {
            loop: false,
            autoplay: true, 
            animationData: datajsonSimple,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        const defaultOptionsEasy = {
            loop: false,
            autoplay: true, 
            animationData: datajsonEasy,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        const defaultOptionsFast = {
            loop: false,
            autoplay: true, 
            animationData: datajsonFast,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Bound id = 'features' className='snap-child'>
                <div className='wrapper'>
                    <div className='title-wrapper' >
                        <div style={{maxWidth:'25rem'}} className='content'>
                            <div className='title simple'>Đơn giản</div>
                            <p>Tưởng tượng rằng bạn sẽ chuyển tiền dễ dàng như cách mà bạn vẫn thường gửi tin nhắn cho bạn bè.</p>
                        </div>
                    </div>                    
                    <div className='animation-bound'id='wrapper-simple'>
                        <div className='image-bound right'>
                            <Lottie 
                            ref={ref => this.lottieOptionsSimple = ref}
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => {
                                        this.setState({isPausedOptionsSimple: true})
                                    },
                                }
                            ]}
                            isClickToPauseDisabled={true}
                            direction={this.state.directionOptionsSimple}
                            options={defaultOptionsSimple}
                            isPaused={this.state.isPausedOptionsSimple}
                            height={265}
                            width={361}/>
                        </div>
                    </div>
                    <div className='note' id='note-simple'>Gửi tiền</div>
                </div>
                <div className='wrapper' >
                    <div className='title-wrapper' >
                        <div style={{maxWidth:'19.125rem'}} className='content'>
                            <div className='title easy'>Dễ dàng</div>
                            <p>Nhắc nhở bạn bè về một số tiền lâu ngày không còn “khó” như bạn nghĩ.</p>
                        </div>
                            
                    </div>     
                    <div className='animation-bound' id='wrapper-easy'>
                        <div className='image-bound left'>
                            <Lottie 
                            ref={ref => this.lottieOptionsEasy = ref}
                            direction={this.state.directionOptionsEasy}
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => {
                                        this.setState({isPausedOptionsEasy: true})
                                    },
                                }
                            ]}
                            isClickToPauseDisabled={true}
                            options={defaultOptionsEasy}
                            isPaused={this.state.isPausedOptionsEasy}
                            height={257}
                            width={350}/>
                        </div>
                    </div>  
                    <div className='note' style={{left: '42%'}} id='note-easy'>Nhắc nợ</div>             
                </div>
                <div className='wrapper'>
                    <div className='title-wrapper'>
                        <div style={{maxWidth:'368px'}} className='content'>
                            <div className='title fast'>Nhanh chóng</div>
                            <p>Không còn phải đau đầu tính toán mỗi người phải trả bao nhiêu sau mỗi buổi hội hè.Cam-pu-chia chỉ còn là chuyện nhỏ.</p>
                        </div>
                    </div>               
                    <div className='animation-bound' id='wrapper-fast'>
                        <div className='image-bound right'>
                            <Lottie 
                            ref={ref => this.lottieOptionsFast = ref}
                            direction={this.state.directionOptionsFast}
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => {
                                        this.setState({isPausedOptionsFast: true})
                                    },
                                }
                            ]}
                            isClickToPauseDisabled={true}
                            options={defaultOptionsFast}
                            isPaused={this.state.isPausedOptionsFast}
                            height={271}
                            width={370}/>
                        </div>
                    </div>
                    <div className='note' id='note-fast'>Chia tiền</div>
                </div>  
            </Bound>
        )
    }
}
