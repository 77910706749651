import React, { Component } from 'react';
import styled from 'styled-components';

//data
import {DESKTOP, TABLET, MOBILE} from '../../../data/constant'

//Image
import img_mobile from '../../../images/iphone.svg';
import face01 from '../../../images/face_01.png';
import face02 from '../../../images/face_02.png';
import face03 from '../../../images/face_03.png';
import  phone_tb from '../../../images/phone_tb.svg';
import  phone_mb from '../../../images/phone_mb.svg';

const Bound = styled.div`
    display:flex;
    position: relative;
    width:100%;
    max-width:1440px;
    align-self:center;
    @media screen and (min-width: 577px) and (max-width: 992px) {
        flex-direction: column;
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
        justify-content: space-around;
    }
    .face-otp{
        position:absolute;
        opacity: 0.15;
        font-family: SVN-Gilroy;
        font-size: 9rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: -0.125rem;
        color: #c9cdd6;
        left:12%;
        bottom:29%;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
    
    #send_flow{
        /* width: 100%; */
        height: 100%;
    }
    .technology-face-animation{
        transform:scale(0);
        opacity:0;
        transition-property:opacity, transform;
        transition-duration:800ms;
    }
    .face01{
        position: absolute;
        top: 0%;
        left: -0.5625rem;
        z-index: -11;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            top: 27%;
        }
        @media screen and (max-width: 576px) {
            top: 43%;
            img{
                width: 8.375rem;
                height: 8.375rem;
            }
        }
    }
    .face02{
        position: absolute;
        bottom: 3%;
        left: 3%;
        z-index: -11;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            bottom: 11%;
            left: unset;
            right:10%;
            img{
                width: 7.8125rem;
                height: 7.8125rem;
            }
        }
        @media screen and (max-width: 576px) {
            bottom: 16%;
            right: 3%;
            left: unset;
            img{
                width: 6.0625rem;
                height: 6.0625rem;
            }
        }
    }
    .face03{
        position: absolute;
        top: 7%;
        right: -1.5rem;
        z-index: -11;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            top: 17%;
            right: 0rem;
            img{
                width: 8.375rem;
                height: 8.375rem;
            }
        }
        @media screen and (max-width: 576px) {
            display:none;
        }
    }

    .content{
        display:flex;
        flex-direction:row;
        align-items: center;
        width:100%;
        height:100%;
        padding: 0 10%;
        .tech-title{
            display: flex;
            width:100%;
            height: fit-content;
            flex-direction: column;
            font-family: 'SVN-Gilroy';
            font-size: 2.5rem;
            font-weight: bold;
            line-height: 1.14;  
            color: #050c1d;            
            text-align: start;
            img{              
                margin-left: 0.3125rem;
            }
            h2{
                font-family: 'SVN-Gilroy';
                font-size: 2.5rem;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #050C1D;
            }
            p{
                padding-top: 0.9375rem;
                text-align: justify;
                font-family: 'UTM-Avo';
                font-size: 1rem;
                font-weight: normal;
                line-height: 1.6;
                color: #050c1d;
            }
        }
        /* tablet */
        @media screen and (min-width: 577px) and (max-width: 992px){
            flex-direction:column;
            align-items: center;
            padding: unset;
            .tech-title{
                font-size: 2.125rem;
                h2{
                    font-size: 2.125rem;
                    max-width:21.875rem;
                    padding: 0 13%;
                }
                p{
                    font-size: 0.9375rem;
                    text-align:justify;
                    padding: 0 13%;
                    padding-top: 1.125rem;
                }
            }
        }
        /* mobile */
        @media screen and (max-width: 576px){
            flex-direction:column;
            align-items: center;
            padding: unset;
            .tech-title{
                padding: 0 2rem ;
                align-items: flex-start;
                text-align: start; 
                width:unset;
                font-size: 2rem;
                h2{
                    font-size: 2rem;
                }
                p{
                    text-align: justify;
                    padding: 1.25rem 0;
                    font-size: 0.875rem;
                }
            } 
        }
        .phone-container{
            position:relative;
            width:100%;
            height: 35rem;
            opacity:0;
            margin-top: 8.125rem;
            transition-property:margin-top, opacity;
            transition-duration:800ms; 
            @media screen and (min-width: 577px) and (max-width: 992px) {
                height: 30rem;
            }
            @media screen and (max-width: 576px) {
                height: 30rem;
            }
            .phone{
                display:flex;
                position: absolute;
                top:0%;
                left:50%;
                transform:translate(-50%, 0%);
                img{
                    height:fit-content;
                }
                .video-phone{
                    height: 26.625rem;
                    background-color: #050c1d;
                    position: absolute;
                    top: 4.375rem;
                    left: 1.25rem;
                    @media screen and (min-width: 577px) and (max-width: 992px) {
                        height: 22.625rem;
                        top: 3.6875rem;
                        left: 0.9375rem;
                    }
                    @media screen and (max-width: 576px) {
                        height: 17.9375rem;
                        top: 2.9375rem;
                        left: 0.75rem;
                    }
                }
            }
        }
    }
`
export default class Technology extends Component {
    constructor(props) {
        super(props)
        this.isScrollUp=true
        this.state = {
            phone:'',
            face01:'',
            face02:'',
            face03:'',
            device:this.props.device,
            direction: 1,
            isHidden: true
        }
    }
    componentDidMount(){
        this.refreshUI()
        this.handleLoadingAnimation(this.audio)
        this.handlePlayVideo()
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }
    componentWillUnmount() {
        const rootEle = document.querySelector(".main-bound");
        rootEle.removeEventListener("scroll", this.checkScrollDown)
        rootEle.removeEventListener("scroll", this.checkScrollUp)
    }
    handlePlayVideo=()=>{
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('technology');
        const video = document.getElementById('send_flow');
        let lastScrollTop = 0;
        const checkScrollIn = this.checkScrollIn = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log(topPosition)
            if ((topPosition >= 0 && topPosition <= window.innerHeight/5) ||
                (topPosition + heightPosition >= window.innerHeight*4/5 && topPosition + heightPosition <= window.innerHeight)){
                video.play()
                rootEle.removeEventListener("scroll", checkScrollIn)
                rootEle.addEventListener("scroll", checkScrollOut)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollOut = this.checkScrollOut = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            const heightPosition = mainBound.offsetHeight;
            console.log(topPosition, window.innerHeight)
            if(topPosition + heightPosition < 0 ||
                topPosition > window.innerHeight){
                video.pause()
                video.currentTime= 0;
                rootEle.removeEventListener("scroll", checkScrollOut)
                rootEle.addEventListener("scroll", checkScrollIn)
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollIn)
    }

    handleLoadingAnimation = () => {
        const rootEle = document.querySelector(".main-bound");
        const mainBound = document.getElementById('technology');
        let lastScrollTop = 0;
        const checkScrollUp = this.checkScrollUp = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st <= lastScrollTop){
                if(topPosition > window.innerHeight*4/5){
                    this.playMainAnimation(-1)
                    rootEle.removeEventListener("scroll", checkScrollUp)
                    rootEle.addEventListener("scroll", checkScrollDown)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        const checkScrollDown = this.checkScrollDown = () => {
            let st = rootEle.scrollTop; 
            const topPosition = mainBound.getBoundingClientRect().top;
            if (st > lastScrollTop){
                if(topPosition <= window.innerHeight/3){
                    this.playMainAnimation(1)
                    rootEle.removeEventListener("scroll", checkScrollDown)
                    rootEle.addEventListener("scroll", checkScrollUp)
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        rootEle.addEventListener("scroll", checkScrollDown)
    }
    
    playMainAnimation = (direction) => {
        let imgArr = document.getElementsByClassName('technology-face-animation')
        let title = document.getElementById('tech-title');
        let phoneBound = document.getElementById('phone-id')
        let scale=0;
        let opacity=0;
        if(direction === 1){
            scale=1;
            opacity=1;
            if(title){
                title.style.opacity=1;
                title.style.marginTop='4.0625rem';   
            }
            setTimeout(() => {
                if(phoneBound){
                    phoneBound.style.opacity=1;
                }
            }, 700);
        } else {
            scale=0;
            opacity=0;
            if(phoneBound){
                phoneBound.style.opacity=0;
            }
            setTimeout(() => {
                if(title){
                    title.style.opacity=0;
                    title.style.marginTop='8.125rem';   
                }
            }, 700);
        }
        if(imgArr){
            for(let i = 0;i < imgArr.length;i++){
                imgArr[i].style.transform='scale('+scale+')';
                imgArr[i].style.opacity=opacity;
            }
        }
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device},()=>{
                    this.refreshUI()
                })
            }
        }
    }

    refreshUI(){
        let {device} = this.state
        switch (device) {
            case DESKTOP:
                this.setState({
                    phone:img_mobile,
                    face01:face01,
                    face02:face02,
                    face03:face03,
                })
                break;
            case TABLET:
                this.setState({
                    phone:phone_tb,
                    face01:face01,
                    face02:face02,
                    face03:face03,
                })
                break;
            case MOBILE:
                this.setState({
                    phone:phone_mb,
                    face01:face01,
                    face02:face02,
                })
                break;
            default:
                break;
        }
        const mainBound = document.getElementById('technology');
        const title = document.getElementById('tech-title')
        const phoneBound = document.getElementById('tech-title')
        const vh = window.innerHeight-63;
        if(mainBound){
            setTimeout(() => {
                let height = mainBound.offsetHeight - 100;
                let heightTitle = title.offsetHeight + 30;
                let heightPhoneBound = phoneBound.offsetHeight + 50;
                if(height < vh){
                    height = vh
                }
                if(height < heightTitle+heightPhoneBound){
                    height = heightTitle+heightPhoneBound
                }
                // mainBound.style.height=height + 'px'
            }, 200);
        }
    }
    render() {
        const {phone,face01,face02,face03} = this.state
        return (
            <Bound id='technology' className='snap-child'>
                <div className='face01 technology-face-animation' ref={ref => this.faceImg01= ref}>
                    <img src={face01} alt='face01' />
                </div>
                <div className='face02 technology-face-animation' ref={ref => this.faceImg02 = ref}>
                    <img src={face02} alt='face02' />
                </div>
                <div className='face03 technology-face-animation' ref={ref => this.faceImg03 = ref}>
                    <img src={face03} alt='face03' />
                </div>
                <h1 className='face-otp'>Face OTP</h1>
                <div className='content'>
                    <div className='tech-title'>
                        <h2>Công nghệ</h2>
                        <h2>trí tuệ nhân tạo</h2>
                        <p>Wee tự hào là ứng dụng đầu tiên sử dụng công nghệ nhận dạng khuôn mặt
                            - Face OTP thay thế cho xác thực SMS OTP trong các giao dịch ngân hàng</p>                    
                    </div>
                    <div className='phone-container' id='tech-title' ref = {ref => this.audio = ref}>
                        <div className='phone'>
                            <img src={phone} alt='mobi' />
                            <div className='video-phone'>
                                <video autoPlay={false} id='send_flow' loop muted playsInline>
                                    <source src='media/send_flow.mp4' type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}
