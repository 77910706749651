import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
//Data
import { footer } from '../../data/data';
import {MOBILE} from '../../data/constant'
import {hostFacebook, hostInstagram, hostYoutube} from '../../data/host'

//Component
import ic_facebook_black from '../../images/ic_facebook_black.svg';
import ic_insta_black from '../../images/ic_insta_black.svg';
import ic_youtube_black from '../../images/ic_youtube_black.svg';

const Bound = styled.div`
    width: 100%;
    position:absolute;
    left:0;
    bottom:0;
    background-color:#fff;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    .footer{
        height: 4.375rem;
        line-height: 4.375rem;
        width: 80%;
        margin: 0 auto;
        max-width: 1440px;
        display: flex;
        justify-content: space-between;
        font-family: 'UTM-Avo';
        font-size: 0.875rem;
        letter-spacing: 0.00625rem;
        color: #484A5D;
        @media screen and (min-width: 577px) and (max-width: 992px) {
            font-family: 'SVN-Gilroy';
            font-size: 0.9375rem;
            line-height: 120%;
            font-weight: bold;
        }
        @media screen and (max-width: 576px) {
            height: fit-content;
            font-family: 'SVN-Gilroy';
            font-size: 1rem;
            line-height: 125%;
            font-weight: bold;
            flex-direction: column;
        }
        ul{
            display: flex;
            align-items: center;
            @media screen and (max-width: 576px) {
                padding-top: 1.25rem;
                flex-direction: column;
                align-items: flex-start;
            }
            li{
                padding-right: 1.5625rem;
                @media screen and (min-width: 577px) and (max-width: 992px) {
                    padding-right: 0.9375rem;
                }
                @media screen and (max-width: 576px) {
                    height: 1.75rem;
                    padding-bottom: 0.875rem ;
                }
                a{
                    line-height: 1.6875rem;
                    color: #484A5D;
                    text-decoration: none;                    
                }
                a:hover{
                    cursor: pointer;
                    color: #0075FF;
                }
            }
        }
        &__icon{
            display: flex;
            @media screen and (min-width: 576px) and (max-width: 992px) {
                display: none;
            }
            @media screen and (max-width: 576px) {
               display: none;
            }
            a{
                display: flex;
                cursor: pointer;
                &:not(:last-child){
                    padding-right: 1.25rem;
                }
            }
        }
        span{
            text-align: right;
            display: flex;
            align-items: center;
            @media screen and (min-width: 576px) and (max-width: 992px) {
                color: #C9CDD6
            }
            @media screen and (max-width: 576px) {
                padding: 1.0625rem 0 1rem 0;
                background: rgba(196, 196, 196, 0.0001);
                /* box-shadow: inset 0rem 0.03125rem 0rem rgba(0, 0, 0, 0.1); */
                line-height: 1.6875rem;
                letter-spacing: 0.00625rem;
                color: #C9CDD6;
                font-family: UTM-Avo;
                font-weight: normal;
            }
        }
    }
`
class Footer extends Component {
    state={
        device:this.props.device
    }
    componentWillReceiveProps(n){
        if(n){
            let {device} = n
            if(device&&device!==this.state.device){
                this.setState({device})
            }
        }
    }
    render() {
        let {device} = this.state
        return (
            <Bound id='footer-id'>
                <footer className = 'footer'>
                    <ul className = 'footer__menu'>
                        {
                            footer.map((item,i) => {
                                return(
                                    <li key = {i}>
                                        {/* <a href = {item.key}>{item.label}</a> */}
                                        <Link to={'/'+item.key}>{item.label}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        device&&device!==MOBILE&&
                        <div className = 'footer__icon'>
                            <a href={hostFacebook}>
                                <img src = {ic_facebook_black} alt = 'ic_facebook_black'/>
                            </a>
                            <a href = {hostInstagram}>
                                <img src = {ic_insta_black} alt = 'ic_insta_black'/>
                            </a>
                            <a href = {hostYoutube}>
                                <img src = {ic_youtube_black} alt = 'ic_youtube_black'/>
                            </a>
                        </div>
                    }                    
                    <span>Copyright © Wee, 2019</span>
                </footer>
            </Bound>
        );
    }
}
export default Footer;