import React, { Component } from 'react'
import styled from 'styled-components'
// data
import { TermOfUseData } from '../../data/data'
import { PrivacyPolicyData } from '../../data/data'

const Bound = styled.div`
position: relative;
    .title{
        font-family: 'SVN-Gilroy';
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: bold;
        color: #050C1D;
        padding-top: 0.625rem;
    }
    .content{
        font-family: 'UTM-Avo';
        font-size: 0.9375rem;
        line-height: 160%;
        font-weight: normal;
        color: #050C1D;
        padding-top: 1.25rem;
    }
    @media screen and (min-width: 577px) and (max-width: 992px){
        .title{
            font-size: 0.9375rem;
            line-height: 120%;
        }
        .content{
            font-size: 0.9375rem;
            line-height: 165%;
        }
    }
    @media screen and (max-width: 576px){
        .title{
            font-size: 1rem;
            line-height: 125%;
        }
        .content{
            font-size: 0.875rem;
            line-height: 165%;
        }
    }
`
const descType = {
    termOfUse: "termOfUse",
    privacyPolicy: "privacyPolicy"
}
export default class ShowContent extends Component {
    state ={
        TermOfUseData : TermOfUseData,
        PrivacyPolicyData : PrivacyPolicyData
    }
    showContent = () =>{
        const type = this.props.type;
        let{TermOfUseData} = this.state
        let{PrivacyPolicyData} = this.state
        switch(type){
            case descType.termOfUse :
                return TermOfUseData.map((item,i) =>{
                    return(
                        <div key={i}>
                            <div className='title'>{item.title}</div>
                            {
                                item.content.map((itemcontent,index)=>{
                                    return(
                                        <p className= 'content' key={index}>{itemcontent}</p>
                                    )
                                })
                            }
                        </div>
                    )
                })
            case descType.privacyPolicy :
                return PrivacyPolicyData.map((item,i) =>{
                    return(
                        <div key={i}>
                            <div className='title'>{item.title}</div>
                            {
                                item.content.map((itemcontent,index)=>{
                                    return(
                                        <p className= 'content' key={index}>{itemcontent}</p>
                                    )
                                })
                            }
                        </div>
                    )
                })
        }
    } 
    render() {
        return (
            <Bound>
                {this.showContent()}
            </Bound>
        )
    }
}
